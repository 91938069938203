import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';

import { MetricItem } from 'generated/graphql';
import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';
import { capitaliseText } from '../../../../../utils/stringUtils';

export type BreakdownScores = {
	epoch1: string;
	epoch2: string;
	epoch3: string;
	epoch4: string;
};

export function useFluencyData(segmentResult: DeepSegmentResult) {
	const {
		audioPrompt,
		breakdownScores,
		correctResponses,
		percentile,
		englishSegment,
	} = useMemo(() => {
		return parseFluencyMetrics(segmentResult.metricItems);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const [recording, setRecording] = useState('');

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});

	useEffect(() => {
		if (audioFiles.length > 0) {
			setRecording(audioFiles[0].responseUrl);
		}
	}, [audioFiles]);

	return {
		audioPrompt,
		breakdownScores,
		correctResponses,
		englishSegment,
		percentile,
		recording,
	};
}

enum PhonemicKeys {
	Category = 'category',
	Epoch1 = 'epoch1_score',
	Epoch2 = 'epoch2_score',
	Epoch3 = 'epoch3_score',
	Epoch4 = 'epoch4_score',
	LanguageCode = 'language_code',
	Percentile = 'phonemic_fluency_percentile',
	TotalScore = 'total_score',
}

export function parseFluencyMetrics(metricItems: MetricItem[]) {
	const breakdownScores: BreakdownScores = {
		epoch1: '--',
		epoch2: '--',
		epoch3: '--',
		epoch4: '--',
	};
	let audioPrompt = '';
	let correctResponses = '-1';
	let percentile: number | undefined;
	let englishSegment = true;

	metricItems.forEach((metricItem) => {
		if (metricItem.key.includes(PhonemicKeys.Epoch1)) {
			breakdownScores.epoch1 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key.includes(PhonemicKeys.Epoch2)) {
			breakdownScores.epoch2 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key.includes(PhonemicKeys.Epoch3)) {
			breakdownScores.epoch3 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key.includes(PhonemicKeys.Epoch4)) {
			breakdownScores.epoch4 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key.includes(PhonemicKeys.Category)) {
			audioPrompt = i18n.t(
				'web.report.phonemicFluencyReport.audioPrompt',
				{
					initialLetter: metricItem.value,
				}
			);
		}

		if (metricItem.key.includes(PhonemicKeys.TotalScore)) {
			correctResponses = isNaN(Number(metricItem.value))
				? '-1'
				: metricItem.value;
		}

		if (metricItem.key === PhonemicKeys.Percentile) {
			percentile = isNaN(Number(metricItem.value))
				? undefined
				: Number(metricItem.value);
		}

		if (metricItem.key === PhonemicKeys.LanguageCode) {
			englishSegment =
				metricItem.value === 'en_US' || metricItem.value === 'en_GB';
		}
	});

	return {
		audioPrompt: capitaliseText(audioPrompt),
		breakdownScores,
		correctResponses,
		englishSegment,
		percentile,
	};
}

export const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			segmentResultId,
			type: '',
			filename: 'phonemic_fluency_audio.mp4',
		},
	];
};
