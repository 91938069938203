import { Patient, Subject } from '@lh/eng-platform-subject-service-rest-client';

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalyticsAction, sendEventData } from 'analytics';
import { useAddRelationship } from 'api/patient';
import { useCreateSubjectMutation } from 'api/subject';
import { UserContext } from 'components/context';
import { ButtonSm } from 'components/shared/designSystem';
import { messages } from 'components/shared/errorMessages';
import { icons } from 'enums/icons';
import { messageEnum } from 'enums/messageEnum';
import { ERROR } from 'logging/linusLogger';
import { useModalStore } from 'store';
import { PartnerInfo } from '../CarePartner.types';
import { modifyPartnerValues } from '../CarePartner.utils';
import { useErrorMessageStore } from '../useErrorMessageStore';

type AddCarePartnerProps = {
	patient: Patient;
};

export function AddCarePartner({ patient }: Readonly<AddCarePartnerProps>) {
	const { currentUser } = useContext(UserContext);
	const { t } = useTranslation();

	const { mutateAsync: createSubject } = useCreateSubjectMutation();
	const { mutateAsync: addRelationship } = useAddRelationship();

	const openModal = useModalStore((state) => state.open);
	const closeModal = useModalStore((state) => state.close);
	const setErrorMessage = useErrorMessageStore(
		(state) => state.setErrorMessage
	);

	async function handleAddCarePartner(newPartnerInfo: PartnerInfo) {
		if (!newPartnerInfo.patientRelationship) {
			ERROR(
				"Care partner doesn't have a set patientRelationship in handleAddCarePartner"
			);
			setErrorMessage(messageEnum.error(messages.mutationPayloadError));
			return;
		}
		try {
			const newSubject = await createSubject({
				organizationId: currentUser.organizationId,
				createSubjectInput: modifyPartnerValues(newPartnerInfo),
			});

			await addRelationship({
				organizationId: currentUser.organizationId,
				subjectId: patient.id,
				relatedSubjectInput: {
					relatedSubjectId: newSubject.id,
					type: newPartnerInfo.patientRelationship,
				},
			});

			handleRelationshipSuccess(newSubject, patient);
		} catch (e) {
			ERROR(
				`Unexpected error in handleAddCarePartner, organizationId: ${currentUser.organizationId}, patientId: ${patient.id}: `,
				e
			);
			setErrorMessage(messageEnum.error(messages.mutationPayloadError));
		}
	}

	function handleRelationshipSuccess(
		partnerInfo: Subject,
		patientInfo: Patient
	) {
		sendEventData({
			eventType: AnalyticsAction.SavedCarePartner,
		});
		openModal('AddCarePartnerConfirmation', {
			fullNameCarePartner: `${partnerInfo.firstName} ${partnerInfo.lastName}`,
			fullNamePatient: `${patientInfo.firstName} ${patientInfo.lastName}`,
		});

		closeModal('AddCarePartner');
	}

	function handleAddPress() {
		sendEventData({
			eventType: AnalyticsAction.ClickedCarePartner,
		});
		openModal('AddCarePartner', {
			patientInfo: patient,
			onSubmit: handleAddCarePartner,
		});
	}

	return (
		<ButtonSm
			onClick={handleAddPress}
			primary={false}
			text={t`web.patients.carePartner.add.cta`}
			IconLeft={icons.CareGiver}
		/>
	);
}
