import { AuthProvider } from '../../../helpers';

import { UserContextProvider as UserProviderAuth0 } from './UserContextProvider.auth0';
import { UserContextProvider as UserProviderCognito } from './UserContextProvider.cognito';

type UserProviderProps = {
	children: React.ReactNode;
	storybook?: boolean;
};

export function UserProvider({
	storybook = false,
	children,
}: Readonly<UserProviderProps>) {
	if (storybook) {
		return <>{children}</>;
	}
	if (AuthProvider.isCognito) {
		return <UserProviderCognito>{children}</UserProviderCognito>;
	}

	return <UserProviderAuth0>{children}</UserProviderAuth0>;
}
