import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { t } from 'i18n';
import { orgSpecificIconStrategy } from '../components/shared/designSystem/iconStrategy/orgSpecificIconStrategy';
import { orgSpecificStringStrategy } from '../stringStrategy/orgSpecificStringStrategy';
import { CurrentUser } from '../types';

import { EnumItem } from './enumeration';
import { icons } from './icons';
import { menuFilterEnum } from './menuFilterEnum';

export type RouteEnum = {
	text: string;
	icon?: EnumItem;
	isBordered?: boolean;
	path: string;
	getCount?: string;
	filter?: (currentUser?: CurrentUser) => void;
	operations: OperationToken[];
};

export const routeEnum = (
	currentUser: CurrentUser,
	isAuth0Universal = false
): RouteEnum[] => {
	const menuFilterOptions = menuFilterEnum();
	return [
		{
			filter: () => menuFilterOptions.excludingLinus(currentUser),
			text: orgSpecificStringStrategy('Participants', currentUser),
			icon: icons.Users,
			getCount: 'Participants',
			path: orgSpecificStringStrategy('/participants', currentUser),
			operations: [
				OperationToken.ViewAllParticipants,
				OperationToken.ViewParticipant,
			],
		},
		{
			filter: () => menuFilterOptions.excludingLinus(currentUser),
			text: t`web.enums.routes.results`,
			icon: icons.Results,
			getCount: 'Results',
			path: '/results',
			operations: [OperationToken.ViewResults],
		},
		{
			text: orgSpecificStringStrategy('Organization', currentUser),
			icon: orgSpecificIconStrategy('OrgIcon', currentUser),
			path: '/organization',
			operations: [OperationToken.ViewAllUsers, OperationToken.ViewUser],
		},
		{
			filter: () => menuFilterOptions.excludingLinus(currentUser),
			text: t`web.enums.routes.support`,
			icon: icons.Support,
			path: '#',
			operations: [OperationToken.ContactSupport],
		},
		{
			filter: () => isAuth0Universal,
			text: t`web.enums.routes.about`,
			path: '/about',
			icon: icons.Info,
			operations: [OperationToken.ViewAbout],
		},
	];
};
