import { useEffect } from 'react';

import { useModalStore, useValueListsStore } from 'store';

export function useStoreReset() {
	const resetValueListsStore = useValueListsStore(
		(state) => state.resetValueLists
	);
	const resetModalStore = useModalStore((state) => state.reset);

	useEffect(() => {
		return () => {
			resetValueListsStore();
			resetModalStore();
		};
	}, []);
}
