import { AssessmentType } from '@lh/eng-platform-battery-service-rest-client';
import { FeatureType } from '@lh/eng-platform-organization-service-rest-client';

import {
	BatteryIssue,
	Report,
} from 'components/report/CognitiveEvaluation.types';
import { BatteryType } from 'generated/graphql';

import { AnalyticsReportName } from './BatteryHeader.analytics';
import {
	ASSESSMENTS_WITH_CARE_PARTNER,
	ASSESSMENTS_WITH_PATIENT_REPORT,
	BATTERIES_WITH_PATIENT_REPORT,
} from './BatteryHeader.consts';
import { ReasonsForTesting } from './TestAdministratorNotes/TestAdministratorNotes.interface';

export function showSessionObservations(
	features: FeatureType[],
	issue?: BatteryIssue
) {
	if (!features.includes(FeatureType.RegisterAssignmentIssue)) {
		return false;
	}

	if (!!issue?.note?.length || !!issue?.options?.length) {
		return true;
	}

	return false;
}

export function showReasonsForTesting(
	features: FeatureType[],
	reasonsForTesting: ReasonsForTesting
) {
	if (!features.includes(FeatureType.ReasonsForTesting)) {
		return false;
	}

	if (!!reasonsForTesting.data.length || !!reasonsForTesting.note?.length) {
		return true;
	}

	return false;
}

export function showTaAttestation(
	features: FeatureType[],
	attestation: string
) {
	if (!features.includes(FeatureType.TaAttestation)) {
		return false;
	}

	if (attestation.length > 0) {
		return true;
	}

	return false;
}

export function getCarePartnerActionPlanName(
	batteryType: BatteryType,
	batteryTitle: string
): string {
	if (batteryType === BatteryType.Iadl)
		return AnalyticsReportName.IADL_CARE_PARTNER_ACTION_PLAN;

	if (batteryType === BatteryType.Adl)
		return AnalyticsReportName.ADL_CARE_PARTNER_ACTION_PLAN;

	return `${batteryTitle} Care Partner Report`;
}

export function getProviderReportName(
	batteryType: BatteryType,
	features: FeatureType[],
	batteryTitle: string
) {
	// special case for some of the batteries
	if (features.includes(FeatureType.ShortProviderReport)) {
		if (batteryType === BatteryType.CognitiveEvaluation) {
			return AnalyticsReportName.CCE_SHORT_PROVIDER_REPORT;
		}

		if (batteryType === BatteryType.Dcr) {
			return AnalyticsReportName.DCR_SHORT_PROVIDER_REPORT;
		}

		if (batteryType === BatteryType.Lhq) {
			return AnalyticsReportName.LHQ_SHORT_PROVIDER_REPORT;
		}
	}

	switch (batteryType) {
		case BatteryType.CognitiveEvaluation:
			return AnalyticsReportName.CCE_PROVIDER_REPORT;
		case BatteryType.Dcr:
			return AnalyticsReportName.DCR_PROVIDER_REPORT;
		case BatteryType.Lhq:
			return AnalyticsReportName.LHQ_PROVIDER_REPORT;
		case BatteryType.HearingScreener:
			return AnalyticsReportName.HEARING_SCREENER_PROVIDER_REPORT;
		case BatteryType.Trails:
			return AnalyticsReportName.DTMT_PROVIDER_REPORT;
		case BatteryType.Orientation:
			return AnalyticsReportName.ORIENTATION_QNR6_PROVIDER_REPORT;
		case BatteryType.Gad7:
			return AnalyticsReportName.GAD_7_PROVIDER_REPORT;
		case BatteryType.Gds:
			return AnalyticsReportName.GDS_PROVIDER_REPORT;
		case BatteryType.Iadl:
			return AnalyticsReportName.IADL_PROVIDER_REPORT;
		case BatteryType.Phonemic:
			return AnalyticsReportName.PHONEMIC_FLUENCY_PROVIDER_REPORT;
		case BatteryType.Semantic:
			return AnalyticsReportName.SEMANTIC_FLUENCY_PROVIDER_REPORT;
		case BatteryType.Bds:
			return AnalyticsReportName.BACKAWRDS_DIGIT_SPAN_REPORT;
		case BatteryType.Fast:
			return AnalyticsReportName.FAST_PROVIDER_REPORT;
		case BatteryType.CommonSpirit:
			return AnalyticsReportName.COMMON_SPIRIT_PRIMARY_CARE_PROVIDER_REPORT;
		case BatteryType.Emory:
			return AnalyticsReportName.EMORY_PROVIDER_REPORT;
		case BatteryType.CommonSpiritDementia:
			return AnalyticsReportName.COMMON_SPIRIT_DEMENTICA_CLINIC;
		case BatteryType.Iuh:
			return AnalyticsReportName.IUH_PROVIDER_REPORT;
		case BatteryType.Pvlt:
			return AnalyticsReportName.PVLT_PROVIDER_REPORT;
		case BatteryType.Adl:
			return AnalyticsReportName.ADL_PROVIDER_REPORT;
		case BatteryType.IadlFast:
			return AnalyticsReportName.IADL_FAST_PROVIDER_REPORT;
		case BatteryType.DcrTrails:
			return AnalyticsReportName.DCR_TRAILS;
		default:
			return `${batteryTitle} Provider Report`;
	}
}

export function getPatientReportName(
	batteryType: BatteryType,
	batteryTitle: string
) {
	switch (batteryType) {
		case BatteryType.CognitiveEvaluation:
			return AnalyticsReportName.CCE_PATIENT_REPORT_BHAP;
		case BatteryType.Dcr:
			return AnalyticsReportName.DCR_PATIENT_REPORT_BHAP;
		case BatteryType.Lhq:
			return AnalyticsReportName.LHQ_PATIENT_REPORT_BHAP;
		case BatteryType.Iadl:
			return AnalyticsReportName.IADL_PATIENT_REPORT_BHAP;
		case BatteryType.Adl:
			return AnalyticsReportName.ADL_PATIENT_REPORT_BHAP;
		default:
			return `${batteryTitle} Report/BHAP`;
	}
}

export function shouldGenerateCarePartnerReport(
	features: FeatureType[],
	batteryResult?: Report
) {
	if (!features.includes(FeatureType.EnableCarePartner)) {
		return false;
	}

	const assessmentResultsWithCarePartner =
		batteryResult?.batteryResultById?.assessmentResults.find(
			(assessmentResult) =>
				ASSESSMENTS_WITH_CARE_PARTNER.includes(
					// this is typed as string on the battery service
					assessmentResult.assessment
						?.assessmentType as AssessmentType
				)
		);

	return !!assessmentResultsWithCarePartner;
}

export function shouldGeneratePatientReport(
	features: FeatureType[],
	batteryResult?: Report
) {
	if (!features.includes(FeatureType.PatientReportAndActionPlan)) {
		return false;
	}

	if (
		BATTERIES_WITH_PATIENT_REPORT.includes(
			// this is typed as string on the battery service
			batteryResult?.batteryResultById?.battery.batteryType as BatteryType
		)
	) {
		return true;
	}

	const assessmentResultsWithPatientReport =
		batteryResult?.batteryResultById?.assessmentResults.find(
			(assessmentResult) =>
				ASSESSMENTS_WITH_PATIENT_REPORT.includes(
					// this is typed as string on the battery service
					assessmentResult.assessment
						?.assessmentType as AssessmentType
				)
		);

	return !!assessmentResultsWithPatientReport;
}
