import { Group, Stack, UnstyledButton } from '@mantine/core';
import { t } from 'i18n';

import { theme } from 'components/providers/StyleProvider/theme';
import { Icon } from 'components/shared/designSystem';
import { Caption, P2 } from 'components/shared/designSystem/TextComponents';
import { icons } from 'enums/icons';
import { PathwayType } from 'generated/graphql';

import { RenderList } from '../Pathway.list';
import { ClickableText, ExternalLink, renderTags } from '../Pathway.misc';
import {
	AdditionalConcerns,
	PathwayConcern,
	PathwayID,
} from '../Pathway.types';
import { concernsLinks } from './Concerns.data';

/**
 * Handles depedency injection of `concern` argument in components.
 *
 * Components: `Summary`, `NextSteps`
 */
export function getConcern(
	concern: PathwayConcern,
	pathwayId: PathwayType | PathwayID
) {
	return {
		Summary: Summary.bind(null, concern),
		NextSteps: NextSteps.bind(null, concern, pathwayId),
	};
}

function Summary(
	concern: PathwayConcern,
	{
		onViewDetails,
	}: {
		onViewDetails(): void;
	}
) {
	const renderViewDetails = concern.id !== AdditionalConcerns.Hearing;

	return (
		<Stack gap={8} data-testid='concern'>
			<Title value={concern.title} />
			<Stack gap={8} ml={22} align='flex-start'>
				<Rationale value={concern.rationale} />
				{renderViewDetails && <ViewDetails onClick={onViewDetails} />}
			</Stack>
		</Stack>
	);
}

function Title({ value }: { value: string }) {
	return (
		<Group
			style={{
				width: 'fit-content',
				gap: 2,
				paddingLeft: 4,
				paddingRight: 8,
				borderRadius: 4,
				backgroundColor: theme.color.white,
			}}
			wrap='nowrap'
		>
			<Icon
				icon={icons.ArrowFlag}
				iconWidth={16}
				iconHeight={16}
				color={theme.colors.orange}
			/>
			<P2 style={{ fontWeight: theme.weight.semi }}>{value}</P2>
		</Group>
	);
}

function Rationale({ value }: { value: string }) {
	return <Caption style={{ color: theme.colors.gray_30 }}>{value}</Caption>;
}

function ViewDetails({ onClick }: { onClick(): void }) {
	return (
		<UnstyledButton ff={theme.baseFont} display='flex' onClick={onClick}>
			<Caption
				style={{
					cursor: 'pointer',
					color: theme.colors.blue,
					fontWeight: theme.weight.medium,
				}}
			>
				{t('cds.concerns.viewDetails')}
			</Caption>
		</UnstyledButton>
	);
}

function NextSteps(
	concern: PathwayConcern,
	pathwayId: PathwayType | PathwayID,
	{
		onClick,
	}: {
		onClick: {
			BHAP(): void;
		};
	}
) {
	const tagComponents = {
		BHAP: <ClickableText onClick={onClick.BHAP} />,
		ABC: (
			<ExternalLink
				to={concernsLinks.ABC}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
		ABCUrl: (
			<ExternalLink
				to={concernsLinks.ABC}
				color='inherit'
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
		FRT: (
			<ExternalLink
				to={concernsLinks.FRT}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
		WVT: (
			<ExternalLink
				to={concernsLinks.WVT}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
		TUG: (
			<ExternalLink
				to={concernsLinks.TUG}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
	};

	return (
		<P2 data-testid='next-steps'>
			<RenderList
				list={concern.nextSteps}
				renderItem={(item) => renderTags(item, tagComponents)}
			/>
		</P2>
	);
}
