import {
	OperationToken,
	UserOrganizationsRoleStatus,
	UserStatus as UserStatusOrg,
} from '@lh/eng-platform-organization-service-rest-client';

import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { useRolesQuery } from 'api/organization';
import { QueryKey } from 'api/query';
import {
	inviteUser,
	useUpdateRoleStatus,
	useUpdateUser,
	useUserQuery,
} from 'api/user';
import { ShowIfAuthorized } from 'components/shared/ShowIfAuthorized';
import { useValueListsStore } from 'store';
import { MessageEnumItem, messageEnum } from '../../enums/messageEnum';
import { ValueListType } from '../../generated/graphql';
import { ERROR } from '../../logging/linusLogger';
import { UserContext } from '../context/UserContext';
import { LinusInput } from '../shared/Forms/Components/LinusInput';
import {
	EditUserModel,
	editUserSchema,
	getModel,
} from '../shared/Forms/Schemas/editUserSchema';
import { InfoMessage } from '../shared/InfoMessage';
import { LinusModalDialog } from '../shared/LinusModalDialog';
import { LoadingDots } from '../shared/LoadingDots';
import { ButtonLg } from '../shared/designSystem/ButtonLg';
import { messages } from '../shared/errorMessages';

import {
	ChangeStatusModal,
	PayloadType,
} from './ConfirmationModals/ChangeStatusModal';
import { UserStatus } from './UserStatus';

export type EditUserFormProps = {
	onCancel: () => void;
	onFinish?: () => void;
	userId: string;
};

const EditUserForm = ({
	onCancel,
	onFinish,
	userId,
}: EditUserFormProps): JSX.Element | null => {
	const [showModal, setShowModal] = useState('');
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	const { currentUser } = useContext(UserContext);
	const { t } = useTranslation();
	const valueLists = useValueListsStore((state) => state.valueLists);

	const client = useQueryClient();

	// gets the roles for the role type dropdown
	const { data: organizationRoles, isPending } = useRolesQuery(
		currentUser.organizationId
	);

	const { data, isPending: loading } = useUserQuery(
		currentUser.organizationId,
		userId
	);

	const { mutateAsync: updateRoleStauts, isPending: roleStatusLoading } =
		useUpdateRoleStatus();
	const {
		mutateAsync: updateUserMutation,
		isPending: updateLoading,
		error: updateError,
	} = useUpdateUser(currentUser.organizationId);

	if (loading || isPending) {
		return <LoadingDots />;
	}

	if (updateError) {
		// We need frontend logging
		ERROR(updateError?.message || '', updateError);
	}

	const updateUser = async (values: EditUserModel) => {
		try {
			await updateUserMutation({
				organizationId: currentUser.organizationId,
				userId: values.id,
				userUpdate: {
					firstName: values.firstName,
					lastName: values.lastName,
					role: {
						organizationId: currentUser.organizationId,
						roleId: values.role,
					},

					suffix: values?.suffixIds?.split(', ').filter(Boolean),
					phoneNumber: values.phoneNumber,
				},
			});
		} catch (e) {
			setServerSideMessage(
				messageEnum.error(messages.mutationPayloadError)
			);
			return false;
		}

		return true;
	};
	const onSubmit = async (
		values: EditUserModel,
		{ setSubmitting }: FormikHelpers<EditUserModel>
	) => {
		const changeStatus = [
			{ activate: values.activate },
			{ deactivateUser: values.deactivate },
			{
				resendInvitation:
					values.invitedUserActions === 'resendInvitation',
			},
			{
				cancelInvitation:
					values.invitedUserActions === 'cancelInvitation',
			},
		] // Arry of objects for each of the different status.
			.filter((x) => Object.values(x)[0]) // Filter through the array of objects to pull objects with true values
			.map((x) => Object.keys(x)[0])[0]; // Pull out key from filter, will either come out as the key or as undefined.

		if (changeStatus) {
			// not sure how this is working just yet
			setShowModal(changeStatus);
			await updateUser(values);
		} else {
			const success = await updateUser(values);
			if (!success) {
				setSubmitting(false);
				return;
			}
			setShowSuccessModal(true);
		}
		sendEventData({
			eventType: AnalyticsAction.UpdatedUser,
			eventProperties: {
				changeStatus,
				userId: values.id,
				userRole: values.role,
			},
		});
		setSubmitting(false);
	};

	const onApproval = async (payload: PayloadType, values: EditUserModel) => {
		if (payload.status === UserStatusOrg.Deactivated) {
			try {
				await updateRoleStauts({
					organizationId: currentUser.organizationId,
					userId,
					status: UserOrganizationsRoleStatus.Deactivated,
				});
				await client.invalidateQueries({
					queryKey: [QueryKey.Users, currentUser.organizationId],
				});
				return true;
			} catch (err) {
				ERROR(
					`Error trying to update role status from EditUserForm, userId: ${userId}, organizationId: ${currentUser.organizationId}: `,
					err
				);
				setServerSideMessage(
					messageEnum.error(messages.mutationPayloadError)
				);
				return false;
			}
		}

		// Reinvite or invite user
		try {
			await inviteUser({
				organizationId: currentUser.organizationId,
				userInvite: {
					organizationId: currentUser.organizationId,
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
					phoneNumber: values.phoneNumber,
					suffixIds: values?.suffixIds?.split(', ').filter(Boolean),
					roleId:
						values.roles.find(
							(r) =>
								r.organizationId === currentUser.organizationId
						)?.roleId || '',
				},
			});
			await client.invalidateQueries({
				queryKey: [QueryKey.Users, currentUser.organizationId],
			});
		} catch (err) {
			ERROR(
				`Error trying to update role status from EditUserForm, userId: ${userId}, organizationId: ${currentUser.organizationId}: `,
				err
			);
			setServerSideMessage(
				messageEnum.error(messages.mutationPayloadError)
			);
			return false;
		}

		return true;
	};
	return (
		<>
			<Formik
				initialValues={getModel(currentUser, data, organizationRoles)}
				validationSchema={editUserSchema}
				onSubmit={onSubmit}
			>
				{({ isSubmitting, values }) => {
					return showModal ? (
						<ChangeStatusModal
							statusAction={showModal}
							firstname={values.firstName}
							lastname={values.lastName}
							onCancelModal={() => {
								setShowModal('');
							}}
							closeParentModal={() => {
								onCancel();
								onFinish?.();
							}}
							onSubmit={(payload) => onApproval(payload, values)}
						/>
					) : (
						<StyledForm>
							<StyledRow>
								<LinusInput
									width='257px'
									name='firstName'
									label={t`web.team.sharedModal.firstNameLabel`}
								/>
								<LinusInput
									width='257px'
									name='lastName'
									label={t`web.team.sharedModal.lastNameLabel`}
								/>
								<LinusInput
									width='257px'
									name='suffixIds'
									label={t`web.team.sharedModal.suffixLabel`}
									type='multi'
									preferDisplayLength='long'
									dropdownOptions={
										valueLists?.[
											ValueListType.Suffix
										]?.toOptions() || []
									}
								/>
							</StyledRow>
							<StyledRow>
								<LinusInput
									name='email'
									label={t`web.team.sharedModal.emailAddressLabel`}
									type='email'
									disabled
									width='402px'
								/>
								<LinusInput
									name='phoneNumber'
									label={t`web.team.sharedModal.phoneNumberLabel`}
									width='402px'
								/>
							</StyledRow>
							<StyledRow>
								<LinusInput
									name='role'
									type='select'
									label={t`web.team.sharedModal.userRoleLabel`}
									width='100%'
									dropdownOptions={(
										organizationRoles || []
									).map((r) => ({
										display: t(r.displayKey),
										value: r.id,
									}))}
									value={values.role}
								/>
							</StyledRow>
							<StyledStatusRow>
								<UserStatus
									name='statusInOrg'
									label={t`web.team.editUserModal.statusLabel`}
								/>
							</StyledStatusRow>
							<InfoMessage
								messageEnum={serverSideMessage}
								showIf={!!serverSideMessage}
							/>
							<StyledButtonRow>
								<StyledButtonWrapper>
									<ButtonLg
										onClick={onCancel}
										text={t`web.team.sharedModal.cancelButton`}
										width='300px'
									/>
								</StyledButtonWrapper>
								<ShowIfAuthorized
									operations={[OperationToken.EditUsers]}
								>
									<ButtonLg
										primary
										disabled={isSubmitting || updateLoading}
										loading={
											updateLoading || roleStatusLoading
										}
										text={t`web.team.editUserModal.saveChangesButton`}
										type='submit'
										width='300px'
									/>
								</ShowIfAuthorized>
							</StyledButtonRow>
						</StyledForm>
					);
				}}
			</Formik>
			{showSuccessModal && (
				<LinusModalDialog
					onClose={() => {
						setShowSuccessModal(false);
						onFinish?.();
					}}
					title={t`web.team.changesSavedModal.title`}
					declineButtonCallback={() => {
						setShowSuccessModal(false);
						onFinish?.();
					}}
					declineButtonText={t`web.team.sharedModal.closeButton`}
					width={'500px'}
				>
					<Trans
						i18nKey={'web.team.changesSavedModal.hasBeenSaved'}
						values={{
							firstName: data?.firstName,
							lastName: data?.lastName,
						}}
						components={[<strong />]}
					/>
				</LinusModalDialog>
			)}
		</>
	);
};

const StyledForm = styled(Form)`
	width: auto;
	margin: 0;
	padding: 0;
`;

const StyledButtonRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: center;
	padding: ${spacing.md};
	`
);
const StyledButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: 0 ${spacing.xl} 0 0;
`
);
const StyledRow = styled.div`
	width: 836px;
	display: flex;
	justify-content: space-between;
`;
const StyledStatusRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: space-between;
	margin: ${spacing.sm} 0;
`
);

export { EditUserForm };
