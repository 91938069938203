import { t } from 'i18n';
import * as yup from 'yup';

export type LoginModel = {
	username: string;
	password: string;
};

export const getModel = (): LoginModel => {
	return { username: '', password: '' };
};

export const validationSchema = yup.object().shape({
	username: yup
		.string()
		.email(t`web.authErrors.invalidEmail`)
		.required(t`web.authErrors.emailRequired`),
	password: yup.string().required(t`web.authErrors.passwordRequired`),
});
