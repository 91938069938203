import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { useRolesQuery } from 'api/organization';
import { useUpdateUser } from 'api/user';
import { SSOTag } from 'components/shared/LinusModal';
import { LoadingDots } from 'components/shared/LoadingDots';
import { MessageEnumItem, messageEnum } from 'enums/messageEnum';
import { ValueListType, useUserIsThirdPartyQuery } from 'generated/graphql';
import { hasOperation } from 'hasOperation';
import { ERROR } from 'logging/linusLogger';
import { useValueListsStore } from 'store';
import { Auth } from '../../features/auth-service';
import { UserContext } from '../context/UserContext';
import { LinusInput } from '../shared/Forms/Components/LinusInput';
import {
	UserProfileModel,
	getModel,
	userProfileSchema,
} from '../shared/Forms/Schemas/userProfileSchema';
import { Header } from '../shared/Header';
import { InfoMessage } from '../shared/InfoMessage';
import { StyledCard } from '../shared/StyledCard';
import { ButtonLg } from '../shared/designSystem/ButtonLg';
import { H2 } from '../shared/designSystem/TextComponents';

export const UserProfile = (): JSX.Element | null => {
	const { currentUser, clearImpersonation, refetchCurrentUser } =
		useContext(UserContext);

	const { data: organizationRoles } = useRolesQuery(
		currentUser.organizationId
	);
	const { mutateAsync: updateProfile, isPending: updatingUser } =
		useUpdateUser(currentUser.organizationId);
	const { t } = useTranslation();
	const valueLists = useValueListsStore((state) => state.valueLists);

	const { organizationId: orgId, id: userId } = currentUser;

	const { data: thirdPartyData, loading } = useUserIsThirdPartyQuery({
		errorPolicy: 'all',
		variables: {
			orgId: currentUser.organizationId,
			userId: currentUser.id,
		},
		skip: !orgId || !userId,
	});

	const [showMessage, setShowMessage] = useState<MessageEnumItem>();

	const isAdmin = hasOperation(currentUser, [OperationToken.EditUser]);
	const isThirdPartyManaged =
		thirdPartyData?.organization?.userById?.isThirdPartyManaged;

	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedProfile });
	}, []);

	useEffect(() => {
		// Clear impersonation
		clearImpersonation();
	}, [clearImpersonation]); //this function should be invariant, so this is just to make the linter happy

	if (loading || !thirdPartyData?.organization) {
		return <LoadingDots />;
	}

	const handleResetPassword = async () => {
		const success = await Auth.requestPasswordReset(currentUser.email);

		if (!success) {
			setShowMessage(
				messageEnum.error(t`web.profile.resetPasswordError`)
			);
			return;
		}

		setShowMessage(messageEnum.success(t`web.profile.emailSent`));
		sendEventData({ eventType: AnalyticsAction.ResetPassword });
	};

	const handleResetPasswordClick = async () => {
		await handleResetPassword();
	};

	const onSubmit = async (
		values: UserProfileModel,
		{ setSubmitting }: FormikHelpers<UserProfileModel>
	) => {
		// Only Admins are allowed to update their roles.
		const role = isAdmin
			? { roleId: values.roleId, organizationId: orgId }
			: undefined;

		try {
			await updateProfile({
				organizationId: orgId,
				userId: values.id,
				userUpdate: {
					firstName: values.firstName,
					lastName: values.lastName,
					phoneNumber: values.phoneNumber,
					suffix: values?.suffixIds.split(', '),
					role,
				},
			});
		} catch (e) {
			ERROR(
				`There was an error updating the patient ${values.id} from orgId ${orgId}: `,
				e
			);
			setShowMessage(messageEnum.error(t`web.profile.changesError`));
			setSubmitting(false);
			return;
		}

		setShowMessage(messageEnum.success(t`web.profile.changesSuccess`));
		sendEventData({ eventType: AnalyticsAction.SavedAccountChanges });
		// This should be removed once the caching system is used for the user context
		refetchCurrentUser();
		setSubmitting(false);
	};

	return (
		<>
			<Header display={t`web.profile.displayTitle`} />
			{/* TODO: I suggest going with the `useFormik()` */}
			<Formik
				initialValues={getModel(currentUser)}
				validationSchema={userProfileSchema}
				onSubmit={onSubmit}
				enableReinitialize={true}
			>
				{({ isSubmitting, isValid, dirty }) => (
					<StyledFormContainer>
						<StyledTitle>
							<StyledH2>
								{t`web.profile.accountInformation`}
							</StyledH2>
							{isThirdPartyManaged && (
								<SSOTag> {t`web.shared.ssoUser`}</SSOTag>
							)}
						</StyledTitle>
						<StyledFormContent>
							<StyledForm>
								<StyledRow>
									<LinusInput
										data-id='firstName-input'
										name='firstName'
										label={t`web.shared.firstName`}
									/>
									<LinusInput
										data-id='lastName-input'
										name='lastName'
										label={t`web.shared.lastName`}
									/>
									<LinusInput
										width='200px'
										name='suffixIds'
										label={t`web.team.sharedModal.suffixLabel`}
										disabled={!valueLists}
										type='multi'
										preferDisplayLength='long'
										dropdownOptions={valueLists?.[
											ValueListType.Suffix
										]?.toOptions()}
									/>
								</StyledRow>
								<StyledRow>
									<LinusInput
										data-id='emailAddress-input'
										width='49%'
										name='email'
										label={t`web.shared.emailAddress`}
										type='email'
										disabled
									/>
									<LinusInput
										data-id='phoneNumber-input'
										width='49%'
										name='phoneNumber'
										label={t`web.team.sharedModal.phoneNumberLabel`}
									/>
								</StyledRow>
								<StyledRow>
									<LinusInput
										data-id='userRole-input'
										name='roleId'
										type='select'
										label={t`web.team.sharedModal.userRoleLabel`}
										width='100%'
										dropdownOptions={(
											organizationRoles || []
										).map((r) => ({
											display: t(r.displayKey),
											value: r.id,
										}))}
										disabled={!isAdmin}
									/>
								</StyledRow>
								{!!showMessage && (
									<StyledInfoMessage>
										<InfoMessage
											messageEnum={showMessage}
											showIf={!!showMessage}
										/>
									</StyledInfoMessage>
								)}
								<StyledButtonRow>
									{!isThirdPartyManaged && (
										<StyledButtonWrapper>
											<ButtonLg
												onClick={
													handleResetPasswordClick
												}
												text={t`web.shared.resetPassword`}
												primary={false}
												width='200px'
												dataId='resetPassword-button'
											/>
										</StyledButtonWrapper>
									)}

									<ButtonLg
										primary={true}
										// TODO: after making one change to field and saving, without refreshing the page,
										// we are unable to revert change and save with original value
										disabled={
											!(isValid && dirty) || isSubmitting
										}
										text={t`web.profile.saveChangesButton`}
										type='submit'
										dataId='saveChanges-button'
										width='200px'
										loading={updatingUser}
									/>
								</StyledButtonRow>
							</StyledForm>
						</StyledFormContent>
					</StyledFormContainer>
				)}
			</Formik>
		</>
	);
};

const StyledFormContainer = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.xxl} ${spacing.xl};
		width: 1000px;
	`
);

const StyledFormContent = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-top: ${spacing.lg};
	`
);

const StyledForm = styled(Form)`
	width: 900px;
	margin-left: 36px;
`;

const StyledTitle = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

const StyledH2 = styled(H2)(
	({ theme: { color, weight, fontSize } }) => css`
		color: ${color.formText};
		font-weight: ${weight.regular};
		display: flex;
		align-items: center;
		font-size: ${fontSize._32};
		line-height: 44px;
	`
);

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

const StyledButtonRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: start;
		padding-top: ${spacing.lg};
	`
);
const StyledButtonWrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin: 0 ${spacing.xl} 0 0;
	`
);

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => css`
		margin: ${spacing.lg} 0 0 0;
		display: flex;
		justify-content: start;
	`
);
