export enum ReferenceID {
	RedDCRScore = 'RedDCRScore',
	YellowDCRScore = 'YellowDCRScore',
	GreenDCRScore = 'GreenDCRScore',
	HighRiskDementia = 'HighRiskDementia',
	NoRiskDementia = 'NoRiskDementia',
	Memory = 'Memory',
	ExecVascular = 'ExecVascular',
	Mixed = 'Mixed',
	Cholinergic = 'Cholinergic',
	Parkinsonism = 'Parkinsonism',
	Tremor = 'Tremor',
}

export interface Reference {
	id: ReferenceID;
	title: string;
	articles: Article[];
	content: string[];
}

interface Article {
	title: string;
	url: string;
	isZendeskUrl?: boolean;
}
