import { useCallback } from 'react';
import {
	DocumentNode,
	OperationVariables,
	useApolloClient,
} from '@apollo/client';

// Addresses issue where promise was not being resolved resulting in hung up network calls
// https://github.com/apollographql/apollo-client/issues/9284#issuecomment-1050603696
export function useCustomLazyQuery<
	TData = any,
	TVariables extends OperationVariables = OperationVariables
>(_query: DocumentNode) {
	const client = useApolloClient();
	return useCallback(
		(variables: TVariables) =>
			client.query<TData, TVariables>({
				query: _query,
				variables: variables,
				fetchPolicy: 'network-only',
			}),
		[client, _query]
	);
}
