import { Auth, AuthFactory, AuthProviderType } from '@lh/eng-shared-auth';

import { config } from '../../config';

import { AuthStorage } from './AuthStorage';

function createAuthModule(sso: boolean) {
	const baseUrl = `${config.apiProtocol}://${config.apiHost}:${config.apiPort}/${config.apiDomain}`;

	if (config.preferredUserProvider === 'auth0') {
		return new AuthFactory(
			{
				type: AuthProviderType.Auth0,
				clientId: config.auth0ClientId,
				domain: config.auth0Domain,
				cookieDomain: config.cookieDomain,
				baseUrl,
				sso,
			},
			new AuthStorage()
		).build();
	}

	return new AuthFactory({
		type: AuthProviderType.Cognito,
		amplifyRegion: config.amplifyRegion,
		amplifyUserPoolId: config.amplifyUserPoolId,
		amplifyUserPoolWebClientId: config.amplifyUserPoolWebClientId,
		host: config.host,
		baseUrl,
	}).build();
}

let module: Auth = createAuthModule(true);

export function updateAuthModule(sso: boolean) {
	module = createAuthModule(sso);
}

export { module as Auth };
