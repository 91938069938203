import { Group, UnstyledButton } from '@mantine/core';

import { theme } from 'components/providers/StyleProvider/theme';
import { ButtonSmText } from '../designSystem';
import { PencilOutlined } from '../designSystem/Icons/svgs/Svgs';

interface EditPenProps {
	onClick: () => void;
	text: string;
}

export function EditPen({ text, onClick }: Readonly<EditPenProps>) {
	return (
		<UnstyledButton data-testid='edit-button' onClick={onClick}>
			<Group gap={4} wrap='nowrap'>
				<PencilOutlined
					height={24}
					width={24}
					color={theme.colors.blue}
				/>
				<ButtonSmText
					style={{
						padding: 0,
						color: theme.colors.blue,
					}}
				>
					{text}
				</ButtonSmText>
			</Group>
		</UnstyledButton>
	);
}
