import { Organization } from '@lh/eng-platform-organization-service-rest-client';

import { useApolloClient } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { getOrganization } from 'api/organization';
import { QueryKey } from 'api/query';
import { buildOrgPreferences } from 'components/context/buildOrganizationPreferences';
import { Memoize } from 'helpers/Memoize';
import { SessionStorageEnum } from '../../../enums/sessionStorageKeysEnum';
import { Auth } from '../../../features/auth-service';
import {
	CurrentUserQuery,
	useCurrentUserLazyQuery,
} from '../../../generated/graphql';
import { CurrentUser } from '../../../types';
import {
	clearSessionStorage,
	getSessionStorage,
	setSessionStorage,
} from '../../../utils/sessionStorage';
import { UserContext, nulloUser } from '../../context/UserContext';

const clearImpersonatedOrgSession = () =>
	clearSessionStorage(SessionStorageEnum.ImpersonatedOrg);

export const UserContextProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const client = useApolloClient();
	const queryClient = useQueryClient();
	const impersonatedOrg = getSessionStorage(
		SessionStorageEnum.ImpersonatedOrg
	);

	const [loggedIn, setLoggedIn] = useState(true);
	const [currentUser, setCurrentUser] = useState<CurrentUser>(nulloUser);
	const [orgLoaded, setOrgLoaded] = useState(false);
	const [isImpersonation, setIsImpersonation] = useState(false);

	const onCurrentUserCompleted = (data: CurrentUserQuery) => {
		const user = data?.currentUser?.currentUser as CurrentUser;
		if (user) {
			setLoggedIn(true);
			setCurrentUser(
				impersonatedOrg
					? {
							...user,
							originalOrganization: {
								organizationId: user.organizationId,
							},
							...impersonatedOrg,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  }
					: user
			);
		}
	};

	const [getCurrentUser] = useCurrentUserLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: onCurrentUserCompleted,
		onError: () => setLoggedIn(false),
	});

	const onOrganizationQueryCompleted = (data?: Organization) => {
		if (!data) return;

		const preferences = buildOrgPreferences(data);

		setCurrentUser({ ...currentUser, ...preferences });
		setOrgLoaded(true);

		if (isImpersonation) {
			setIsImpersonation(false);
			setSessionStorage(
				{
					organizationId: currentUser.organizationId,
					operations: currentUser.operations,
				},
				SessionStorageEnum.ImpersonatedOrg
			);
		}
	};

	const onLogin = useCallback((_user: CurrentUser) => {
		setLoggedIn(true);
		setCurrentUser((state) => ({ ...state, ..._user }));
	}, []);

	const logout = useCallback(async () => {
		try {
			await Auth.logout();
			setLoggedIn(false);
			setCurrentUser(nulloUser);
			await client.clearStore();
			clearImpersonatedOrgSession();
		} catch (error) {
			console.error('error signing out: ', error);
		}
	}, [client]);

	const updateUser = useCallback(
		(user: CurrentUser, isImpersonation?: boolean) => {
			unstable_batchedUpdates(() => {
				if (isImpersonation) {
					setSessionStorage(
						{
							organizationId: user.organizationId,
							operations: user.operations,
						},
						SessionStorageEnum.ImpersonatedOrg
					);
					setIsImpersonation(true);
				}
				setCurrentUser(user);
			});
		},
		[setCurrentUser, setIsImpersonation]
	);

	const clearImpersonation = useCallback(() => {
		if (currentUser.originalOrganization) {
			const currentUserWithOriginalOrg = {
				...currentUser,
				organizationId:
					currentUser?.originalOrganization.organizationId,
				originalOrganization: undefined,
			};
			setCurrentUser(currentUserWithOriginalOrg);
			clearImpersonatedOrgSession();
		}
	}, [currentUser, setCurrentUser]);

	const refetchCurrentUser = useCallback(() => {
		getCurrentUser({
			variables: {
				orgId: currentUser.organizationId,
			},
		});
	}, [currentUser.organizationId, getCurrentUser]);

	useEffect(() => {
		if (!loggedIn) {
			queryClient.clear();
		}
	}, [loggedIn, queryClient]);

	useEffect(() => refetchCurrentUser(), [refetchCurrentUser]);

	useEffect(() => {
		async function _getOrganization(orgId: string) {
			const response = await queryClient.fetchQuery({
				queryKey: [QueryKey.Organization, orgId],
				queryFn: ({ signal }) => getOrganization(orgId, signal),
				staleTime: Infinity,
			});
			onOrganizationQueryCompleted(response);
		}
		if (currentUser.organizationId) {
			_getOrganization(currentUser.organizationId);
		}
	}, [currentUser.organizationId]);

	if (loggedIn && (!orgLoaded || !currentUser)) {
		return null;
	}

	return (
		<UserContext.Provider
			value={{
				currentUser,
				setCurrentUser: updateUser,
				updateCurrentUser: getCurrentUser,
				onLogin,
				logout,
				clearImpersonation,
				refetchCurrentUser,
			}}
		>
			<Memoize>{children}</Memoize>
		</UserContext.Provider>
	);
};
