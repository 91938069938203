import { ConcernType as ConcernTypeRest } from '@lh/eng-platform-organization-service-rest-client';
import { PaginatedPatients } from '@lh/eng-platform-subject-service-rest-client';

import { SimpleGrid, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18n';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { QueryKey } from 'api/query';
import { UserContext } from 'components/context';
import { getDCRScoreCounts } from 'components/report/AssessmentComponents/MiniModal/MiniModalOverview/DcrPerformanceScoreReport/helpers';
import { shouldShowAdditionalDcr } from 'components/report/AssessmentComponents/MiniModal/MiniModalOverview/MiniModalOverview.utils';
import { DCRSummaryWidget } from 'components/report/CDS/DCR';
import { Pathway } from 'components/report/CDS/Pathway';
import { ReferencesModal } from 'components/report/CDS/Pathway/Modals';
import { PathwayID } from 'components/report/CDS/Pathway/Pathway.types';
import { NoteTemplate } from 'components/report/NoteTemplate/NoteTemplate';
import type { NoteTemplateState } from 'components/report/NoteTemplate/NoteTemplate.types';
import { getAssessmentData } from 'components/report/cognitiveEvalParseData';
import { FormattedAssessmentResults } from 'components/report/cognitiveEvalParseData/cognitiveEvalParseData';
import { Metric } from 'components/report/segmentUtilities/metrics';
import {
	columns,
	mapData,
} from 'components/shared/DataTable/schemas/resultsSchema';
import { LinusDataTable } from 'components/shared/LinusDataTable';
import { LinusModalDialog } from 'components/shared/LinusModalDialog';
import { LoadingDots } from 'components/shared/LoadingDots';
import { ButtonLg } from 'components/shared/designSystem';
import { useOrganizationFeatures } from 'components/shared/hooks';
import { useGetReport } from 'components/shared/hooks/useGetReport';
import { icons } from 'enums/icons';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import {
	BatteryType,
	ConcernType,
	FeatureType,
	SegmentType,
	useCdsRunLazyQuery,
} from 'generated/graphql';
import { Report } from '../../../../components/report/CognitiveEvaluation.types';
import { FileNotFound } from '../../../FileNotFound';
import { EditParticipantInfoForm } from '../../../participants/EditParticipantInfoForm';
import { LinusModal } from '../../../shared/LinusModal';
import { ArchivePatientConfirmation } from '../../ArchivePatient/ArchivePatientConfirmation';

import { PathwayWidget } from './PathwayWidget';
import {
	useBatteryResults,
	useFormattedData,
	usePatient,
} from './PatientDetails.controller';
import type { MockData, PatientScores } from './PatientDetails.fixtures';
import {
	ActionsContainer,
	Body,
	DefaultPathway,
	HeaderContainer,
} from './PatientDetails.style';
import { ProfileHeader } from './ProfileHeader';

interface Props {
	isTest?: boolean;
	mockData?: MockData;
	mockBatteryResult?: Report;
	mockPatientId?: string;
}

const EMPTY_SCORES: PatientScores = {
	DCR: null,
	clockDrawing: null,
	delayedRecall: null,
	immediateRecall: null,
};

const cdsV2BatteryTypes: BatteryType[] = [
	BatteryType.Dcr,
	BatteryType.CognitiveEvaluation,
	BatteryType.DcrTrails,
	BatteryType.DcrPlus,
	BatteryType.CcePlus,
];

export function PatientDetails_V2({
	isTest = false,
	mockData,
	mockBatteryResult,
	mockPatientId,
}: Readonly<Props>) {
	const { currentUser } = useContext(UserContext);
	const archivePatientFlag = useFeatureFlag(FeatureFlags.ArchivePatient);
	const location = useLocation();
	const navigate = useNavigate();
	const features = useOrganizationFeatures();
	const { patientId } = useParams() as { patientId: string };
	const queryClient = useQueryClient();
	const [searchParams] = useSearchParams();
	const theme = useTheme();

	const { data, loading, dateFormat, defaultTimezone } = useBatteryResults(
		mockData?.batteryResults,
		mockPatientId ?? patientId
	);

	const [getCdsForParticipant, { data: cdsData, loading: cdsLoading }] =
		useCdsRunLazyQuery();
	const {
		patientData,
		isLoading: patientLoading,
		refetchPatient,
	} = usePatient(mockData?.patient, mockPatientId ?? patientId);
	const { lastBatteryId, mostRecent } = findFirstNonProcessingBattery();
	const { organization, patient } = useFormattedData(
		mockData?.data,
		patientData
	);

	const {
		data: {
			battery: batteryResultResponse,
			participant: batteryParticipant,
		},
		loading: { loadingBattery: loadingBatteryResult },
	} = useGetReport({
		batteryResultId: lastBatteryId,
		orgId: currentUser.organizationId,
		participantId: patientId,
	});

	const batteryResult = mockBatteryResult ?? batteryResultResponse;

	const [showReferences, references] = useDisclosure(false);
	const [showEditPatient, editPatient] = useDisclosure(false);

	const pathwayRef = useRef<HTMLInputElement>(null);

	const [isViewPathwayOpen, setIsViewPathwayOpen] = useState(false);
	const [concernsList, setConcernsList] = useState<ConcernType[]>([]);
	const [scores, setScores] = useState(EMPTY_SCORES);
	const [noteTemplate, setNoteTemplate] = useState<NoteTemplateState>({
		open: false,
		batteryResultId: '',
	});
	const [showArchiveConfirmation, setArchiveConfirmation] = useState(false);
	const [showArchivedSuccessfully, setArchivedSuccessfuly] = useState(false);
	const [showCannotArchive, setCannotArchive] = useState(false);

	const hasMmseFlag = features.includes(FeatureType.MmseEstimation);
	const hasMipFlag = features.includes(
		FeatureType.MemoryImpairmentProbability
	);
	const nodes = data?.participant.batteryResults.nodes ?? [];
	const showAdditionalDcr = shouldShowAdditionalDcr(
		hasMmseFlag,
		hasMipFlag,
		batteryResult?.batteryResultById?.battery.batteryType
	);

	useEffect(() => {
		refetchPatient();
	}, []);

	useEffect(() => {
		if (location.state && pathwayRef.current) {
			const { scrollToPathway } = location.state as {
				scrollToPathway: boolean;
			};

			if (scrollToPathway) {
				pathwayRef.current?.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				});
			}
		}
	}, [location.state, pathwayRef.current]);

	useEffect(() => {
		if (searchParams.get('source') === 'provider_pdf') {
			sendEventData({
				eventType: AnalyticsAction.ClickedFollowup,
				eventProperties: {
					source: 'PDF',
				},
			});
		}
	}, []);

	useEffect(() => {
		if (batteryResult) {
			if (nodes.length === 0) {
				setScores(EMPTY_SCORES);
			} else {
				const { miniModalAssessment } = getAssessmentData({
					batteryResult,
				});
				if (miniModalAssessment) {
					const dcrScore = miniModalAssessment?.metricItems[
						'dcr_score'
					] || {
						value: -1,
						algorithmVersion: '0.1',
					};

					setScores(getScores(dcrScore, miniModalAssessment));
				}
			}
		}
	}, [batteryResult, nodes]);

	useEffect(() => {
		async function getCdsData() {
			try {
				await getCdsForParticipant({
					variables: {
						batteryResultId: lastBatteryId,
					},
				});
			} catch (error) {
				console.error(error);
			}
		}

		if (!loading && lastBatteryId) {
			getCdsData().catch((error) =>
				console.error(
					`There was an error fetching getReportData from PatientDetails_V2: ${error}`
				)
			);
		}
	}, [loading, lastBatteryId]);

	useEffect(() => {
		if (currentUser && cdsData && !cdsLoading) {
			const managementConcerns = cdsData.cdsRun.concerns.filter(
				(concern) => {
					return currentUser.organizationCdsConcerns.includes(
						concern as ConcernTypeRest
					);
				}
			);
			setConcernsList(managementConcerns);
		}
	}, [cdsLoading, currentUser, cdsData]);

	function getScores(
		score: Metric,
		miniModalAssessment: FormattedAssessmentResults
	) {
		if (miniModalAssessment.segmentResults.length === 0)
			return EMPTY_SCORES;
		const immediateRecallData = miniModalAssessment.segmentResults.find(
			(subscoreSegment) =>
				subscoreSegment?.segmentType === SegmentType.RecallImmediate
		);

		const counts = getDCRScoreCounts({
			score,
			miniModalSubscoreSegments: miniModalAssessment.segmentResults,
		});

		const dctClockData = miniModalAssessment.segmentResults.find(
			(subscoreSegment) =>
				subscoreSegment?.segmentType === SegmentType.Clock
		)?.metricItems;

		const dctClockScore = dctClockData?.DCTScore
			? Math.floor(Number(dctClockData.DCTScore.value))
			: 0;

		return {
			DCR: Number(score.value),
			clockDrawing: counts.dctClock.correct ?? 0,
			delayedRecall: Number(counts.delayedRecall.correct),
			immediateRecall: Number(
				immediateRecallData?.metricItems?.correct_words_count?.value
			),
			commandPlacement: dctClockData?.COMComponentPlacement_s?.value ?? 0,
			copyPlacement: dctClockData?.COPComponentPlacement_s?.value ?? 0,
			commandOscillatory:
				dctClockData?.COMOscillatoryMotion_s?.value ?? 0,
			copyOscillatory: dctClockData?.COPOscillatoryMotion_s?.value ?? 0,
			dctClockScore,
			commandMotor: dctClockData?.COMSimpleMotor_s?.value ?? 0,
			copyMotor: dctClockData?.COPSimpleMotor_s?.value ?? 0,
			commandDraw: dctClockData?.COMDrawingSize_s?.value ?? 0,
			copyDraw: dctClockData?.COPDrawingSize_s?.value ?? 0,
			mmseEstimation:
				miniModalAssessment.metricItems?.mmse_prediction?.value,
		};
	}

	function findFirstNonProcessingBattery() {
		if (!data) {
			return {
				lastBatteryId: '',
				mostRecent: '',
			};
		}

		const firstNode = mapData(data.participant).find(
			(e) =>
				!e.delayed &&
				!e.processing &&
				cdsV2BatteryTypes.includes(e.batteryType)
		);

		return {
			lastBatteryId: firstNode?.id ?? '',
			mostRecent: firstNode?.date ?? '',
		};
	}

	function handleOnViewPress() {
		return navigate(`/results/${patientId}/${lastBatteryId}`);
	}

	function handleViewPathwayModal() {
		sendEventData({
			eventType: AnalyticsAction.ClickedPathwayOverview,
		});
		setIsViewPathwayOpen(true);
	}

	function handleEndReached() {
		sendEventData({ eventType: AnalyticsAction.ViewedPathway });
	}

	function handleResultsEndReached(inView: boolean) {
		if (inView) {
			sendEventData({ eventType: AnalyticsAction.ViewedPatientResults });
		}
	}

	function handleReferencesClick() {
		sendEventData({
			eventType: AnalyticsAction.ClickedRef,
			eventProperties: {
				source: 'Patient profile',
			},
		});

		references.open();
	}

	function handleClosePathwayModal() {
		setIsViewPathwayOpen(false);
	}

	function handleArchivedSuccessfully() {
		setArchiveConfirmation(false);
		setArchivedSuccessfuly(true);
		setCannotArchive(false);
		queryClient.setQueryData<PaginatedPatients>(
			[QueryKey.Patients, currentUser.organizationId],
			(data) => {
				if (!data) {
					return;
				}
				return {
					...data,
					totalCount: +data.totalCount - 1,
				};
			}
		);
	}

	function handleArchivedSuccessfullyClose() {
		setArchivedSuccessfuly(false);
		navigate('/patients');
	}

	function handleCannotArchive() {
		setArchiveConfirmation(false);
		setArchivedSuccessfuly(false);
		setCannotArchive(true);
	}

	function handleEditParticipantSubmit() {
		refetchPatient();
		editPatient.close();
	}

	function handleNoteTemplateClose() {
		setNoteTemplate({ open: false, batteryResultId: '' });
	}

	function handleNoteTemplateOpen(batteryResultId: string) {
		setNoteTemplate({ open: true, batteryResultId });
	}

	if (!isTest && patientLoading) {
		return <LoadingDots />;
	}

	if (!isTest && loading) {
		return <LoadingDots />;
	}

	if (!isTest && loadingBatteryResult) {
		return <LoadingDots />;
	}

	if (!isTest && !data?.participant) {
		return <FileNotFound />;
	}

	return (
		<>
			<Header>{organization.name}</Header>
			<Stack gap={24} id='patient-details-container'>
				<ProfileHeader
					patient={patientData}
					onPatientEdit={editPatient.open}
				/>
				<SimpleGrid
					cols={showAdditionalDcr ? 1 : 2}
					spacing={24}
					verticalSpacing={24}
				>
					<DCRSummaryWidget
						showAdditionalDcrResults={showAdditionalDcr}
						canViewReport={nodes.length > 0}
						data={{
							score: {
								DCR: scores.DCR,
								clockDrawing: scores.clockDrawing,
								delayedRecall: scores.delayedRecall,
							},
							mmsePrediction: scores.mmseEstimation,
							mostRecent:
								mostRecent.length > 0
									? mostRecent
									: t('web.patients.info.notesDefaultValue'),
							dateFormat,
							defaultTimeZone:
								currentUser.organizationDefaultTimezone,
						}}
						onViewReport={() => handleOnViewPress()}
					/>
					<Anchor ref={pathwayRef} />
				</SimpleGrid>
				<PathwayWidget
					onClick={{
						overview: handleViewPathwayModal,
						references: handleReferencesClick,
					}}
					onEndReached={handleEndReached}
				>
					{cdsLoading ? (
						<LoadingDots />
					) : (
						<Pathway
							patient={{
								batteryResult,
								batteryParticipant,
								lastBatteryId,
								name: patient.firstName,
								score: {
									...scores,
								},
							}}
							pathway={{
								pathwayInstanceId:
									cdsData?.cdsRun.pathway ?? PathwayID.Empty,
								concernsList,
							}}
						/>
					)}
				</PathwayWidget>
				{data && (
					<>
						<LinusDataTable
							title={t`web.sessionHistory`}
							titleIcon={icons.Results}
							columns={columns(
								theme,
								dateFormat,
								defaultTimezone,
								features,
								handleNoteTemplateOpen
							)}
							tableData={mapData(data.participant)}
							operations={[]}
							hideFooter={true}
							headerColor={theme.color.headerTextSecondary}
							deviantWidth='1090px'
							rowsPerPage={nodes.length}
						/>
						<InView
							onChange={(inView) =>
								handleResultsEndReached(inView)
							}
						/>
					</>
				)}
			</Stack>

			{!isTest && showEditPatient && patientData && (
				<LinusModal
					title={t`web.patients.info.title`}
					onClose={editPatient.close}
					titleIcon={icons.UserSolid}
					titleIconColor={theme.color.iconAddUserSolid}
				>
					<EditParticipantInfoForm
						participant={patientData}
						onArchive={() => {
							editPatient.close();
							setArchiveConfirmation(true);
						}}
						onCancel={editPatient.close}
						onEditParticipantSubmit={handleEditParticipantSubmit}
					/>
				</LinusModal>
			)}

			{isViewPathwayOpen && (
				<LinusModal
					title='Linus Health’s Recommended Pathway'
					onClose={handleClosePathwayModal}
				>
					<DefaultPathway
						src='/img/default-pathway.svg'
						alt='Default pathway'
					/>
					<ActionsContainer>
						<ButtonLg
							onClick={handleClosePathwayModal}
							text={t('cds.concerns.close')}
							width='170px'
						/>
					</ActionsContainer>
				</LinusModal>
			)}

			{showReferences && <ReferencesModal onClose={references.close} />}

			{archivePatientFlag && showArchiveConfirmation && (
				<LinusModal
					title={t`web.archivePatientModal.title`}
					titleIcon={icons.RemoveUserSolid}
					titleIconColor={theme.color.alertError}
					onClose={() => setArchiveConfirmation(false)}
				>
					<ArchivePatientConfirmation
						type='EditParticipant'
						patientId={patientData?.id ?? ''}
						firstName={patientData?.firstName ?? ''}
						lastName={patientData?.lastName ?? ''}
						organizationName={currentUser.organizationName}
						onCancel={() => setArchiveConfirmation(false)}
						onFail={handleCannotArchive}
						onSuccess={handleArchivedSuccessfully}
					/>
				</LinusModal>
			)}
			{noteTemplate.open && (
				<NoteTemplate
					batteryDataLoading={loading}
					batteryData={data}
					participantId={patientId}
					batteryResultId={noteTemplate.batteryResultId}
					orgId={currentUser.organizationId}
					onClose={handleNoteTemplateClose}
				/>
			)}
			{archivePatientFlag && showArchivedSuccessfully && (
				<LinusModalDialog
					width='485px'
					title={t`web.archivePatientModal.archived`}
					titleIcon={icons.CheckmarkSolid}
					titleIconColor={theme.color.alertSuccess}
					acceptButtonText={t`web.archivePatientModal.close`}
					acceptButtonCallback={handleArchivedSuccessfullyClose}
					onClose={handleArchivedSuccessfullyClose}
				>
					<Trans
						i18nKey='web.archivePatientModal.success'
						values={{
							firstName: patientData?.firstName,
							lastName: patientData?.lastName,
						}}
						components={{
							b: <strong />,
						}}
					/>
				</LinusModalDialog>
			)}

			{archivePatientFlag && showCannotArchive && (
				<LinusModalDialog
					width='485px'
					title={t`web.archivePatientModal.cannotArchive`}
					titleIcon={icons.AlertShieldSolid}
					titleIconColor={theme.color.alertError}
					acceptButtonText={t`web.archivePatientModal.close`}
					acceptButtonCallback={() => setCannotArchive(false)}
					onClose={() => setCannotArchive(false)}
				>
					<Trans i18nKey='web.archivePatientModal.takingAssessment' />
				</LinusModalDialog>
			)}
		</>
	);
}

function Header({ children }: { children: React.ReactNode }) {
	return (
		<HeaderContainer data-testid='patient-details-header'>
			<Body>{children}</Body>
		</HeaderContainer>
	);
}

const Anchor = styled.div`
	align-self: flex-end;
	height: 0px;
`;
