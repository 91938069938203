import { useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function getUser(
	organizationId: string,
	userId: string,
	signal?: AbortSignal
) {
	if (!organizationId) {
		throw new Error("useGetUser: organizationId can't be null");
	}

	if (!userId) {
		throw new Error("useGetUser: userId can't be null");
	}

	const service = await getOrganizationService();
	const { data } = await service.getUserById(
		{ organizationId, userId },
		{ signal }
	);
	return data;
}

export function useUserQuery(organizationId: string, userId: string) {
	return useQuery({
		enabled: !!organizationId && !!userId,
		meta: {
			errorMessage: `Error fetching user with organizationId: ${organizationId} and userId: ${userId}`,
		},
		queryKey: [QueryKey.User, organizationId, userId],
		queryFn: ({ signal }) => getUser(organizationId, userId, signal),
		staleTime: Infinity,
	});
}
