import { useContext, useEffect, useRef } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { usePrevious } from '@mantine/hooks';

import { updateAmplitudeWithProps } from 'analytics';
import { AuthenticationLayout } from '../../auth/AuthenticationLayout';
import { config } from 'config';
import { UserContext } from '../../context/UserContext';
import { UserStatus } from 'generated/graphql';
import { Layout } from '../../layout/Layout';

export const LoggedInCheck = (): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	const location = useLocation();

	const setAmplitudeProperties = useRef(false);

	const prevUser = usePrevious(currentUser);
	useEffect(() => {
		if (!prevUser && currentUser?.id && !setAmplitudeProperties.current) {
			setAmplitudeProperties.current = true;
			updateAmplitudeWithProps(currentUser);
		}

		if (
			prevUser?.organizationId &&
			currentUser?.organizationId &&
			prevUser.organizationId !== currentUser.organizationId
		) {
			updateAmplitudeWithProps(currentUser);
		}
	}, [prevUser, currentUser]);

	if (
		currentUser?.userStatus === UserStatus.Invited &&
		config.preferredUserProvider === 'auth0'
	) {
		return <AuthenticationLayout finishingSignup={true} />;
	}
	if (currentUser?.id && location.pathname) {
		// If you're already logged in, we should redirect to home page
		if (
			location.pathname.match('/auth/login') ||
			location.pathname.match('/auth/finish-signup')
		) {
			return <Navigate to={'/'} />;
		}
		if (location.pathname.match('/auth/reset-password')) {
			//If we match the above locations we allow the user to access
			//even if it's signed-in
			return <AuthenticationLayout />;
		}
	}
	return currentUser?.id ? <Layout /> : <AuthenticationLayout />;
};
