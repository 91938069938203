import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type AddCdsRecommendationsToBatteryResultInput = {
  /** The version of the CDS Rules engine used to generate the recommendations */
  algorithmVersion: Scalars['String'];
  /** The unique identifier for the battery result which was used to generate the recommendations */
  batteryResultId: Scalars['ID'];
  /** Clinical Decision Support recommendations for the battery result above */
  cdsRecommendations: Array<Scalars['String']>;
};

export type AddResultMetricsInput = {
  algorithmVersion: Scalars['String'];
  assessmentResultId?: InputMaybe<Scalars['ID']>;
  batteryResultId: Scalars['ID'];
  metricsEntries: Array<MetricsEntry>;
  participantId: Scalars['ID'];
  segmentResultId?: InputMaybe<Scalars['ID']>;
};

/** Keys available for submission to analytics service, currently "amplitude" */
export const AnalyticsAction = {
  AddedAvatar: 'ADDED_AVATAR',
  AddedNewOrganization: 'ADDED_NEW_ORGANIZATION',
  AddedNewPatient: 'ADDED_NEW_PATIENT',
  /** Event for when a user clicked Archive Patient from the kebab menu */
  ArchivedPatient: 'ARCHIVED_PATIENT',
  AssignedBattery: 'ASSIGNED_BATTERY',
  /** Event for each click of a tab in the CCE Report */
  ClickedCceTab: 'CLICKED_CCE_TAB',
  /** Event for when a user clicked on Learn More on Consider Cognitive Care Planning Visit box */
  ClickedCcp: 'CLICKED_CCP',
  /** Event for when a user clicked on More Details on Concerns List */
  ClickedConcernsDetails: 'CLICKED_CONCERNS_DETAILS',
  /** Event for when a user clicked on one of the external links */
  ClickedExternalLink: 'CLICKED_EXTERNAL_LINK',
  /** Event for when a user clicked on View Recommended Followup */
  ClickedFollowup: 'CLICKED_FOLLOWUP',
  /** Event for when a user clicked on View Anticholinergic medications list */
  ClickedMeds: 'CLICKED_MEDS',
  /** Event for when a user clicked on Note Template icon inside the Session History data table from Patient Details page. */
  ClickedNote: 'CLICKED_NOTE',
  /** Event for when a user clicked on Show Notes button from Patient Profile page. */
  ClickedNotes: 'CLICKED_NOTES',
  /** Event for when a user clicked on Learn more on the pathway overview modal */
  ClickedPathwayLearnmore: 'CLICKED_PATHWAY_LEARNMORE',
  /** Event for when a user clicked on View Pathway Overview on Recommended Next Steps card */
  ClickedPathwayOverview: 'CLICKED_PATHWAY_OVERVIEW',
  /** Event for when a user clicked on References link from Recommended Next Steps card or View details modal */
  ClickedRef: 'CLICKED_REF',
  /** Event for when a user clicked on link in References modal */
  ClickedRefLink: 'CLICKED_REF_LINK',
  /** Event for when a user clicked on View Composite Scales button on Subscores card from Results page */
  ClickedScales: 'CLICKED_SCALES',
  /** Event for when a user clicked on View Scoring Details button on DCR card from Results page */
  ClickedScoring: 'CLICKED_SCORING',
  /** Event for each click of the hide arrow on the sidebar */
  ClickedSidebarHideArrow: 'CLICKED_SIDEBAR_HIDE_ARROW',
  /** Event for each click of a sidebar item */
  ClickedSidebarItem: 'CLICKED_SIDEBAR_ITEM',
  /** Event for when a user clicked on Ta Notes inside the Web Report. */
  ClickedTanotes: 'CLICKED_TANOTES',
  DownloadedReport: 'DOWNLOADED_REPORT',
  /** Event for when a CDS Full Article page is abandoned or escaped */
  EscapedCceCdsFullArticle: 'ESCAPED_CCE_CDS_FULL_ARTICLE',
  /** The accordion is expanded by clicking on it. */
  ExpandedMmseAccordion: 'EXPANDED_MMSE_ACCORDION',
  InvitedNewUser: 'INVITED_NEW_USER',
  LoggedOut: 'LOGGED_OUT',
  PlayedRecording: 'PLAYED_RECORDING',
  RemovedBattery: 'REMOVED_BATTERY',
  ResetPassword: 'RESET_PASSWORD',
  SavedAccountChanges: 'SAVED_ACCOUNT_CHANGES',
  /** Event for when a user makes a selection from the reasons for testing dropdown. */
  SearchedReasonEnd: 'SEARCHED_REASON_END',
  /** Event for when a user starts typing into search box for reasons for testing. */
  SearchedReasonStart: 'SEARCHED_REASON_START',
  /** Generic event for searching on a data table */
  SearchingTable: 'SEARCHING_TABLE',
  /** Please add a description for all of these analytics Keys */
  StartedSession: 'STARTED_SESSION',
  /** Event for when a user is switching to a different organization than the default one */
  SwitchedOrg: 'SWITCHED_ORG',
  UpdatedOrganization: 'UPDATED_ORGANIZATION',
  UpdatedPatient: 'UPDATED_PATIENT',
  UpdatedUser: 'UPDATED_USER',
  ViewedContactUs: 'VIEWED_CONTACT_US',
  /** Clicking the VIEW DETAILS button on the MIP component */
  ViewedMipDetails: 'VIEWED_MIP_DETAILS',
  ViewedMyTeam: 'VIEWED_MY_TEAM',
  ViewedOrganization: 'VIEWED_ORGANIZATION',
  ViewedOrganizations: 'VIEWED_ORGANIZATIONS',
  /** Event for when user scrolled to the bottom of the pathway */
  ViewedPathway: 'VIEWED_PATHWAY',
  ViewedPatients: 'VIEWED_PATIENTS',
  ViewedPatientDetails: 'VIEWED_PATIENT_DETAILS',
  /** Event for when user scrolled to the bottom of the patients results table */
  ViewedPatientResults: 'VIEWED_PATIENT_RESULTS',
  ViewedPrivacyPolicy: 'VIEWED_PRIVACY_POLICY',
  ViewedProfile: 'VIEWED_PROFILE',
  ViewedRegulatoryLabel: 'VIEWED_REGULATORY_LABEL',
  ViewedReport: 'VIEWED_REPORT',
  ViewedResults: 'VIEWED_RESULTS',
  ViewedSupport: 'VIEWED_SUPPORT',
  ViewedTermsAndPrivacy: 'VIEWED_TERMS_AND_PRIVACY',
  ViewedTermsOfUse: 'VIEWED_TERMS_OF_USE'
} as const;

export type AnalyticsAction = typeof AnalyticsAction[keyof typeof AnalyticsAction];
export type ArtifactPresignedUrlResponse = {
  __typename?: 'ArtifactPresignedURLResponse';
  /** Array of error messages encountered */
  errors: Array<Scalars['String']>;
  /** Success flag */
  success?: Maybe<Scalars['Boolean']>;
  /** The presigned URL for artifact download */
  url?: Maybe<Scalars['String']>;
};

/**
 * Assessments are a collection of one or more segments that have a score associated with it.
 * Will include different segments ranging from clock tests to lifestyle questionnaires.
 */
export type Assessment = Node & {
  __typename?: 'Assessment';
  /** Type of assessment */
  assessmentType: AssessmentType;
  /** ID of the assessment */
  id: Scalars['ID'];
  /** Indication that there will or will not be metrics coming back from this assessment */
  isExpectingMetrics: Scalars['Boolean'];
  /** Name of the assessment */
  name: Scalars['String'];
  /** The collection of different segments in each assessment */
  segments: Array<Segment>;
};

/** Contains data related to the lifecycle of an assessment */
export type AssessmentResult = Node & {
  __typename?: 'AssessmentResult';
  /** The assessment that the results are based on */
  assessment: Assessment;
  /** Indicates analysis engine processing error */
  hasProcessingError: Scalars['Boolean'];
  /** ID of the assessment result (GUID) */
  id: Scalars['ID'];
  /** When all segment results that are expecting data are `PARTICIPANT_COMPLETE` */
  isComplete: Scalars['Boolean'];
  /** A collection of metrics that are specific to the assessment generated after all child segment results are `ANALYSIS_COMPELTE` */
  metricItems: MetricItemConnection;
  /** A collection of segment result data related to this assessment */
  segmentResults: SegmentResultConnection;
};


/** Contains data related to the lifecycle of an assessment */
export type AssessmentResultMetricItemsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};


/** Contains data related to the lifecycle of an assessment */
export type AssessmentResultSegmentResultsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};

/** Response data for assessment results query when passing in a connection */
export type AssessmentResultConnection = Connection & {
  __typename?: 'AssessmentResultConnection';
  /** Contains the initial data from the connection input as well as page count and total count */
  connectionInfo?: Maybe<ConnectionInfo>;
  /** Any underlying errors while retrieving data */
  errors: Array<Scalars['String']>;
  /** Collection of the requested object type */
  nodes: Array<AssessmentResult>;
  /** Indication that all underyling requests had no errors */
  success: Scalars['Boolean'];
};

export const AssessmentType = {
  Adl: 'ADL',
  Dcr: 'DCR',
  DcrPlus: 'DCR_PLUS',
  Default: 'DEFAULT',
  Iadl: 'IADL'
} as const;

export type AssessmentType = typeof AssessmentType[keyof typeof AssessmentType];
export type Assignment = Node & {
  __typename?: 'Assignment';
  assignedTime?: Maybe<Scalars['DateTime']>;
  assigner?: Maybe<User>;
  assignmentStatus: AssignmentStatus;
  battery: Battery;
  id: Scalars['ID'];
  interpretingUser: User;
  organizationId?: Maybe<Scalars['ID']>;
  remoteLink?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AssignmentConnection = Connection & {
  __typename?: 'AssignmentConnection';
  connectionInfo?: Maybe<ConnectionInfo>;
  errors: Array<Scalars['String']>;
  nodes: Array<Assignment>;
  success: Scalars['Boolean'];
};

export type AssignmentIssueInput = {
  /** The assignment associated with this issue */
  assignmentId: Scalars['ID'];
  /** A flag to indicate which type of issue we are recording */
  issueContext: IssueContext;
  /** note is optional and its existence indicates selection 'other' */
  note?: InputMaybe<Scalars['String']>;
  /** An array of selections that indicate which issue(s) occurred */
  options: Array<IssueType>;
};

export type AssignmentResponse = {
  __typename?: 'AssignmentResponse';
  assignment?: Maybe<Assignment>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export const AssignmentStatus = {
  AbortedDeclinedConsent: 'ABORTED_DECLINED_CONSENT',
  AbortedEndedSession: 'ABORTED_ENDED_SESSION',
  AbortedTimedOut: 'ABORTED_TIMED_OUT',
  Assigned: 'ASSIGNED',
  Complete: 'COMPLETE',
  Deleted: 'DELETED',
  Started: 'STARTED'
} as const;

export type AssignmentStatus = typeof AssignmentStatus[keyof typeof AssignmentStatus];
export const AssignmentType = {
  Mobile: 'MOBILE',
  Web: 'WEB'
} as const;

export type AssignmentType = typeof AssignmentType[keyof typeof AssignmentType];
export type Avatar = {
  __typename?: 'Avatar';
  defaultUrl?: Maybe<Scalars['String']>;
};

/**
 * A Battery is a collection of one or more assessments that are assigned to a patient/participant.
 * For further clarification on Battery vs Assessment vs Segment, refer to: https://linushealth.atlassian.net/wiki/spaces/EN/pages/571441153/Battery+vs+Assessment+vs+Segment
 */
export type Battery = Node & {
  __typename?: 'Battery';
  /** The collection of assessments that are in the Battery */
  assessments: Array<Assessment>;
  /** The type of battery */
  batteryType: BatteryType;
  /** Translation key used to get a string for display purposes */
  displayKey: Scalars['String'];
  /** The time, in minutes, that we expect the battery to take */
  estimatedDurationMins?: Maybe<Scalars['Int']>;
  /** ID of the battery */
  id: Scalars['ID'];
  /** Indication that there will or will not be metrics coming back from this battery */
  isExpectingMetrics: Scalars['Boolean'];
  /** Can the battery be run on mobile platforms */
  mobileEnabled?: Maybe<Scalars['Boolean']>;
  /** Name of the battery */
  name: Scalars['String'];
  /** Can the battery be run on web platforms */
  webEnabled?: Maybe<Scalars['Boolean']>;
};

/** Response data for battery query when passing in a connection */
export type BatteryConnection = Connection & {
  __typename?: 'BatteryConnection';
  /** Contains the initial data from the connection input as well as page count and total count */
  connectionInfo?: Maybe<ConnectionInfo>;
  /** Any underlying errors while retrieving data */
  errors: Array<Scalars['String']>;
  /** Collection of the requested object type */
  nodes: Array<Battery>;
  /** Indication that all underyling requests had no errors */
  success: Scalars['Boolean'];
};

/** Input to register report references */
export type BatteryReportInput = {
  /** The battery result that generated this report */
  batteryResultId: Scalars['ID'];
  /** The output location of the report if successful */
  dataURL?: InputMaybe<Scalars['String']>;
  /** The type of error that was produced on failure */
  errorStatus?: InputMaybe<ErrorStatus>;
  /** The version of the lambda that created this report */
  generatorVersion: Scalars['String'];
  /** The language of report that produced this error */
  language: Scalars['String'];
  /** The type of report that was produced */
  reportType: ReportType;
  /** The integer version of the document */
  revision: Scalars['Int'];
};

/** Once a battery is started, the results are stored in the battery result object */
export type BatteryResult = Node & {
  __typename?: 'BatteryResult';
  /** A collection of assessment result data related to this battery result */
  assessmentResults: AssessmentResultConnection;
  /** The assignment that started the battery */
  assignment?: Maybe<Assignment>;
  /**
   * The battery the results are based on
   * @deprecated Please refer to batteryId field to call our REST Services to fetch battery definitions
   */
  battery: Battery;
  /** The id of the battery type for this result */
  batteryId: Scalars['ID'];
  /** Clinical Decision Support recommendations made based on the metrics of this battery result */
  cdsRecommendations: Array<CdsRecommendation>;
  /** The time when all assesssments are `ANALYSIS_COMPLETE` */
  endTime?: Maybe<Scalars['DateTime']>;
  /** ID of the battery result (GUID) */
  id: Scalars['ID'];
  /** The Issue object associated with this battery result if it exists */
  issue?: Maybe<Issue>;
  /** A collection of metrics that are specific to the battery generated after all child segment results are `ANALYSIS_COMPELTE` */
  metricItems: MetricItemConnection;
  /** User participating in the battery */
  participant: Participant;
  /** V2 of a user participating in the battery */
  participantV2?: Maybe<ParticipantV2>;
  /** User overseeing the battery */
  proctor?: Maybe<User>;
  /** The url pointing to the data uploaded from mobile within AWS S3 bucket */
  rawDataUrl: Scalars['String'];
  /** The time when the first segment of the battery has started */
  startTime: Scalars['DateTime'];
  /** Current battery result status */
  status: BatteryResultStatus;
};


/** Once a battery is started, the results are stored in the battery result object */
export type BatteryResultAssessmentResultsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};


/** Once a battery is started, the results are stored in the battery result object */
export type BatteryResultMetricItemsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};

/** Response data for battery results query when passing in a connection */
export type BatteryResultConnection = Connection & {
  __typename?: 'BatteryResultConnection';
  /** Contains the initial data from the connection input as well as page count and total count */
  connectionInfo?: Maybe<ConnectionInfo>;
  /** Any underlying errors while retrieving data */
  errors: Array<Scalars['String']>;
  /** Collection of the requested object type */
  nodes: Array<BatteryResult>;
  /** Indication that all underyling requests had no errors */
  success: Scalars['Boolean'];
};

export const BatteryResultStatus = {
  AbortedTimedOut: 'ABORTED_TIMED_OUT',
  AnalysisComplete: 'ANALYSIS_COMPLETE',
  DataComplete: 'DATA_COMPLETE',
  Incomplete: 'INCOMPLETE',
  MetricsComplete: 'METRICS_COMPLETE',
  ParticipantComplete: 'PARTICIPANT_COMPLETE',
  PendingShortPhrases: 'PENDING_SHORT_PHRASES',
  ProcessingError: 'PROCESSING_ERROR'
} as const;

export type BatteryResultStatus = typeof BatteryResultStatus[keyof typeof BatteryResultStatus];
export type BatteryResultUploadUrlInput = {
  /** An array of battery result ids for which we want to generate urls. */
  uploadRequests: Array<BatteryUrlRequest>;
};

export const BatteryType = {
  Adl: 'ADL',
  Bds: 'BDS',
  CcePlus: 'CCE_PLUS',
  CognitiveEvaluation: 'COGNITIVE_EVALUATION',
  CommonSpirit: 'COMMON_SPIRIT',
  CommonSpiritDementia: 'COMMON_SPIRIT_DEMENTIA',
  CustomBattery: 'CUSTOM_BATTERY',
  Dcr: 'DCR',
  DcrPlus: 'DCR_PLUS',
  DcrTrails: 'DCR_TRAILS',
  Default: 'DEFAULT',
  Emory: 'EMORY',
  Fast: 'FAST',
  Gad7: 'GAD7',
  Gds: 'GDS',
  HearingScreener: 'HEARING_SCREENER',
  Iadl: 'IADL',
  IadlFast: 'IADL_FAST',
  Iuh: 'IUH',
  Lhq: 'LHQ',
  Orientation: 'ORIENTATION',
  Phonemic: 'PHONEMIC',
  Pvlt: 'PVLT',
  Semantic: 'SEMANTIC',
  Trails: 'TRAILS'
} as const;

export type BatteryType = typeof BatteryType[keyof typeof BatteryType];
export type BatteryUrlRequest = {
  /** The battery result to upload. */
  batteryResultId: Scalars['ID'];
  /** The file name to create at the upload location */
  file: ExpectedFiles;
};

export type CdsRecommendation = {
  __typename?: 'CDSRecommendation';
  /** The version of the CDS Rules engine that generated this recommendation */
  algorithmVersion: Scalars['String'];
  /** The content id for this recommendation */
  contentId: Scalars['String'];
  /** The version of the Clinical Decision Support content */
  contentVersion?: Maybe<Scalars['String']>;
};

export type CdsRun = {
  __typename?: 'CdsRun';
  algorithmVersion: Scalars['String'];
  batteryResultId: Scalars['ID'];
  concerns: Array<ConcernType>;
  id: Scalars['ID'];
  pathway?: Maybe<PathwayType>;
};

/** The possible search fields */
export const ClinicalPatientTableSearchField = {
  AssignmentStatus: 'ASSIGNMENT_STATUS',
  PatientFullName: 'PATIENT_FULL_NAME',
  PatientFullNameAndExternalId: 'PATIENT_FULL_NAME_AND_EXTERNAL_ID'
} as const;

export type ClinicalPatientTableSearchField = typeof ClinicalPatientTableSearchField[keyof typeof ClinicalPatientTableSearchField];
export type ClinicalPatientTableSearchInput = {
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /** The search parameters, optional */
  search?: InputMaybe<ClinicalPatientTableSearchParams>;
  /** Search parameters, optional */
  searches?: InputMaybe<Array<ClinicalPatientTableSearchParams>>;
  /** The sort parameters, optional */
  sort?: InputMaybe<ClinicalPatientTableSortParams>;
  /** Status of the patients search */
  status?: InputMaybe<Array<Status>>;
};

export type ClinicalPatientTableSearchInputObject = {
  __typename?: 'ClinicalPatientTableSearchInputObject';
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /**
   * The search parameters, optional, deprecated
   * @deprecated use `searches`
   */
  search?: Maybe<ClinicalPatientTableSearchParamsObject>;
  /** The search parameters, optional */
  searches?: Maybe<Array<ClinicalPatientTableSearchParamsObject>>;
  /** The sort parameters, optional */
  sort?: Maybe<ClinicalPatientTableSortParamsObject>;
};

export type ClinicalPatientTableSearchParams = {
  /** one of the enumerated search fields */
  prop: ClinicalPatientTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

export type ClinicalPatientTableSearchParamsObject = {
  __typename?: 'ClinicalPatientTableSearchParamsObject';
  /** one of the enumerated search fields */
  prop: ClinicalPatientTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

export type ClinicalPatientTableSearchResponse = {
  __typename?: 'ClinicalPatientTableSearchResponse';
  /** the number of patients returned from the query, should never exceed limit */
  count: Scalars['Int'];
  /** Array of errors encountered */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The result of the search query */
  patients: Array<ClinicalPatientTableSearchResult>;
  /** The input parameters that created this result */
  requestParams: ClinicalPatientTableSearchInputObject;
  /** Success Flag */
  success: Scalars['Boolean'];
  /** the total number of patients that satisfy the query */
  total: Scalars['Int'];
};

/** A limited entity to resolve needed data for tables */
export type ClinicalPatientTableSearchResult = {
  __typename?: 'ClinicalPatientTableSearchResult';
  assignedTime?: Maybe<Scalars['DateTime']>;
  assignmentId?: Maybe<Scalars['ID']>;
  assignmentStatus?: Maybe<AssignmentStatus>;
  batteryDisplayKey?: Maybe<Scalars['String']>;
  batteryId?: Maybe<Scalars['ID']>;
  lastCompletedBatteryEndTime?: Maybe<Scalars['DateTime']>;
  patientAvatarUrl?: Maybe<Scalars['String']>;
  patientBirthDate?: Maybe<Scalars['DateTime']>;
  patientExternalId?: Maybe<Scalars['ID']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientId: Scalars['ID'];
  patientLastName?: Maybe<Scalars['String']>;
};

/** The possible sort fields */
export const ClinicalPatientTableSortField = {
  AssignedTime: 'ASSIGNED_TIME',
  BatteryStatus: 'BATTERY_STATUS',
  DateOfBirth: 'DATE_OF_BIRTH',
  LastCompletedBatteryTime: 'LAST_COMPLETED_BATTERY_TIME',
  PatientFullName: 'PATIENT_FULL_NAME'
} as const;

export type ClinicalPatientTableSortField = typeof ClinicalPatientTableSortField[keyof typeof ClinicalPatientTableSortField];
export type ClinicalPatientTableSortParams = {
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: ClinicalPatientTableSortField;
};

export type ClinicalPatientTableSortParamsObject = {
  __typename?: 'ClinicalPatientTableSortParamsObject';
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: ClinicalPatientTableSortField;
};

/** The possible search fields for clinical */
export const ClinicalResultTableSearchField = {
  BatteryResultStatus: 'BATTERY_RESULT_STATUS',
  ParticipantExternalIdAndFullName: 'PARTICIPANT_EXTERNAL_ID_AND_FULL_NAME',
  ParticipantFullName: 'PARTICIPANT_FULL_NAME'
} as const;

export type ClinicalResultTableSearchField = typeof ClinicalResultTableSearchField[keyof typeof ClinicalResultTableSearchField];
export type ClinicalResultTableSearchInput = {
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /** The search parameters, optional */
  search?: InputMaybe<ClinicalResultTableSearchParams>;
  /** Search parameters, optional */
  searches?: InputMaybe<Array<ClinicalResultTableSearchParams>>;
  /** The sort parameters, optional */
  sort?: InputMaybe<ClinicalResultTableSortParams>;
};

export type ClinicalResultTableSearchInputObject = {
  __typename?: 'ClinicalResultTableSearchInputObject';
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /**
   * The search parameters, optional, deprecated
   * @deprecated use `searches`
   */
  search?: Maybe<ClinicalResultTableSearchParamsObject>;
  /** Search parameters, optional */
  searches?: Maybe<Array<ClinicalResultTableSearchParamsObject>>;
  /** The sort parameters, optional */
  sort?: Maybe<ClinicalResultTableSortParamsObject>;
};

export type ClinicalResultTableSearchParams = {
  /** one of the enumerated search fields */
  prop: ClinicalResultTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

export type ClinicalResultTableSearchParamsObject = {
  __typename?: 'ClinicalResultTableSearchParamsObject';
  /** one of the enumerated search fields */
  prop: ClinicalResultTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

/** The possible sort fields for clinical */
export const ClinicalResultTableSortField = {
  BatteryResultEndTime: 'BATTERY_RESULT_END_TIME',
  ParticipantLastName: 'PARTICIPANT_LAST_NAME',
  ProctorFirstName: 'PROCTOR_FIRST_NAME',
  ProctorLastName: 'PROCTOR_LAST_NAME'
} as const;

export type ClinicalResultTableSortField = typeof ClinicalResultTableSortField[keyof typeof ClinicalResultTableSortField];
export type ClinicalResultTableSortParams = {
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: ClinicalResultTableSortField;
};

export type ClinicalResultTableSortParamsObject = {
  __typename?: 'ClinicalResultTableSortParamsObject';
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: ClinicalResultTableSortField;
};

export type ClinicalResultsTableSearchResponse = {
  __typename?: 'ClinicalResultsTableSearchResponse';
  /** The result of the search query */
  batteryResults: Array<ClinicalResultsTableSearchResult>;
  /** the number of battery results returned from the query, should never exceed limit */
  count: Scalars['Int'];
  /** Array of errors encountered */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The input parameters that created this result */
  requestParams: ClinicalResultTableSearchInputObject;
  /** Success Flag */
  success: Scalars['Boolean'];
  /** the total number of battery results that satisfy the query */
  total: Scalars['Int'];
};

/** A limited entity to resolve needed data for tables */
export type ClinicalResultsTableSearchResult = {
  __typename?: 'ClinicalResultsTableSearchResult';
  batteryDcrScore?: Maybe<Scalars['Int']>;
  batteryDisplayKey?: Maybe<Scalars['String']>;
  batteryId: Scalars['ID'];
  batteryResultEndTime?: Maybe<Scalars['DateTime']>;
  batteryResultHasConcerns?: Maybe<Scalars['Boolean']>;
  batteryResultHasProcessingError?: Maybe<Scalars['Boolean']>;
  batteryResultId: Scalars['ID'];
  batteryResultStartTime?: Maybe<Scalars['DateTime']>;
  batteryResultStatus?: Maybe<BatteryResultStatus>;
  batteryTrailsColor?: Maybe<Scalars['String']>;
  batteryTrailsDuration?: Maybe<Scalars['Float']>;
  batteryType?: Maybe<BatteryType>;
  participantExternalId?: Maybe<Scalars['ID']>;
  participantFirstName?: Maybe<Scalars['String']>;
  participantId: Scalars['ID'];
  participantLastName?: Maybe<Scalars['String']>;
  proctorFirstName?: Maybe<Scalars['String']>;
  proctorId?: Maybe<Scalars['ID']>;
  proctorLastName?: Maybe<Scalars['String']>;
};

export const ConcernType = {
  Cholinergic: 'CHOLINERGIC',
  Execmixed: 'EXECMIXED',
  Execvascular: 'EXECVASCULAR',
  Memory: 'MEMORY',
  MemorymixedCond1: 'MEMORYMIXED_COND1',
  MemorymixedCond2: 'MEMORYMIXED_COND2',
  Mixed: 'MIXED',
  Parkinsonism: 'PARKINSONISM',
  Tremor: 'TREMOR'
} as const;

export type ConcernType = typeof ConcernType[keyof typeof ConcernType];
/**
 * An interface that should be implemented by all connection results.
 * It enforces that the result of a collection contains both a connectionInfo
 * object as well as the nodes of the collection. It also provides the success/errors for the query
 */
export type Connection = {
  connectionInfo?: Maybe<ConnectionInfo>;
  errors: Array<Scalars['String']>;
  nodes: Array<Node>;
  success: Scalars['Boolean'];
};

export type ConnectionInfo = {
  __typename?: 'ConnectionInfo';
  filter?: Maybe<Filter>;
  pageCount?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Sort>>;
  take?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ConnectionInput = {
  filter?: InputMaybe<FilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<SortInput>>;
  take?: InputMaybe<Scalars['Int']>;
};

export type CreateAssessmentResultData = {
  __typename?: 'CreateAssessmentResultData';
  assessmentType: Scalars['String'];
  id: Scalars['ID'];
  segmentResults: Array<CreateSegmentResultData>;
};

export type CreateAssignmentInput = {
  assignedTime?: InputMaybe<Scalars['DateTime']>;
  batteryId: Scalars['ID'];
  interpretingUserId?: InputMaybe<Scalars['ID']>;
  participantId: Scalars['ID'];
  remote?: InputMaybe<Scalars['Boolean']>;
};

/** The information needed to create a new organization */
export type CreateNewOrganizationInput = {
  /** List of batteries that the organization will have access to. */
  batteries?: InputMaybe<Array<Scalars['ID']>>;
  /** List of CDS v2 concerns configuration */
  cdsConcernConfigurations?: InputMaybe<Array<ConcernType>>;
  /** The format of the date shown for anything that relates to this organization */
  dateFormat: Scalars['String'];
  /** The default timezone of the organization */
  defaultTimezone?: InputMaybe<Scalars['String']>;
  /** The default user language for the webpage */
  defaultUserLocale: Locale;
  /** A description of the organization */
  description?: InputMaybe<Scalars['String']>;
  /** A list of features an organization may have access to */
  features?: InputMaybe<Array<InputMaybe<FeatureType>>>;
  /** The name of the new organization */
  name: Scalars['String'];
  /** The different languages that a test can be taken in. */
  participantLanguages: Array<Scalars['ID']>;
  /** Sets of words which segments can use to conduct recall tests. */
  recallWordSets?: InputMaybe<Array<Scalars['ID']>>;
  /** The usability status for the organization */
  status: OrganizationStatus;
  /** The purpose for creating the organization */
  type: OrganizationType;
  /** The lists of other values that are needed such as Ethnicity, Race, etc. for this organization */
  valueLists: Array<Scalars['ID']>;
};

export type CreateParticipantInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  birthYear?: InputMaybe<Scalars['Int']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactPreference?: InputMaybe<Scalars['String']>;
  educationId?: InputMaybe<Scalars['ID']>;
  ethnicityIds?: InputMaybe<Array<Scalars['ID']>>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  handedness?: InputMaybe<Handedness>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  raceId?: InputMaybe<Scalars['ID']>;
  raceIds?: InputMaybe<Array<Scalars['ID']>>;
  sexAssignedAtBirth?: InputMaybe<SexAssignedAtBirth>;
};

export type CreateSegmentResultData = {
  __typename?: 'CreateSegmentResultData';
  id: Scalars['ID'];
  rawDataUrl: Scalars['String'];
  segmentType: Scalars['String'];
};

export type CreateSubjectStatusInput = {
  status: Status;
  subjectId: Scalars['String'];
};

export type Criteria = {
  __typename?: 'Criteria';
  key: Scalars['String'];
  op: CriteriaOperation;
  value: Scalars['String'];
};

export type CriteriaInput = {
  key: Scalars['String'];
  op: CriteriaOperation;
  value: Scalars['String'];
};

export const CriteriaOperation = {
  Equals: 'equals',
  GreaterThan: 'greaterThan',
  GreaterThanOrEqual: 'greaterThanOrEqual',
  In: 'in',
  LessThan: 'lessThan',
  LessThanOrEqual: 'lessThanOrEqual',
  Like: 'like'
} as const;

export type CriteriaOperation = typeof CriteriaOperation[keyof typeof CriteriaOperation];
export type CurrentUser = Node & {
  __typename?: 'CurrentUser';
  /** Location of the current users avatar in S3 bucket. */
  avatarUrl: Scalars['String'];
  /** Email address of current user. */
  email: Scalars['String'];
  /** First name of current user. */
  firstName: Scalars['String'];
  /** ID of current user (GUID). */
  id: Scalars['ID'];
  /** Last name of current user. */
  lastName: Scalars['String'];
  /** Operations that the current user is allowed. */
  operations: Array<OperationToken>;
  /** The id of the organization that the current user belongs to. */
  organizationId: Scalars['ID'];
  /** The name of the organization that the current user belongs to. */
  organizationName: Scalars['String'];
  /** The type of organization the current user belongs to (Linus or Clinical). */
  organizationType: OrganizationType;
  /** Phone number of current user. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The display key for the role of the current user */
  role: Scalars['String'];
  /** Id of the role assigned to the current user. */
  roleId: Scalars['ID'];
  /** The raw role name of the current user, eg: Clinical admin, dev, etc. */
  roleName: Scalars['String'];
  /** All the roles for the current user */
  roles: Array<OrganizationRole>;
  /** The suffix describing the current user, can be nothing, eg: MD, RN, etc */
  suffix: Array<ValueListItem>;
  /** The status of the current user (INVITED, ACTIVE, DEACTIVATED, etc) */
  userStatus: UserStatus;
};

export type CurrentUserProvider = {
  user?: Maybe<CurrentUser>;
};

/** The data response from the current user query to get information of the current user. */
export type CurrentUserResponse = {
  __typename?: 'CurrentUserResponse';
  /** Data for the current user */
  currentUser?: Maybe<CurrentUser>;
  /** Any underlying errors while retrieving data. */
  errors: Array<Scalars['String']>;
  /** Indication that all underyling requests had no errors. */
  success: Scalars['Boolean'];
};

export type DeleteAssignmentInput = {
  id: Scalars['ID'];
};

export const ErrorStatus = {
  /** Error encountered generating a PDF report in post-processing */
  PdfLambdaError: 'PDF_LAMBDA_ERROR',
  /** The report exceeded the expected time in GENERATING */
  Timeout: 'TIMEOUT'
} as const;

export type ErrorStatus = typeof ErrorStatus[keyof typeof ErrorStatus];
export const ExpectedFiles = {
  Precheck: 'PRECHECK',
  UfSurvey: 'UF_SURVEY'
} as const;

export type ExpectedFiles = typeof ExpectedFiles[keyof typeof ExpectedFiles];
export type Feature = {
  __typename?: 'Feature';
  featureType?: Maybe<Scalars['String']>;
};

export const FeatureType = {
  ClinicalDecisionSupport: 'ClinicalDecisionSupport',
  ClinicalDecisionSupportV2: 'ClinicalDecisionSupportV2',
  ClockShortPhrases: 'ClockShortPhrases',
  DementiaRiskEstimateReports: 'DementiaRiskEstimateReports',
  EnableCarePartner: 'EnableCarePartner',
  EnableMyPatientList: 'EnableMyPatientList',
  FunctionalImpairmentProbability: 'FunctionalImpairmentProbability',
  GenerateProviderReportWithCds: 'GenerateProviderReportWithCds',
  IntegrationAthenaOrderAdapter: 'IntegrationAthenaOrderAdapter',
  IntegrationAthenaReportAdapter: 'IntegrationAthenaReportAdapter',
  IntegrationCernerReportAdapter: 'IntegrationCernerReportAdapter',
  IntegrationConcordFaxAdapter: 'IntegrationConcordFaxAdapter',
  IntegrationDocmanReportAdapter: 'IntegrationDocmanReportAdapter',
  IntegrationEhrSmartFhirAdapter: 'IntegrationEhrSmartFhirAdapter',
  IntegrationEpicReportAdapter: 'IntegrationEpicReportAdapter',
  IntegrationOracleReportAdapter: 'IntegrationOracleReportAdapter',
  IntegrationRedoxReportAdapter: 'IntegrationRedoxReportAdapter',
  IntegrationRedoxScheduleAdapter: 'IntegrationRedoxScheduleAdapter',
  IntegrationXealthOrderAdapter: 'IntegrationXealthOrderAdapter',
  LinusAmyloidLikelihood: 'LinusAmyloidLikelihood',
  MemoryImpairmentProbability: 'MemoryImpairmentProbability',
  MmseEstimation: 'MmseEstimation',
  NoteTemplate: 'NoteTemplate',
  PatientReportAndActionPlan: 'PatientReportAndActionPlan',
  ReasonsForTesting: 'ReasonsForTesting',
  RegisterAssignmentIssue: 'RegisterAssignmentIssue',
  RemainLoggedIn: 'RemainLoggedIn',
  RemoteAssessment: 'RemoteAssessment',
  ResearchReportAudioPlayers: 'ResearchReportAudioPlayers',
  RestartSegment: 'RestartSegment',
  ShortProviderReport: 'ShortProviderReport',
  TaAttestation: 'TAAttestation',
  UfSurvey: 'UFSurvey',
  VisitTypes: 'VisitTypes'
} as const;

export type FeatureType = typeof FeatureType[keyof typeof FeatureType];
export type Filter = {
  __typename?: 'Filter';
  AND?: Maybe<Array<Criteria>>;
  NOT?: Maybe<Array<Criteria>>;
};

export type FilterInput = {
  AND?: InputMaybe<Array<CriteriaInput>>;
  NOT?: InputMaybe<Array<CriteriaInput>>;
};

export type FinishSignupInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export const Gender = {
  Female: 'FEMALE',
  Male: 'MALE',
  NonBinary: 'NON_BINARY',
  NonDisclosed: 'NON_DISCLOSED',
  Other: 'OTHER',
  ToBeCollectedAtTimeOfTesting: 'TO_BE_COLLECTED_AT_TIME_OF_TESTING',
  TransgenderFemale: 'TRANSGENDER_FEMALE',
  TransgenderMale: 'TRANSGENDER_MALE'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];
export type GetUploadUrlResponse = {
  __typename?: 'GetUploadURLResponse';
  /** The array of presigned upload urls */
  urls: Array<UrlResult>;
};

export const Handedness = {
  Ambidextrous: 'AMBIDEXTROUS',
  LeftHanded: 'LEFT_HANDED',
  RightHanded: 'RIGHT_HANDED',
  ToBeCollectedAtTimeOfTesting: 'TO_BE_COLLECTED_AT_TIME_OF_TESTING'
} as const;

export type Handedness = typeof Handedness[keyof typeof Handedness];
export type InviteUserInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationId: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
  suffixIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Issue = {
  __typename?: 'Issue';
  /** note is optional and its existence indicates selection 'other' */
  note?: Maybe<Scalars['String']>;
  /** An array of selections that indicate which issue(s) occurred */
  options: Array<IssueType>;
  reporter: SlimUser;
};

export const IssueContext = {
  AssignmentRemoved: 'ASSIGNMENT_REMOVED',
  BatteryResult: 'BATTERY_RESULT'
} as const;

export type IssueContext = typeof IssueContext[keyof typeof IssueContext];
export type IssueResponse = {
  __typename?: 'IssueResponse';
  /** Array of error messages encountered */
  errors: Array<Scalars['String']>;
  /** The created issue */
  issue?: Maybe<Issue>;
  /** Success flag */
  success?: Maybe<Scalars['Boolean']>;
};

export const IssueType = {
  Declined: 'DECLINED',
  Device: 'DEVICE',
  Fatigue: 'FATIGUE',
  Hearing: 'HEARING',
  Interrupt: 'INTERRUPT',
  Motor: 'MOTOR',
  NoResponse: 'NO_RESPONSE',
  PrimaryLang: 'PRIMARY_LANG',
  TechStruggle: 'TECH_STRUGGLE',
  Vision: 'VISION'
} as const;

export type IssueType = typeof IssueType[keyof typeof IssueType];
export const Locale = {
  EnGb: 'EN_GB',
  EnUs: 'EN_US',
  EsMx: 'ES_MX'
} as const;

export type Locale = typeof Locale[keyof typeof Locale];
/**
 * Describes the granular data captured during an assessment or as a result of the analysis engine.
 * This data is used to measure how well a participant did on an assessment.
 */
export type MetricItem = Node & {
  __typename?: 'MetricItem';
  /** Version of algorithm */
  algorithmVersion: Scalars['String'];
  /** ID of the metric item (GUID) */
  id: Scalars['ID'];
  /** Metric item key (e.g. dcr_score) */
  key: Scalars['String'];
  /** Measurement for metric item key */
  value: Scalars['String'];
};

/** Response data for metric items query when passing in a connection */
export type MetricItemConnection = Connection & {
  __typename?: 'MetricItemConnection';
  /** Contains the initial data from the connection input as well as page count and total count */
  connectionInfo?: Maybe<ConnectionInfo>;
  /** Any underlying errors while retrieving data */
  errors: Array<Scalars['String']>;
  /** Collection of the requested object type */
  nodes: Array<MetricItem>;
  /** Indication that all underyling requests had no errors */
  success: Scalars['Boolean'];
};

export type MetricItemResponse = {
  __typename?: 'MetricItemResponse';
  data: Array<MetricItem>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type MetricsEntry = {
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Update user profile mutation */
export type Mutation = {
  __typename?: 'Mutation';
  activateUser: UserResponse;
  /** Saves recommendations and ties them to the battery result used to generate them */
  addCDSRecommendationsToBatteryResult: Response;
  addMetricsToAssessmentResult: MetricItemResponse;
  addMetricsToBatteryResult: MetricItemResponse;
  addMetricsToSegmentResult: MetricItemResponse;
  createAssignment: AssignmentResponse;
  /**
   * The createNewOrganization mutation creates a new organization record in the database
   * and links it to the information passed in the createNewOrganizationInput object
   */
  createNewOrganization: OrganizationResponse;
  createParticipant: ParticipantResponse;
  deactivateUser: UserResponse;
  deleteAssignment: Response;
  finishSignup: CurrentUserResponse;
  forgotPassword: Success;
  forgotPasswordSubmit: Success;
  generateBatteryResultUploadURL: GetUploadUrlResponse;
  generateSegmentResultUploadURL: GetUploadUrlResponse;
  inviteNewUser: UserResponse;
  placeholderMutation: PlaceholderMutationResponse;
  /** Creates a record for report statuses */
  recordBatteryResultReport: Response;
  /**
   * Mutation which creates a new record of the CDS V2 run
   * by the Concerns Engine based on the battery results
   */
  recordCdsConcerns: Response;
  /**
   * Mutation which creates a new record of the CDS V2 run
   * by the Pathway Engine based on the concerns list
   */
  recordCdsPathway: Response;
  refreshUserData: Success;
  /** Sets `hasProcessingError` to true on given assessment result */
  registerAssessmentResultError: Success;
  registerAssignmentIssue: IssueResponse;
  /**
   * Used by our serverless functions (AWS Lambda) when mobile uploads a zip file to the AWS S3 bucket.
   * Processes segment result and sets `hasReceivedResults` to true for that result,
   * as well as updates the battery result with new status when the data is complete.
   */
  registerSegmentResultData: Success;
  /** Sets `hasProcessingError` to true on given segment result */
  registerSegmentResultError: Success;
  reinviteUser: Success;
  startAssignment: StartedAssignmentResponse;
  /**
   * The updateOrganization mutation updates the organization record in the database
   * by using the information passed in the updateOrganizationInput object.
   */
  updateOrganization: OrganizationResponse;
  updateParticipant: ParticipantResponse;
  /**
   * Update primary organization takes in an UpdatePrimaryOrganizationInput to allow a user to update their default organization.
   * Will return a UserResponse, which includes the new updated data as well as meta data on the success and errors of the mutation.
   */
  updatePrimaryOrganization: UserResponse;
  /**
   * Update profile takes in an UpdateProfileInput that is the given new values of the user to update into the database.
   * Will return a UserResponse, which includes the new updated data as well as meta data on the success and errors of the mutation.
   */
  updateProfile: UserResponse;
  updateSegmentResult: SegmentResultsResponse;
  updateSubjectStatus: SubjectStatusResponse;
  updateUser: UserResponse;
  zendeskRedirectUrl: ZendeskRedirectUrlResponse;
};


/** Update user profile mutation */
export type MutationActivateUserArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};


/** Update user profile mutation */
export type MutationAddCdsRecommendationsToBatteryResultArgs = {
  addCDSRecommendationsToBatteryResultInput: AddCdsRecommendationsToBatteryResultInput;
};


/** Update user profile mutation */
export type MutationAddMetricsToAssessmentResultArgs = {
  metricsData: AddResultMetricsInput;
};


/** Update user profile mutation */
export type MutationAddMetricsToBatteryResultArgs = {
  metricsData: AddResultMetricsInput;
};


/** Update user profile mutation */
export type MutationAddMetricsToSegmentResultArgs = {
  metricsData: AddResultMetricsInput;
};


/** Update user profile mutation */
export type MutationCreateAssignmentArgs = {
  CreateAssignmentInput: CreateAssignmentInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationCreateNewOrganizationArgs = {
  createNewOrganizationInput: CreateNewOrganizationInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationCreateParticipantArgs = {
  CreateParticipantInput: CreateParticipantInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationDeactivateUserArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};


/** Update user profile mutation */
export type MutationDeleteAssignmentArgs = {
  DeleteAssignmentInput: DeleteAssignmentInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationFinishSignupArgs = {
  FinishSignupInput: FinishSignupInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationForgotPasswordArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};


/** Update user profile mutation */
export type MutationForgotPasswordSubmitArgs = {
  SubmitForgotPasswordInput: SubmitForgotPasswordInput;
};


/** Update user profile mutation */
export type MutationGenerateBatteryResultUploadUrlArgs = {
  generateUploadURLInput: BatteryResultUploadUrlInput;
};


/** Update user profile mutation */
export type MutationGenerateSegmentResultUploadUrlArgs = {
  generateUploadURLInput: SegmentResultUploadUrlInput;
};


/** Update user profile mutation */
export type MutationInviteNewUserArgs = {
  InviteUserInput: InviteUserInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationPlaceholderMutationArgs = {
  params: Params;
};


/** Update user profile mutation */
export type MutationRecordBatteryResultReportArgs = {
  reportInput: BatteryReportInput;
};


/** Update user profile mutation */
export type MutationRecordCdsConcernsArgs = {
  recordCdsConcernsInput: RecordCdsConcernsInput;
};


/** Update user profile mutation */
export type MutationRecordCdsPathwayArgs = {
  recordCdsPathwayInput: RecordCdsPathwayInput;
};


/** Update user profile mutation */
export type MutationRefreshUserDataArgs = {
  userManger: Scalars['String'];
};


/** Update user profile mutation */
export type MutationRegisterAssessmentResultErrorArgs = {
  assessmentResultId: Scalars['ID'];
};


/** Update user profile mutation */
export type MutationRegisterAssignmentIssueArgs = {
  issueInput: AssignmentIssueInput;
};


/** Update user profile mutation */
export type MutationRegisterSegmentResultDataArgs = {
  registerSegmentResultDataInput: RegisterSegmentResultDataInput;
};


/** Update user profile mutation */
export type MutationRegisterSegmentResultErrorArgs = {
  segmentResultId: Scalars['ID'];
};


/** Update user profile mutation */
export type MutationReinviteUserArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
  reInviteUserInput: ReInviteUserInput;
};


/** Update user profile mutation */
export type MutationStartAssignmentArgs = {
  StartAssignmentInput: StartAssignmentInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationUpdateOrganizationArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
  updateOrganizationInput: UpdateOrganizationInput;
};


/** Update user profile mutation */
export type MutationUpdateParticipantArgs = {
  UpdateParticipantInput: UpdateParticipantInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationUpdatePrimaryOrganizationArgs = {
  UpdatePrimaryOrganizationInput: UpdatePrimaryOrganizationInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationUpdateProfileArgs = {
  UpdateProfileInput: UpdateProfileInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationUpdateSegmentResultArgs = {
  UpdateSegmentResultInput: UpdateSegmentResultInput;
};


/** Update user profile mutation */
export type MutationUpdateSubjectStatusArgs = {
  CreateSubjectStatusInput: CreateSubjectStatusInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationUpdateUserArgs = {
  UpdateUserInput: UpdateUserInput;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Update user profile mutation */
export type MutationZendeskRedirectUrlArgs = {
  zendeskRedirectUrlInput?: InputMaybe<ZendeskRedirectUrlInput>;
};

/** An interface that should be implemented by all Entities. i.e. all objects with an Id */
export type Node = {
  id: Scalars['ID'];
};

/** An object which contains the name of the operation a role is permitted to make */
export type Operation = {
  __typename?: 'Operation';
  /** Actual operation token used to permit actions */
  name: OperationToken;
};

export const OperationToken = {
  AdminDeleteUser: 'ADMIN_DELETE_USER',
  AssignBattery: 'ASSIGN_BATTERY',
  CanAccessMobileApp: 'CAN_ACCESS_MOBILE_APP',
  ContactSupport: 'CONTACT_SUPPORT',
  CreateAssessmentResult: 'CREATE_ASSESSMENT_RESULT',
  CreateAssessmentResultMetrics: 'CREATE_ASSESSMENT_RESULT_METRICS',
  CreateBatteryResult: 'CREATE_BATTERY_RESULT',
  CreateBatteryResultMetrics: 'CREATE_BATTERY_RESULT_METRICS',
  CreateBatteryResultReportRecord: 'CREATE_BATTERY_RESULT_REPORT_RECORD',
  CreateCdsRecommendations: 'CREATE_CDS_RECOMMENDATIONS',
  CreateIntegrationConfiguration: 'CREATE_INTEGRATION_CONFIGURATION',
  CreateOrganization: 'CREATE_ORGANIZATION',
  CreateParticipant: 'CREATE_PARTICIPANT',
  CreateReport: 'CREATE_REPORT',
  CreateSchedule: 'CREATE_SCHEDULE',
  CreateSegmentResult: 'CREATE_SEGMENT_RESULT',
  CreateSegmentResultMetrics: 'CREATE_SEGMENT_RESULT_METRICS',
  CreateVisitTypes: 'CREATE_VISIT_TYPES',
  DeleteOrganization: 'DELETE_ORGANIZATION',
  DeleteParticipant: 'DELETE_PARTICIPANT',
  DeleteSchedule: 'DELETE_SCHEDULE',
  DeleteUser: 'DELETE_USER',
  EditAllOrganizations: 'EDIT_ALL_ORGANIZATIONS',
  EditAllParticipants: 'EDIT_ALL_PARTICIPANTS',
  EditLinusUsers: 'EDIT_LINUS_USERS',
  EditLinusUserRoles: 'EDIT_LINUS_USER_ROLES',
  EditOrganization: 'EDIT_ORGANIZATION',
  EditOwnUserRole: 'EDIT_OWN_USER_ROLE',
  EditParticipant: 'EDIT_PARTICIPANT',
  EditProductUsers: 'EDIT_PRODUCT_USERS',
  EditProductUserRoles: 'EDIT_PRODUCT_USER_ROLES',
  EditSchedule: 'EDIT_SCHEDULE',
  EditSelf: 'EDIT_SELF',
  EditUser: 'EDIT_USER',
  EditUsers: 'EDIT_USERS',
  GenerateBatteryResultPresignedUrl: 'GENERATE_BATTERY_RESULT_PRESIGNED_URL',
  GenerateSegmentResultPresignedUrl: 'GENERATE_SEGMENT_RESULT_PRESIGNED_URL',
  GenerateToken: 'GENERATE_TOKEN',
  ImpersonateOrganization: 'IMPERSONATE_ORGANIZATION',
  IntegrationService: 'INTEGRATION_SERVICE',
  InviteLinusUser: 'INVITE_LINUS_USER',
  InviteProductUser: 'INVITE_PRODUCT_USER',
  InviteUser: 'INVITE_USER',
  RecordCdsConcerns: 'RECORD_CDS_CONCERNS',
  RecordCdsPathway: 'RECORD_CDS_PATHWAY',
  RedeemToken: 'REDEEM_TOKEN',
  RegisterAssignmentAttestation: 'REGISTER_ASSIGNMENT_ATTESTATION',
  RegisterAssignmentIssue: 'REGISTER_ASSIGNMENT_ISSUE',
  RegisterBatteryProcessingError: 'REGISTER_BATTERY_PROCESSING_ERROR',
  RegisterBatteryResultData: 'REGISTER_BATTERY_RESULT_DATA',
  ResetPassword: 'RESET_PASSWORD',
  UpdatePrimaryOrganization: 'UPDATE_PRIMARY_ORGANIZATION',
  ViewAbout: 'VIEW_ABOUT',
  ViewAllAssessments: 'VIEW_ALL_ASSESSMENTS',
  ViewAllAssessmentResults: 'VIEW_ALL_ASSESSMENT_RESULTS',
  ViewAllBatteries: 'VIEW_ALL_BATTERIES',
  ViewAllBatteryResults: 'VIEW_ALL_BATTERY_RESULTS',
  ViewAllBatteryResultsV2: 'VIEW_ALL_BATTERY_RESULTS_V2',
  ViewAllLinusUsers: 'VIEW_ALL_LINUS_USERS',
  ViewAllOrganizations: 'VIEW_ALL_ORGANIZATIONS',
  ViewAllParticipants: 'VIEW_ALL_PARTICIPANTS',
  ViewAllProductUsers: 'VIEW_ALL_PRODUCT_USERS',
  ViewAllResidents: 'VIEW_ALL_RESIDENTS',
  ViewAllSchedules: 'VIEW_ALL_SCHEDULES',
  ViewAllSegments: 'VIEW_ALL_SEGMENTS',
  ViewAllSegmentResults: 'VIEW_ALL_SEGMENT_RESULTS',
  ViewAllUsers: 'VIEW_ALL_USERS',
  ViewAssessments: 'VIEW_ASSESSMENTS',
  ViewBattery: 'VIEW_BATTERY',
  ViewCdsRecommendations: 'VIEW_CDS_RECOMMENDATIONS',
  ViewCdsRun: 'VIEW_CDS_RUN',
  ViewDashboard: 'VIEW_DASHBOARD',
  ViewIntegrationConfiguration: 'VIEW_INTEGRATION_CONFIGURATION',
  ViewIssue: 'VIEW_ISSUE',
  ViewLinusUser: 'VIEW_LINUS_USER',
  ViewOrganization: 'VIEW_ORGANIZATION',
  ViewParticipant: 'VIEW_PARTICIPANT',
  ViewProductUser: 'VIEW_PRODUCT_USER',
  ViewReport: 'VIEW_REPORT',
  ViewResident: 'VIEW_RESIDENT',
  ViewResults: 'VIEW_RESULTS',
  ViewS3MetricFiles: 'VIEW_S3_METRIC_FILES',
  ViewUser: 'VIEW_USER',
  ViewUserLogs: 'VIEW_USER_LOGS',
  ViewUserSubjects: 'VIEW_USER_SUBJECTS'
} as const;

export type OperationToken = typeof OperationToken[keyof typeof OperationToken];
export const OptionalFields = {
  Ethnicity: 'ETHNICITY',
  ExternalId: 'EXTERNAL_ID',
  Gender: 'GENDER',
  Notes: 'NOTES',
  Race: 'RACE'
} as const;

export type OptionalFields = typeof OptionalFields[keyof typeof OptionalFields];
/** An organization is the main entity which groups a study or client. */
export type Organization = Node & {
  __typename?: 'Organization';
  /** Query which returns a collection of batteries based on the settings passed in the connection object. */
  batteries: BatteryConnection;
  /** Query which returns the battery that contains the batteryId passed. */
  batteryById: Battery;
  /** Query which returns the battery result that contains the batteryResultId passed. */
  batteryResultById: BatteryResult;
  /** Query which returns a collection of battery results based on the settings passed in the connection object. */
  batteryResults: BatteryResultConnection;
  /** The ISO date and time when the organization record was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Description about the organization. */
  description?: Maybe<Scalars['String']>;
  /** A unique identifier for an organization (GUID). */
  id: Scalars['ID'];
  /** The displayed name of an organization. */
  name: Scalars['String'];
  /** Organization settings that include value lists */
  preferences: OrganizationPreferences;
  /** Query which returns a collection of roles based on the settings passed in the connection object. */
  roles: RoleConnection;
  /** The usability status of the organization (Live or Test). */
  status: OrganizationStatus;
  /** The assigned vertical and purpose of this organization. */
  type: OrganizationType;
  /** Query which retrieves a user that has the passed userId. */
  userById?: Maybe<User>;
  /** Query which retrieves a collection of users based on the settings passed in the connection object. */
  users: UserConnection;
};


/** An organization is the main entity which groups a study or client. */
export type OrganizationBatteriesArgs = {
  connection?: InputMaybe<ConnectionInput>;
};


/** An organization is the main entity which groups a study or client. */
export type OrganizationBatteryByIdArgs = {
  batteryId: Scalars['ID'];
};


/** An organization is the main entity which groups a study or client. */
export type OrganizationBatteryResultByIdArgs = {
  batteryResultId: Scalars['ID'];
};


/** An organization is the main entity which groups a study or client. */
export type OrganizationBatteryResultsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};


/** An organization is the main entity which groups a study or client. */
export type OrganizationRolesArgs = {
  connection?: InputMaybe<ConnectionInput>;
};


/** An organization is the main entity which groups a study or client. */
export type OrganizationUserByIdArgs = {
  userId: Scalars['ID'];
};


/** An organization is the main entity which groups a study or client. */
export type OrganizationUsersArgs = {
  connection?: InputMaybe<ConnectionInput>;
};

/** Response data for organizations query when passing in a connection */
export type OrganizationConnection = Connection & {
  __typename?: 'OrganizationConnection';
  /** Criteria to use when retrieving the collection of organizations from the database */
  connectionInfo?: Maybe<ConnectionInfo>;
  /** List of errors encountered while fetching the organization collection */
  errors: Array<Scalars['String']>;
  /** A collection of  organizations that satisfy the criteria in the connectionInfo settings. */
  nodes: Array<Organization>;
  /** Whether the call was successful or not */
  success: Scalars['Boolean'];
};

/** Organization settings that include value lists */
export type OrganizationPreferences = {
  __typename?: 'OrganizationPreferences';
  /** List of CDS v2 concerns configuration */
  cdsConcernConfigurations?: Maybe<Array<ConcernType>>;
  /** The format which the dates will be displayed in for the entities related to the organization */
  dateFormat: Scalars['String'];
  /** The default timezone of the organization */
  defaultTimezone?: Maybe<Scalars['String']>;
  /** The default language locale for the web page */
  defaultUserLocale: Locale;
  /** A list of features an organization may have access to */
  features: Array<Maybe<FeatureType>>;
  /** A list of permitted languages in which the batteries can be taken. */
  participantLanguages: Array<ValueListItem>;
  /** A list of word sets that batteries may include in their recall tests. */
  recallWordSets?: Maybe<Array<ValueListItem>>;
  /** A list of other values that may be needed such as Ethnicity, Race, etc. */
  valueLists: Array<ValueList>;
};

/** The response object when fetching a single organization */
export type OrganizationResponse = {
  __typename?: 'OrganizationResponse';
  /** List of errors encountered while fetching the organization */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The organization object with relevant information */
  organization?: Maybe<Organization>;
  /** Whether the call was successful or not */
  success: Scalars['Boolean'];
};

export type OrganizationRole = {
  __typename?: 'OrganizationRole';
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
  organizationType: OrganizationType;
  primary: Scalars['Boolean'];
  roleId: Scalars['ID'];
  roleName: Scalars['String'];
};

export const OrganizationStatus = {
  Live: 'LIVE',
  Test: 'TEST'
} as const;

export type OrganizationStatus = typeof OrganizationStatus[keyof typeof OrganizationStatus];
export const OrganizationType = {
  Clinical: 'CLINICAL',
  Linus: 'LINUS',
  Research: 'RESEARCH'
} as const;

export type OrganizationType = typeof OrganizationType[keyof typeof OrganizationType];
export type Params = {
  id: Scalars['ID'];
  param1: Scalars['String'];
  param2?: InputMaybe<Scalars['String']>;
  param3?: InputMaybe<TestEnum>;
};

export type ParamsObject = {
  __typename?: 'ParamsObject';
  id: Scalars['ID'];
  param1: Scalars['String'];
  param2?: Maybe<Scalars['String']>;
  param3?: Maybe<TestEnum>;
};

export type Participant = Node & {
  __typename?: 'Participant';
  age?: Maybe<Scalars['Int']>;
  assignments: AssignmentConnection;
  avatar?: Maybe<Avatar>;
  batteryResultById?: Maybe<BatteryResult>;
  batteryResults: BatteryResultConnection;
  birthDate: Scalars['DateTime'];
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactPreference?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  education: ValueListItem;
  ethnicity: Array<ValueListItem>;
  externalId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: Maybe<Gender>;
  handedness: Handedness;
  id: Scalars['ID'];
  language: Scalars['String'];
  lastName: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  /** @deprecated replaced by multi valued races field */
  race?: Maybe<ValueListItem>;
  races?: Maybe<Array<ValueListItem>>;
  sexAssignedAtBirth?: Maybe<SexAssignedAtBirth>;
  status?: Maybe<Status>;
};


export type ParticipantAssignmentsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};


export type ParticipantBatteryResultByIdArgs = {
  batteryResultId: Scalars['ID'];
};


export type ParticipantBatteryResultsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};

export type ParticipantConnection = Connection & {
  __typename?: 'ParticipantConnection';
  connectionInfo?: Maybe<ConnectionInfo>;
  errors: Array<Scalars['String']>;
  nodes: Array<Participant>;
  success: Scalars['Boolean'];
};

export type ParticipantPropertiesInputInput = {
  id?: InputMaybe<Scalars['ID']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ParticipantResponse = {
  __typename?: 'ParticipantResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  participant?: Maybe<Participant>;
  participantV2?: Maybe<ParticipantV2>;
  success: Scalars['Boolean'];
};

export type ParticipantV2 = Node & {
  __typename?: 'ParticipantV2';
  age?: Maybe<Scalars['Int']>;
  assignments?: Maybe<AssignmentConnection>;
  avatar?: Maybe<Avatar>;
  batteryResultById?: Maybe<BatteryResult>;
  batteryResults?: Maybe<BatteryResultConnection>;
  birthDate?: Maybe<Scalars['Date']>;
  birthYear?: Maybe<Scalars['Int']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactPreference?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  education?: Maybe<ValueListItem>;
  ethnicity?: Maybe<Array<Maybe<ValueListItem>>>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  handedness?: Maybe<Handedness>;
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  /** @deprecated replaced by multi valued races field */
  race?: Maybe<ValueListItem>;
  races?: Maybe<Array<ValueListItem>>;
  sexAssignedAtBirth?: Maybe<SexAssignedAtBirth>;
  status?: Maybe<Status>;
};


export type ParticipantV2AssignmentsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};


export type ParticipantV2BatteryResultByIdArgs = {
  batteryResultId: Scalars['ID'];
};


export type ParticipantV2BatteryResultsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};

export type ParticipantV2Connection = Connection & {
  __typename?: 'ParticipantV2Connection';
  connectionInfo?: Maybe<ConnectionInfo>;
  errors: Array<Scalars['String']>;
  nodes: Array<ParticipantV2>;
  success: Scalars['Boolean'];
};

export const PathwayType = {
  GreenHearingBad: 'GreenHearingBad',
  GreenHearingBadAdditionalConcerns: 'GreenHearingBadAdditionalConcerns',
  GreenHearingGoodAdditionalConcerns: 'GreenHearingGoodAdditionalConcerns',
  GreenNoConcerns: 'GreenNoConcerns',
  RedHearingBad: 'RedHearingBad',
  RedHearingGood: 'RedHearingGood',
  YellowHearingBadDelayedRecallBad: 'YellowHearingBadDelayedRecallBad',
  YellowHearingBadDelayedRecallGood: 'YellowHearingBadDelayedRecallGood',
  YellowHearingGood: 'YellowHearingGood'
} as const;

export type PathwayType = typeof PathwayType[keyof typeof PathwayType];
export type PlaceholderMutationResponse = {
  __typename?: 'PlaceholderMutationResponse';
  success: Scalars['Boolean'];
};

export type PlaceholderQueryResponse = {
  __typename?: 'PlaceholderQueryResponse';
  params: ParamsObject;
  success: Scalars['Boolean'];
};

/** Query to get data on the current user of the web application. */
export type Query = {
  __typename?: 'Query';
  /** Retrieves a collection of all batteries. */
  batteries: BatteryConnection;
  /** query which retrieves data about CDS run V2 */
  cdsRun: CdsRun;
  /** Search patients with filtering and pagination */
  clinicalPatientTableSearch: ClinicalPatientTableSearchResponse;
  /** Search Battery Results with filtering and pagination */
  clinicalResultsTableSearch: ClinicalResultsTableSearchResponse;
  /**
   * The current user query will return with a currentUserResponse that will include data on the current user.
   * The currentUserResponse will also include meta data on the status of the query:
   * Success indications and a collection of errors (if there were any).
   */
  currentUser?: Maybe<CurrentUserResponse>;
  featureFlags: Array<Maybe<Feature>>;
  /** Endpoint that takes in a segResultId and filename, and returns a presigned URL for that asset */
  getSegmentResultArtifact: ArtifactPresignedUrlResponse;
  /**
   * The organization query will return the information of the organization whose id matches
   * the passed orgId.
   */
  organization?: Maybe<Organization>;
  /**
   * The organizations query collects information on all organizations saved in the database.
   * It will return a list of all organizations based on the settings passed in the connection object.
   */
  organizations: OrganizationConnection;
  /**
   * The organizations query collects information on all organizations related to the userId.
   * It will return a list of all organizations related to the userId.
   */
  organizationsByUser: OrganizationConnection;
  participant: Participant;
  participantV2: ParticipantV2;
  participants: ParticipantConnection;
  participantsV2: ParticipantV2Connection;
  placeholderQuery: PlaceholderQueryResponse;
  /** Search participants with filtering and pagination */
  researchParticipantTableSearch: ResearchParticipantTableSearchResponse;
  researchResultsTableSearch: ResearchResultsTableSearchResponse;
  /** Pull list of available Linus timezones */
  timezones: TimezoneResponse;
  /** Search user(s) with filtering and pagination */
  userTableSearch: UserTableSearchResponse;
  valueList?: Maybe<ValueList>;
  valueLists: ValueListConnection;
};


/** Query to get data on the current user of the web application. */
export type QueryBatteriesArgs = {
  connection?: InputMaybe<ConnectionInput>;
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Query to get data on the current user of the web application. */
export type QueryCdsRunArgs = {
  batteryResultId: Scalars['ID'];
};


/** Query to get data on the current user of the web application. */
export type QueryClinicalPatientTableSearchArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
  searchInput: ClinicalPatientTableSearchInput;
};


/** Query to get data on the current user of the web application. */
export type QueryClinicalResultsTableSearchArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
  searchInput: ClinicalResultTableSearchInput;
  showDcrScore?: InputMaybe<Scalars['Boolean']>;
};


/** Query to get data on the current user of the web application. */
export type QueryCurrentUserArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Query to get data on the current user of the web application. */
export type QueryGetSegmentResultArtifactArgs = {
  fileName: Scalars['String'];
  segmentResultId: Scalars['ID'];
};


/** Query to get data on the current user of the web application. */
export type QueryOrganizationArgs = {
  orgId: Scalars['ID'];
};


/** Query to get data on the current user of the web application. */
export type QueryOrganizationsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};


/** Query to get data on the current user of the web application. */
export type QueryOrganizationsByUserArgs = {
  connection?: InputMaybe<ConnectionInput>;
  userId: Scalars['ID'];
};


/** Query to get data on the current user of the web application. */
export type QueryParticipantArgs = {
  id: Scalars['ID'];
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Query to get data on the current user of the web application. */
export type QueryParticipantV2Args = {
  id: Scalars['ID'];
  orgId?: InputMaybe<Scalars['ID']>;
};


/** Query to get data on the current user of the web application. */
export type QueryParticipantsArgs = {
  connection?: InputMaybe<ConnectionInput>;
  orgId: Scalars['ID'];
};


/** Query to get data on the current user of the web application. */
export type QueryParticipantsV2Args = {
  connection?: InputMaybe<ConnectionInput>;
  orgId: Scalars['ID'];
};


/** Query to get data on the current user of the web application. */
export type QueryPlaceholderQueryArgs = {
  params: Params;
};


/** Query to get data on the current user of the web application. */
export type QueryResearchParticipantTableSearchArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
  searchInput: ResearchParticipantTableSearchInput;
};


/** Query to get data on the current user of the web application. */
export type QueryResearchResultsTableSearchArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
  searchInput: ResearchResultTableSearchInput;
};


/** Query to get data on the current user of the web application. */
export type QueryUserTableSearchArgs = {
  orgId?: InputMaybe<Scalars['ID']>;
  searchInput: UserTableSearchInput;
};


/** Query to get data on the current user of the web application. */
export type QueryValueListArgs = {
  valueListId: Scalars['ID'];
};


/** Query to get data on the current user of the web application. */
export type QueryValueListsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};

export type ReInviteUserInput = {
  clientId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  userId: Scalars['ID'];
};

/** Input required to create a new cds run record */
export type RecordCdsConcernsInput = {
  /** The version of the CDS V2 Concerns engine used */
  algorithmVersion: Scalars['String'];
  /** Battery result ID to be used for processing concerns list */
  batteryResultId: Scalars['ID'];
  /** List of the concerns returned by the CDS V2 Concerns engine */
  cdsConcerns: Array<ConcernType>;
};

/** Input required to create a new cds pathway record */
export type RecordCdsPathwayInput = {
  /** Pathway value returned by the CDS V2 Pathway engine */
  cdsPathway?: InputMaybe<PathwayType>;
  /** Unique id of the cds run */
  cdsRunId: Scalars['ID'];
};

/** Data used to process and update segment results */
export type RegisterSegmentResultDataInput = {
  /** Battery result ID to be used for processing */
  batteryResultId: Scalars['ID'];
  /** The url pointing to the data uploaded from mobile within AWS S3 bucket */
  fileUrl?: InputMaybe<Scalars['String']>;
  /** Organization ID related to segment result */
  organizationId: Scalars['ID'];
  /** Segment result ID to be used for processing */
  segmentResultId: Scalars['ID'];
};

export const ReportType = {
  /** Black and white version for the short provider report */
  BlackAndWhiteProviderReport: 'BLACK_AND_WHITE_PROVIDER_REPORT',
  /** Action Plan generated for Care Partners */
  CarePartnerReportActionPlan: 'CARE_PARTNER_REPORT_ACTION_PLAN',
  /** Report generated for Patients, includes a BHAP report */
  PatientReport: 'PATIENT_REPORT',
  /** Action Plan generated for Patients */
  PatientReportActionPlan: 'PATIENT_REPORT_ACTION_PLAN',
  /** Report generated for providers and EHR integrations */
  ProviderReport: 'PROVIDER_REPORT',
  /** Report generated for providers and EHR integrations (includes CDS Recommendations) */
  ProviderReportWithCds: 'PROVIDER_REPORT_WITH_CDS'
} as const;

export type ReportType = typeof ReportType[keyof typeof ReportType];
/** The possible search fields */
export const ResearchParticipantTableSearchField = {
  ParticipantExternalId: 'PARTICIPANT_EXTERNAL_ID'
} as const;

export type ResearchParticipantTableSearchField = typeof ResearchParticipantTableSearchField[keyof typeof ResearchParticipantTableSearchField];
export type ResearchParticipantTableSearchInput = {
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /** The search parameters, optional */
  search?: InputMaybe<ResearchParticipantTableSearchParams>;
  /** The sort parameters, optional */
  sort?: InputMaybe<ResearchParticipantTableSortParams>;
};

export type ResearchParticipantTableSearchInputObject = {
  __typename?: 'ResearchParticipantTableSearchInputObject';
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /** The search parameters, optional */
  search?: Maybe<ResearchParticipantTableSearchParamsObject>;
  /** The sort parameters, optional */
  sort?: Maybe<ResearchParticipantTableSortParamsObject>;
};

export type ResearchParticipantTableSearchParams = {
  /** one of the enumerated search fields */
  prop: ResearchParticipantTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

export type ResearchParticipantTableSearchParamsObject = {
  __typename?: 'ResearchParticipantTableSearchParamsObject';
  /** one of the enumerated search fields */
  prop: ResearchParticipantTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

export type ResearchParticipantTableSearchResponse = {
  __typename?: 'ResearchParticipantTableSearchResponse';
  /** the number of participants returned from the query, should never exceed limit */
  count: Scalars['Int'];
  /** Array of errors encountered */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The result of the search query */
  participants: Array<ResearchParticipantTableSearchResult>;
  /** The input parameters that created this result */
  requestParams: ResearchParticipantTableSearchInputObject;
  /** Success Flag */
  success: Scalars['Boolean'];
  /** the total number of participants that satisfy the query */
  total: Scalars['Int'];
};

/** A limited entity to resolve needed data for tables */
export type ResearchParticipantTableSearchResult = {
  __typename?: 'ResearchParticipantTableSearchResult';
  assignedTime?: Maybe<Scalars['DateTime']>;
  assignmentId?: Maybe<Scalars['ID']>;
  assignmentStatus?: Maybe<AssignmentStatus>;
  batteryDisplayKey?: Maybe<Scalars['String']>;
  batteryId?: Maybe<Scalars['ID']>;
  lastCompletedBatteryEndTime?: Maybe<Scalars['DateTime']>;
  participantBirthYear?: Maybe<Scalars['Int']>;
  participantExternalId?: Maybe<Scalars['String']>;
  participantId: Scalars['ID'];
};

/** The possible sort fields */
export const ResearchParticipantTableSortField = {
  AssignedTime: 'ASSIGNED_TIME',
  BatteryStatus: 'BATTERY_STATUS',
  LastCompletedBatteryTime: 'LAST_COMPLETED_BATTERY_TIME',
  ParticipantExternalId: 'PARTICIPANT_EXTERNAL_ID',
  YearOfBirth: 'YEAR_OF_BIRTH'
} as const;

export type ResearchParticipantTableSortField = typeof ResearchParticipantTableSortField[keyof typeof ResearchParticipantTableSortField];
export type ResearchParticipantTableSortParams = {
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: ResearchParticipantTableSortField;
};

export type ResearchParticipantTableSortParamsObject = {
  __typename?: 'ResearchParticipantTableSortParamsObject';
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: ResearchParticipantTableSortField;
};

/** The possible search fields for research */
export const ResearchResultTableSearchField = {
  BatteryResultStatus: 'BATTERY_RESULT_STATUS',
  ParticipantExternalId: 'PARTICIPANT_EXTERNAL_ID'
} as const;

export type ResearchResultTableSearchField = typeof ResearchResultTableSearchField[keyof typeof ResearchResultTableSearchField];
export type ResearchResultTableSearchInput = {
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /** The search parameters, optional */
  search?: InputMaybe<ResearchResultTableSearchParams>;
  searches?: InputMaybe<Array<ResearchResultTableSearchParams>>;
  /** The sort parameters, optional */
  sort?: InputMaybe<ResearchResultTableSortParams>;
};

export type ResearchResultTableSearchInputObject = {
  __typename?: 'ResearchResultTableSearchInputObject';
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /**
   * The search parameters, optional
   * @deprecated use `searches`
   */
  search?: Maybe<ResearchResultTableSearchParamsObject>;
  searches?: Maybe<Array<ResearchResultTableSearchParamsObject>>;
  /** The sort parameters, optional */
  sort?: Maybe<ResearchResultTableSortParamsObject>;
};

export type ResearchResultTableSearchParams = {
  /** one of the enumerated search fields */
  prop: ResearchResultTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

export type ResearchResultTableSearchParamsObject = {
  __typename?: 'ResearchResultTableSearchParamsObject';
  /** one of the enumerated search fields */
  prop: ResearchResultTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

/** The possible sort fields for research */
export const ResearchResultTableSortField = {
  BatteryResultEndTime: 'BATTERY_RESULT_END_TIME',
  ParticipantExternalId: 'PARTICIPANT_EXTERNAL_ID',
  ProctorFirstName: 'PROCTOR_FIRST_NAME',
  ProctorLastName: 'PROCTOR_LAST_NAME'
} as const;

export type ResearchResultTableSortField = typeof ResearchResultTableSortField[keyof typeof ResearchResultTableSortField];
export type ResearchResultTableSortParams = {
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: ResearchResultTableSortField;
};

export type ResearchResultTableSortParamsObject = {
  __typename?: 'ResearchResultTableSortParamsObject';
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: ResearchResultTableSortField;
};

export type ResearchResultsTableSearchResponse = {
  __typename?: 'ResearchResultsTableSearchResponse';
  /** The result of the search query */
  batteryResults: Array<ResearchResultsTableSearchResult>;
  /** the number of battery results returned from the query, should never exceed limit */
  count: Scalars['Int'];
  /** Array of errors encountered */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The input parameters that created this result */
  requestParams: ResearchResultTableSearchInputObject;
  /** Success Flag */
  success: Scalars['Boolean'];
  /** the total number of battery results that satisfy the query */
  total: Scalars['Int'];
};

/** A limited entity to resolve needed data for tables */
export type ResearchResultsTableSearchResult = {
  __typename?: 'ResearchResultsTableSearchResult';
  batteryDisplayKey?: Maybe<Scalars['String']>;
  batteryId: Scalars['ID'];
  batteryResultEndTime?: Maybe<Scalars['DateTime']>;
  batteryResultHasConcerns?: Maybe<Scalars['Boolean']>;
  batteryResultHasProcessingError?: Maybe<Scalars['Boolean']>;
  batteryResultId: Scalars['ID'];
  batteryResultStartTime?: Maybe<Scalars['DateTime']>;
  batteryResultStatus?: Maybe<Scalars['String']>;
  participantExternalId?: Maybe<Scalars['String']>;
  participantId: Scalars['ID'];
  proctorFirstName?: Maybe<Scalars['String']>;
  proctorId?: Maybe<Scalars['ID']>;
  proctorLastName?: Maybe<Scalars['String']>;
};

export type Response = {
  __typename?: 'Response';
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/**
 * An interface that should be implemented by all Queries that return a single object.
 * It provides the success/errors for the query
 */
export type Result = {
  data?: Maybe<Node>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** An entity tied to a user and which contains operations a user can perform */
export type Role = Node & {
  __typename?: 'Role';
  /** The key used to display the role description in the front end */
  descriptionKey: Scalars['String'];
  /** The key used to display the role name in the front end */
  displayKey: Scalars['String'];
  /** A unique identifier (GUID) tied to this specific role */
  id: Scalars['ID'];
  /** The internal name for the role */
  name: Scalars['String'];
  /** The set of operations that a user with this role is allowed to act on */
  operations: Array<Operation>;
};

/** Response object when a collection of roles is fetched */
export type RoleConnection = Connection & {
  __typename?: 'RoleConnection';
  /** Criteria used when retrieving the set of roles from the Database */
  connectionInfo?: Maybe<ConnectionInfo>;
  /** Collection of errors encountered while executing the call */
  errors: Array<Scalars['String']>;
  /** Collection of roles that satisfy the criteria in the connectionInfo settings */
  nodes: Array<Role>;
  /** Whether the call was successful or not */
  success: Scalars['Boolean'];
};

/** The object which contains the id of the role we want to act on */
export type RoleInput = {
  /** The identifier for a specific role we want to act on (GUID) */
  id: Scalars['ID'];
};

/** Segments specific tests that have specific functions and some may return metrics. */
export type Segment = Node & {
  __typename?: 'Segment';
  /** ID of the segment */
  id: Scalars['ID'];
  /** Indiciation that there will or will not be metrics coming back from this segment */
  isExpectingMetrics: Scalars['Boolean'];
  /** Indication that there will or will not be results from the segment (ie Pencil Practice will not have results) */
  isExpectingResults: Scalars['Boolean'];
  /** Name of the segment */
  name: Scalars['String'];
  /** The type of test this segment is */
  segmentType: SegmentType;
};

/** Contains data related to the lifecycle of a segment */
export type SegmentResult = Node & {
  __typename?: 'SegmentResult';
  /** Time that segment result is created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Application version used on device during segment */
  deviceAppVersion?: Maybe<Scalars['String']>;
  /** Battery level of device used during segment */
  deviceBattery?: Maybe<Scalars['String']>;
  /** Brightness of device used during segment */
  deviceBrightness?: Maybe<Scalars['String']>;
  /** ID of device used during segment */
  deviceId?: Maybe<Scalars['String']>;
  /** Model of device used during segment */
  deviceModel?: Maybe<Scalars['String']>;
  /** Operating System (OS) of device used during segment */
  deviceOs?: Maybe<Scalars['String']>;
  /** Type of device used during segment */
  deviceType?: Maybe<Scalars['String']>;
  /** Time that segment result finished */
  endTime?: Maybe<Scalars['DateTime']>;
  /** Indicates analysis engine processing error */
  hasProcessingError?: Maybe<Scalars['Boolean']>;
  /** ID of the segment result (GUID) */
  id: Scalars['ID'];
  /** Indicates that the result for the segment have been submitted */
  isComplete?: Maybe<Scalars['Boolean']>;
  /** A collection of metrics that are specific to the segment */
  metricItems: MetricItemConnection;
  /** User overseeing the battery */
  proctor?: Maybe<User>;
  /** The url pointing to the data uploaded from mobile within AWS S3 bucket */
  rawDataUrl?: Maybe<Scalars['String']>;
  /** The segment the results are based on */
  segment: Segment;
};


/** Contains data related to the lifecycle of a segment */
export type SegmentResultMetricItemsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};

/** Response data for segment results query when passing in a connection */
export type SegmentResultConnection = Connection & {
  __typename?: 'SegmentResultConnection';
  /** Contains the initial data from the connection input as well as page count and total count */
  connectionInfo?: Maybe<ConnectionInfo>;
  /** Any underlying errors while retrieving data */
  errors: Array<Scalars['String']>;
  /** Collection of the requested object type */
  nodes: Array<SegmentResult>;
  /** Indication that all underyling requests had no errors */
  success: Scalars['Boolean'];
};

export type SegmentResultUploadUrlInput = {
  /** An array of segment result ids for which we want to generate urls. */
  uploadRequests: Array<SegmentUrlRequest>;
};

export type SegmentResultsResponse = {
  __typename?: 'SegmentResultsResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  segmentResult?: Maybe<SegmentResult>;
  success: Scalars['Boolean'];
};

export const SegmentType = {
  BackwardsDigitSpan: 'BACKWARDS_DIGIT_SPAN',
  Clock: 'CLOCK',
  ComplexPictureDescription: 'COMPLEX_PICTURE_DESCRIPTION',
  CustomHinmConsent: 'CUSTOM_HINM_CONSENT',
  CustomHinmSurvey: 'CUSTOM_HINM_SURVEY',
  CustomQuestionnaire: 'CUSTOM_QUESTIONNAIRE',
  DelayedRecall_6: 'DELAYED_RECALL_6',
  DelayedRecognition: 'DELAYED_RECOGNITION',
  DigitSymbol: 'DIGIT_SYMBOL',
  Epsom: 'EPSOM',
  FotoTest: 'FOTO_TEST',
  HearingScreener: 'HEARING_SCREENER',
  ImmediateRecall_6: 'IMMEDIATE_RECALL_6',
  Lhq15: 'LHQ15',
  Lhq32: 'LHQ32',
  LongitudinalEpsom: 'LONGITUDINAL_EPSOM',
  PencilPractice: 'PENCIL_PRACTICE',
  PhonemicFluency: 'PHONEMIC_FLUENCY',
  PhonemicFluencyFas: 'PHONEMIC_FLUENCY_FAS',
  RecallDelayed: 'RECALL_DELAYED',
  RecallImmediate: 'RECALL_IMMEDIATE',
  SemanticFluency: 'SEMANTIC_FLUENCY',
  SimpleReactionTime: 'SIMPLE_REACTION_TIME',
  SpiralTracing: 'SPIRAL_TRACING',
  TrailsA: 'TRAILS_A',
  TrailsB: 'TRAILS_B',
  TrailsV2A: 'TRAILS_V2_A',
  TrailsV2B: 'TRAILS_V2_B',
  Vismet: 'VISMET'
} as const;

export type SegmentType = typeof SegmentType[keyof typeof SegmentType];
export type SegmentUrlRequest = {
  /** The segment result to upload. */
  segmentResultId: Scalars['ID'];
};

export const SexAssignedAtBirth = {
  Complex: 'COMPLEX',
  Female: 'FEMALE',
  Male: 'MALE',
  Other: 'OTHER',
  ToBeCollectedAtTimeOfTesting: 'TO_BE_COLLECTED_AT_TIME_OF_TESTING',
  Unknown: 'UNKNOWN'
} as const;

export type SexAssignedAtBirth = typeof SexAssignedAtBirth[keyof typeof SexAssignedAtBirth];
export type SlimUser = Node & {
  __typename?: 'SlimUser';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  status: UserStatus;
  zendeskId?: Maybe<Scalars['String']>;
};

export type Sort = {
  __typename?: 'Sort';
  direction: SortDir;
  field: Scalars['String'];
};

export const SortDir = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortDir = typeof SortDir[keyof typeof SortDir];
export type SortInput = {
  direction: SortDir;
  field: Scalars['String'];
};

export type StartAssignmentInput = {
  assignmentId: Scalars['ID'];
  participantId: Scalars['ID'];
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type StartedAssignmentData = {
  __typename?: 'StartedAssignmentData';
  assessmentResults: Array<CreateAssessmentResultData>;
  batteryResultId: Scalars['ID'];
  rawDataUrl: Scalars['String'];
};

export type StartedAssignmentResponse = {
  __typename?: 'StartedAssignmentResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  resultData?: Maybe<StartedAssignmentData>;
  success: Scalars['Boolean'];
};

export const Status = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE'
} as const;

export type Status = typeof Status[keyof typeof Status];
export type SubjectStatus = {
  __typename?: 'SubjectStatus';
  status: Status;
  statusDate?: Maybe<Scalars['Date']>;
  subjectId: Scalars['String'];
};

export type SubjectStatusResponse = {
  __typename?: 'SubjectStatusResponse';
  errors?: Maybe<Array<Scalars['String']>>;
  subjectStatus?: Maybe<SubjectStatus>;
  success: Scalars['Boolean'];
};

export type SubmitForgotPasswordInput = {
  code: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export const TestEnum = {
  Random: 'RANDOM',
  Some: 'SOME',
  Values: 'VALUES'
} as const;

export type TestEnum = typeof TestEnum[keyof typeof TestEnum];
/** The object containing information about the timezone */
export type Timezone = {
  __typename?: 'Timezone';
  /** Friendly display name consumed by clients */
  display: Scalars['String'];
  /** A valid IANA timezone supported by Postgres (used as primary key) */
  name: Scalars['String'];
};

/** Includes a collection of all available timezones supported by Linus */
export type TimezoneResponse = {
  __typename?: 'TimezoneResponse';
  timezones?: Maybe<Array<Timezone>>;
};

export type UrlResult = {
  __typename?: 'URLResult';
  /** The segment or battery result ID */
  entityId: Scalars['ID'];
  /** Error message if success is false */
  error?: Maybe<Scalars['String']>;
  /** The s3 upload URL */
  presignedUrl?: Maybe<Scalars['String']>;
  /** Success Flag */
  success: Scalars['Boolean'];
};

/** The information needed to update an organization */
export type UpdateOrganizationInput = {
  /** List of batteries that the organization will have access to. */
  batteries?: InputMaybe<Array<Scalars['ID']>>;
  /** List of CDS v2 concerns configuration */
  cdsConcernConfigurations?: InputMaybe<Array<ConcernType>>;
  /** The format of the displayed date of the updated organization */
  dateFormat?: InputMaybe<Scalars['String']>;
  /** The default timezone of the organization */
  defaultTimezone?: InputMaybe<Scalars['String']>;
  /** The default language locale for the updated organization */
  defaultUserLocale?: InputMaybe<Locale>;
  /** A description of the updated organization */
  description?: InputMaybe<Scalars['String']>;
  /** A list of features an organization may have access to */
  features?: InputMaybe<Array<InputMaybe<FeatureType>>>;
  /** The id of updated organization */
  id: Scalars['ID'];
  /** The name of the updated organization */
  name?: InputMaybe<Scalars['String']>;
  /** The updated list of languages the tests can be performed in by the participants for the updated organization */
  participantLanguages?: InputMaybe<Array<Scalars['ID']>>;
  /** The updated list of recall words the recall segments can perform on the participants for the updated organization */
  recallWordSets?: InputMaybe<Array<Scalars['ID']>>;
  /** The new status of the organization to be updated */
  status?: InputMaybe<OrganizationStatus>;
  /** The updated list of values such as ethnicity, race, etc. for the updated organization */
  valueLists?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateParticipantInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['DateTime']>;
  birthYear?: InputMaybe<Scalars['Int']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  contactPreference?: InputMaybe<Scalars['String']>;
  educationId?: InputMaybe<Scalars['ID']>;
  ethnicityIds?: InputMaybe<Array<Scalars['ID']>>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  handedness?: InputMaybe<Handedness>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  raceId?: InputMaybe<Scalars['ID']>;
  raceIds?: InputMaybe<Array<Scalars['ID']>>;
  sexAssignedAtBirth?: InputMaybe<SexAssignedAtBirth>;
  status?: InputMaybe<Status>;
};

/** New input to allow a user to change its default organization */
export type UpdatePrimaryOrganizationInput = {
  /** The id of the organization the user wishes to change its default to */
  targetOrgId: Scalars['ID'];
};

/** New input for when the user profile is updated. */
export type UpdateProfileInput = {
  /** The first name taken from the update user mutation. */
  firstName: Scalars['String'];
  /** The id from the update user mutation. */
  id: Scalars['ID'];
  /** The last name taken from the update user mutation. */
  lastName: Scalars['String'];
  /** The phone number taken from the update user mutation. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** The new role assigned to the user, taken from the update user mutation. */
  role?: InputMaybe<RoleInput>;
  /** The new collection of suffixs taken from the update user mutation. */
  suffixIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateSegmentResultInput = {
  assessmentResultId: Scalars['ID'];
  batteryResultId: Scalars['ID'];
  deviceAppVersion: Scalars['String'];
  deviceBattery: Scalars['String'];
  deviceBrightness: Scalars['String'];
  deviceId: Scalars['String'];
  deviceModel: Scalars['String'];
  deviceOs: Scalars['String'];
  deviceType: Scalars['String'];
  endTime?: InputMaybe<Scalars['DateTime']>;
  proctorId: Scalars['ID'];
  segmentResultId: Scalars['ID'];
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateUserInput = {
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role: RoleInput;
  suffixIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type User = Node & {
  __typename?: 'User';
  avatar: Avatar;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isThirdPartyManaged: Scalars['Boolean'];
  lastName: Scalars['String'];
  organization: Organization;
  phoneNumber?: Maybe<Scalars['String']>;
  role: Role;
  status: UserStatus;
  suffix?: Maybe<Array<ValueListItem>>;
  zendeskId?: Maybe<Scalars['String']>;
};

export type UserConnection = Connection & {
  __typename?: 'UserConnection';
  connectionInfo?: Maybe<ConnectionInfo>;
  errors: Array<Scalars['String']>;
  nodes: Array<User>;
  success: Scalars['Boolean'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export const UserStatus = {
  Active: 'ACTIVE',
  Deactivated: 'DEACTIVATED',
  Invited: 'INVITED'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];
/** The possible search fields */
export const UserTableSearchField = {
  UserFullName: 'USER_FULL_NAME'
} as const;

export type UserTableSearchField = typeof UserTableSearchField[keyof typeof UserTableSearchField];
export type UserTableSearchInput = {
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /** The search parameters, optional */
  search?: InputMaybe<UserTableSearchParams>;
  /** The sort parameters, optional */
  sort?: InputMaybe<UserTableSortParams>;
};

export type UserTableSearchInputObject = {
  __typename?: 'UserTableSearchInputObject';
  /** The number of results to return */
  limit: Scalars['Int'];
  /** the number of results to skip before selecting the next 'limit' results */
  offset: Scalars['Int'];
  /** The search parameters, optional */
  search?: Maybe<UserTableSearchParamsObject>;
  /** The sort parameters, optional */
  sort?: Maybe<UserTableSortParamsObject>;
};

export type UserTableSearchParams = {
  /** one of the enumerated search fields */
  prop: UserTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

export type UserTableSearchParamsObject = {
  __typename?: 'UserTableSearchParamsObject';
  /** one of the enumerated search fields */
  prop: UserTableSearchField;
  /** The search predicate */
  value: Scalars['String'];
};

export type UserTableSearchResponse = {
  __typename?: 'UserTableSearchResponse';
  /** the number of users returned from the query, should never exceed limit */
  count: Scalars['Int'];
  /** Array of errors encountered */
  errors?: Maybe<Array<Scalars['String']>>;
  /** The input parameters that created this result */
  requestParams: UserTableSearchInputObject;
  /** Success Flag */
  success: Scalars['Boolean'];
  /** the total number of users that satisfy the query */
  total: Scalars['Int'];
  /** The result of the search query */
  users: Array<UserTableSearchResult>;
};

/** A limited entity to resolve needed data for tables */
export type UserTableSearchResult = {
  __typename?: 'UserTableSearchResult';
  isThirdPartyManaged?: Maybe<Scalars['Boolean']>;
  userAvatarDefaultUrl?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userFirstName: Scalars['String'];
  userId: Scalars['ID'];
  userLastName: Scalars['String'];
  userPhoneNumber?: Maybe<Scalars['String']>;
  userRoleId: Scalars['String'];
  userRoleOperations: Array<Operation>;
  userStatus: UserStatus;
  userSuffixes?: Maybe<Array<ValueListItem>>;
};

/** The possible sort fields */
export const UserTableSortField = {
  UserEmail: 'USER_EMAIL',
  UserLastName: 'USER_LAST_NAME',
  UserRoleName: 'USER_ROLE_NAME',
  UserStatus: 'USER_STATUS'
} as const;

export type UserTableSortField = typeof UserTableSortField[keyof typeof UserTableSortField];
export type UserTableSortParams = {
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: UserTableSortField;
};

export type UserTableSortParamsObject = {
  __typename?: 'UserTableSortParamsObject';
  /** Sort direction, either asc or desc */
  dir: SortDir;
  /** one of the enumerated sort fields */
  prop: UserTableSortField;
};

export type ValueList = Node & {
  __typename?: 'ValueList';
  id: Scalars['ID'];
  items: ValueListItemConnection;
  name?: Maybe<Scalars['String']>;
  type: ValueListType;
};


export type ValueListItemsArgs = {
  connection?: InputMaybe<ConnectionInput>;
};

export type ValueListConnection = Connection & {
  __typename?: 'ValueListConnection';
  connectionInfo?: Maybe<ConnectionInfo>;
  errors: Array<Scalars['String']>;
  nodes: Array<ValueList>;
  success: Scalars['Boolean'];
};

export type ValueListItem = Node & {
  __typename?: 'ValueListItem';
  display?: Maybe<Scalars['String']>;
  displayKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  longDisplay?: Maybe<Scalars['String']>;
  longDisplayKey?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ValueListItemConnection = Connection & {
  __typename?: 'ValueListItemConnection';
  connectionInfo?: Maybe<ConnectionInfo>;
  errors: Array<Scalars['String']>;
  nodes: Array<ValueListItem>;
  success: Scalars['Boolean'];
};

export const ValueListType = {
  Education: 'EDUCATION',
  Ethnicity: 'ETHNICITY',
  InterfaceLocale: 'INTERFACE_LOCALE',
  ParticipantLanguage: 'PARTICIPANT_LANGUAGE',
  Race: 'RACE',
  RecallWordSet: 'RECALL_WORD_SET',
  Suffix: 'SUFFIX'
} as const;

export type ValueListType = typeof ValueListType[keyof typeof ValueListType];
export type ZendeskRedirectUrlInput = {
  article?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type ZendeskRedirectUrlResponse = {
  __typename?: 'ZendeskRedirectUrlResponse';
  redirectUrl: Scalars['String'];
  success: Scalars['Boolean'];
};

export type OrganizationsByUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type OrganizationsByUserQuery = { __typename?: 'Query', organizationsByUser: { __typename?: 'OrganizationConnection', success: boolean, nodes: Array<{ __typename?: 'Organization', id: string, name: string, type: OrganizationType, status: OrganizationStatus, roles: { __typename?: 'RoleConnection', nodes: Array<{ __typename?: 'Role', id: string, name: string, displayKey: string, descriptionKey: string, operations: Array<{ __typename?: 'Operation', name: OperationToken }> }> } }> } };

export type CdsRunQueryVariables = Exact<{
  batteryResultId: Scalars['ID'];
}>;


export type CdsRunQuery = { __typename?: 'Query', cdsRun: { __typename?: 'CdsRun', algorithmVersion: string, batteryResultId: string, id: string, concerns: Array<ConcernType>, pathway?: PathwayType | undefined } };

export type CurrentUserQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['ID']>;
}>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'CurrentUserResponse', success: boolean, errors: Array<string>, currentUser?: { __typename?: 'CurrentUser', id: string, firstName: string, lastName: string, email: string, avatarUrl: string, role: string, roleId: string, phoneNumber?: string | undefined, operations: Array<OperationToken>, organizationId: string, organizationType: OrganizationType, organizationName: string, userStatus: UserStatus, suffix: Array<{ __typename?: 'ValueListItem', id: string, value?: string | undefined, display?: string | undefined, displayKey?: string | undefined }> } | undefined } | undefined };

export type GetReportForParticipantQueryVariables = Exact<{
  participantId: Scalars['ID'];
  batteryResultId: Scalars['ID'];
  orgId?: InputMaybe<Scalars['ID']>;
}>;


export type GetReportForParticipantQuery = { __typename?: 'Query', participant: { __typename?: 'Participant', id: string, firstName: string, lastName: string, birthDate: any, gender?: Gender | undefined, sexAssignedAtBirth?: SexAssignedAtBirth | undefined, language: string, batteryResultById?: { __typename?: 'BatteryResult', cdsRecommendations: Array<{ __typename?: 'CDSRecommendation', contentId: string, algorithmVersion: string, contentVersion?: string | undefined }> } | undefined } };

export type BatteryHeaderFragment = { __typename?: 'Participant', id: string, firstName: string, lastName: string, birthDate: any, gender?: Gender | undefined, sexAssignedAtBirth?: SexAssignedAtBirth | undefined, language: string, batteryResultById?: { __typename?: 'BatteryResult', cdsRecommendations: Array<{ __typename?: 'CDSRecommendation', contentId: string, algorithmVersion: string, contentVersion?: string | undefined }> } | undefined };

export type GetBatteryResultsForIndividualQueryVariables = Exact<{
  participantId: Scalars['ID'];
  connection: ConnectionInput;
  metricItemsConnection: ConnectionInput;
  orgId?: InputMaybe<Scalars['ID']>;
}>;


export type GetBatteryResultsForIndividualQuery = { __typename?: 'Query', participant: { __typename?: 'Participant', id: string, firstName: string, lastName: string, avatar?: { __typename?: 'Avatar', defaultUrl?: string | undefined } | undefined, batteryResults: { __typename?: 'BatteryResultConnection', nodes: Array<{ __typename?: 'BatteryResult', id: string, endTime?: any | undefined, status: BatteryResultStatus, participant: { __typename?: 'Participant', id: string, firstName: string, lastName: string, avatar?: { __typename?: 'Avatar', defaultUrl?: string | undefined } | undefined }, battery: { __typename?: 'Battery', name: string, displayKey: string, batteryType: BatteryType }, proctor?: { __typename?: 'User', firstName: string, lastName: string, suffix?: Array<{ __typename?: 'ValueListItem', id: string, display?: string | undefined, displayKey?: string | undefined }> | undefined } | undefined, metricItems: { __typename?: 'MetricItemConnection', nodes: Array<{ __typename?: 'MetricItem', key: string, value: string, algorithmVersion: string }> }, assessmentResults: { __typename?: 'AssessmentResultConnection', nodes: Array<{ __typename?: 'AssessmentResult', id: string, hasProcessingError: boolean, assessment: { __typename?: 'Assessment', assessmentType: AssessmentType }, segmentResults: { __typename?: 'SegmentResultConnection', nodes: Array<{ __typename?: 'SegmentResult', hasProcessingError?: boolean | undefined, metricItems: { __typename?: 'MetricItemConnection', nodes: Array<{ __typename?: 'MetricItem', id: string, key: string, value: string, algorithmVersion: string }> } }> } }> } }> } } };

export type ParticipantWithBatteryResultsFragment = { __typename?: 'Participant', id: string, firstName: string, lastName: string, avatar?: { __typename?: 'Avatar', defaultUrl?: string | undefined } | undefined, batteryResults: { __typename?: 'BatteryResultConnection', nodes: Array<{ __typename?: 'BatteryResult', id: string, endTime?: any | undefined, status: BatteryResultStatus, participant: { __typename?: 'Participant', id: string, firstName: string, lastName: string, avatar?: { __typename?: 'Avatar', defaultUrl?: string | undefined } | undefined }, battery: { __typename?: 'Battery', name: string, displayKey: string, batteryType: BatteryType }, proctor?: { __typename?: 'User', firstName: string, lastName: string, suffix?: Array<{ __typename?: 'ValueListItem', id: string, display?: string | undefined, displayKey?: string | undefined }> | undefined } | undefined, metricItems: { __typename?: 'MetricItemConnection', nodes: Array<{ __typename?: 'MetricItem', key: string, value: string, algorithmVersion: string }> }, assessmentResults: { __typename?: 'AssessmentResultConnection', nodes: Array<{ __typename?: 'AssessmentResult', id: string, hasProcessingError: boolean, assessment: { __typename?: 'Assessment', assessmentType: AssessmentType }, segmentResults: { __typename?: 'SegmentResultConnection', nodes: Array<{ __typename?: 'SegmentResult', hasProcessingError?: boolean | undefined, metricItems: { __typename?: 'MetricItemConnection', nodes: Array<{ __typename?: 'MetricItem', id: string, key: string, value: string, algorithmVersion: string }> } }> } }> } }> } };

export type GetAllBatteryResultsQueryVariables = Exact<{
  orgId: Scalars['ID'];
  connection: ConnectionInput;
  metricItemsConnection: ConnectionInput;
}>;


export type GetAllBatteryResultsQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, batteryResults: { __typename?: 'BatteryResultConnection', nodes: Array<{ __typename?: 'BatteryResult', id: string, endTime?: any | undefined, status: BatteryResultStatus, participant: { __typename?: 'Participant', id: string, firstName: string, lastName: string, avatar?: { __typename?: 'Avatar', defaultUrl?: string | undefined } | undefined }, battery: { __typename?: 'Battery', name: string, displayKey: string, batteryType: BatteryType }, proctor?: { __typename?: 'User', firstName: string, lastName: string, suffix?: Array<{ __typename?: 'ValueListItem', id: string, display?: string | undefined, displayKey?: string | undefined }> | undefined } | undefined, metricItems: { __typename?: 'MetricItemConnection', nodes: Array<{ __typename?: 'MetricItem', key: string, value: string, algorithmVersion: string }> }, assessmentResults: { __typename?: 'AssessmentResultConnection', nodes: Array<{ __typename?: 'AssessmentResult', id: string, hasProcessingError: boolean, assessment: { __typename?: 'Assessment', assessmentType: AssessmentType }, segmentResults: { __typename?: 'SegmentResultConnection', nodes: Array<{ __typename?: 'SegmentResult', hasProcessingError?: boolean | undefined, metricItems: { __typename?: 'MetricItemConnection', nodes: Array<{ __typename?: 'MetricItem', id: string, key: string, value: string, algorithmVersion: string }> } }> } }> } }> } } | undefined };

export type GetPaginatedClinicalBatteryResultsQueryVariables = Exact<{
  searchInput: ClinicalResultTableSearchInput;
  orgId: Scalars['ID'];
}>;


export type GetPaginatedClinicalBatteryResultsQuery = { __typename?: 'Query', clinicalResultsTableSearch: { __typename?: 'ClinicalResultsTableSearchResponse', total: number, count: number, success: boolean, errors?: Array<string> | undefined, batteryResults: Array<{ __typename?: 'ClinicalResultsTableSearchResult', participantId: string, participantFirstName?: string | undefined, participantLastName?: string | undefined, participantExternalId?: string | undefined, batteryId: string, batteryDisplayKey?: string | undefined, proctorId?: string | undefined, proctorFirstName?: string | undefined, proctorLastName?: string | undefined, batteryResultId: string, batteryResultStatus?: BatteryResultStatus | undefined, batteryResultStartTime?: any | undefined, batteryResultEndTime?: any | undefined, batteryResultHasConcerns?: boolean | undefined, batteryResultHasProcessingError?: boolean | undefined, batteryTrailsColor?: string | undefined }> } };

export type GetAllResultsCountQueryVariables = Exact<{
  searchInput: ClinicalResultTableSearchInput;
  orgId: Scalars['ID'];
}>;


export type GetAllResultsCountQuery = { __typename?: 'Query', clinicalResultsTableSearch: { __typename?: 'ClinicalResultsTableSearchResponse', total: number, success: boolean, errors?: Array<string> | undefined } };

export type ClinicalBatteryResultFragment = { __typename?: 'ClinicalResultsTableSearchResult', participantId: string, participantFirstName?: string | undefined, participantLastName?: string | undefined, participantExternalId?: string | undefined, batteryId: string, batteryDisplayKey?: string | undefined, proctorId?: string | undefined, proctorFirstName?: string | undefined, proctorLastName?: string | undefined, batteryResultId: string, batteryResultStatus?: BatteryResultStatus | undefined, batteryResultStartTime?: any | undefined, batteryResultEndTime?: any | undefined, batteryResultHasConcerns?: boolean | undefined, batteryResultHasProcessingError?: boolean | undefined, batteryTrailsColor?: string | undefined };

export type BatteryResultsFragment = { __typename?: 'BatteryResult', id: string, endTime?: any | undefined, status: BatteryResultStatus, participant: { __typename?: 'Participant', id: string, firstName: string, lastName: string, avatar?: { __typename?: 'Avatar', defaultUrl?: string | undefined } | undefined }, battery: { __typename?: 'Battery', name: string, displayKey: string, batteryType: BatteryType }, proctor?: { __typename?: 'User', firstName: string, lastName: string, suffix?: Array<{ __typename?: 'ValueListItem', id: string, display?: string | undefined, displayKey?: string | undefined }> | undefined } | undefined, metricItems: { __typename?: 'MetricItemConnection', nodes: Array<{ __typename?: 'MetricItem', key: string, value: string, algorithmVersion: string }> }, assessmentResults: { __typename?: 'AssessmentResultConnection', nodes: Array<{ __typename?: 'AssessmentResult', id: string, hasProcessingError: boolean, assessment: { __typename?: 'Assessment', assessmentType: AssessmentType }, segmentResults: { __typename?: 'SegmentResultConnection', nodes: Array<{ __typename?: 'SegmentResult', hasProcessingError?: boolean | undefined, metricItems: { __typename?: 'MetricItemConnection', nodes: Array<{ __typename?: 'MetricItem', id: string, key: string, value: string, algorithmVersion: string }> } }> } }> } };

export type GetRolesForOrgQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type GetRolesForOrgQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, roles: { __typename?: 'RoleConnection', nodes: Array<{ __typename?: 'Role', id: string, name: string, displayKey: string, descriptionKey: string }> } } | undefined };

export type UserIsThirdPartyQueryVariables = Exact<{
  userId: Scalars['ID'];
  orgId: Scalars['ID'];
}>;


export type UserIsThirdPartyQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', userById?: { __typename?: 'User', isThirdPartyManaged: boolean } | undefined } | undefined };

export const BatteryHeaderFragmentDoc = gql`
    fragment batteryHeader on Participant {
  id
  firstName
  lastName
  birthDate
  gender
  sexAssignedAtBirth
  language
  batteryResultById(batteryResultId: $batteryResultId) {
    cdsRecommendations {
      contentId
      algorithmVersion
      contentVersion
    }
  }
}
    `;
export const BatteryResultsFragmentDoc = gql`
    fragment batteryResults on BatteryResult {
  id
  endTime
  status
  participant {
    id
    firstName
    lastName
    avatar {
      defaultUrl
    }
  }
  battery {
    name
    displayKey
    batteryType
  }
  proctor {
    firstName
    lastName
    suffix {
      id
      display
      displayKey
    }
  }
  metricItems(connection: $metricItemsConnection) {
    nodes {
      key
      value
      algorithmVersion
    }
  }
  assessmentResults {
    nodes {
      assessment {
        assessmentType
      }
      id
      hasProcessingError
      segmentResults {
        nodes {
          metricItems {
            nodes {
              id
              key
              value
              algorithmVersion
            }
          }
          hasProcessingError
        }
      }
    }
  }
}
    `;
export const ParticipantWithBatteryResultsFragmentDoc = gql`
    fragment participantWithBatteryResults on Participant {
  id
  firstName
  lastName
  avatar {
    defaultUrl
  }
  batteryResults(connection: $connection) {
    nodes {
      ...batteryResults
    }
  }
}
    ${BatteryResultsFragmentDoc}`;
export const ClinicalBatteryResultFragmentDoc = gql`
    fragment clinicalBatteryResult on ClinicalResultsTableSearchResult {
  participantId
  participantFirstName
  participantLastName
  participantExternalId
  batteryId
  batteryDisplayKey
  proctorId
  proctorFirstName
  proctorLastName
  batteryResultId
  batteryResultStatus
  batteryResultStartTime
  batteryResultEndTime
  batteryResultHasConcerns
  batteryResultHasProcessingError
  batteryTrailsColor
}
    `;
export const OrganizationsByUserDocument = gql`
    query OrganizationsByUser($userId: ID!) {
  organizationsByUser(userId: $userId) {
    success
    nodes {
      id
      name
      type
      status
      roles {
        nodes {
          id
          name
          displayKey
          descriptionKey
          operations {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrganizationsByUserQuery__
 *
 * To run a query within a React component, call `useOrganizationsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOrganizationsByUserQuery(baseOptions: Apollo.QueryHookOptions<OrganizationsByUserQuery, OrganizationsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsByUserQuery, OrganizationsByUserQueryVariables>(OrganizationsByUserDocument, options);
      }
export function useOrganizationsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsByUserQuery, OrganizationsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsByUserQuery, OrganizationsByUserQueryVariables>(OrganizationsByUserDocument, options);
        }
export type OrganizationsByUserQueryHookResult = ReturnType<typeof useOrganizationsByUserQuery>;
export type OrganizationsByUserLazyQueryHookResult = ReturnType<typeof useOrganizationsByUserLazyQuery>;
export type OrganizationsByUserQueryResult = Apollo.QueryResult<OrganizationsByUserQuery, OrganizationsByUserQueryVariables>;
export const CdsRunDocument = gql`
    query CdsRun($batteryResultId: ID!) {
  cdsRun(batteryResultId: $batteryResultId) {
    algorithmVersion
    batteryResultId
    id
    concerns
    pathway
  }
}
    `;

/**
 * __useCdsRunQuery__
 *
 * To run a query within a React component, call `useCdsRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useCdsRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCdsRunQuery({
 *   variables: {
 *      batteryResultId: // value for 'batteryResultId'
 *   },
 * });
 */
export function useCdsRunQuery(baseOptions: Apollo.QueryHookOptions<CdsRunQuery, CdsRunQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CdsRunQuery, CdsRunQueryVariables>(CdsRunDocument, options);
      }
export function useCdsRunLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CdsRunQuery, CdsRunQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CdsRunQuery, CdsRunQueryVariables>(CdsRunDocument, options);
        }
export type CdsRunQueryHookResult = ReturnType<typeof useCdsRunQuery>;
export type CdsRunLazyQueryHookResult = ReturnType<typeof useCdsRunLazyQuery>;
export type CdsRunQueryResult = Apollo.QueryResult<CdsRunQuery, CdsRunQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser($orgId: ID) {
  currentUser(orgId: $orgId) {
    currentUser {
      id
      firstName
      lastName
      email
      avatarUrl
      role
      roleId
      phoneNumber
      operations
      organizationId
      organizationType
      organizationName
      suffix {
        id
        value
        display
        displayKey
      }
      userStatus
    }
    success
    errors
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GetReportForParticipantDocument = gql`
    query GetReportForParticipant($participantId: ID!, $batteryResultId: ID!, $orgId: ID) {
  participant(id: $participantId, orgId: $orgId) {
    ...batteryHeader
  }
}
    ${BatteryHeaderFragmentDoc}`;

/**
 * __useGetReportForParticipantQuery__
 *
 * To run a query within a React component, call `useGetReportForParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportForParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportForParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      batteryResultId: // value for 'batteryResultId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetReportForParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetReportForParticipantQuery, GetReportForParticipantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportForParticipantQuery, GetReportForParticipantQueryVariables>(GetReportForParticipantDocument, options);
      }
export function useGetReportForParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportForParticipantQuery, GetReportForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportForParticipantQuery, GetReportForParticipantQueryVariables>(GetReportForParticipantDocument, options);
        }
export type GetReportForParticipantQueryHookResult = ReturnType<typeof useGetReportForParticipantQuery>;
export type GetReportForParticipantLazyQueryHookResult = ReturnType<typeof useGetReportForParticipantLazyQuery>;
export type GetReportForParticipantQueryResult = Apollo.QueryResult<GetReportForParticipantQuery, GetReportForParticipantQueryVariables>;
export const GetBatteryResultsForIndividualDocument = gql`
    query GetBatteryResultsForIndividual($participantId: ID!, $connection: ConnectionInput!, $metricItemsConnection: ConnectionInput!, $orgId: ID) {
  participant(id: $participantId, orgId: $orgId) {
    ...participantWithBatteryResults
  }
}
    ${ParticipantWithBatteryResultsFragmentDoc}`;

/**
 * __useGetBatteryResultsForIndividualQuery__
 *
 * To run a query within a React component, call `useGetBatteryResultsForIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatteryResultsForIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatteryResultsForIndividualQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      connection: // value for 'connection'
 *      metricItemsConnection: // value for 'metricItemsConnection'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetBatteryResultsForIndividualQuery(baseOptions: Apollo.QueryHookOptions<GetBatteryResultsForIndividualQuery, GetBatteryResultsForIndividualQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatteryResultsForIndividualQuery, GetBatteryResultsForIndividualQueryVariables>(GetBatteryResultsForIndividualDocument, options);
      }
export function useGetBatteryResultsForIndividualLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatteryResultsForIndividualQuery, GetBatteryResultsForIndividualQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatteryResultsForIndividualQuery, GetBatteryResultsForIndividualQueryVariables>(GetBatteryResultsForIndividualDocument, options);
        }
export type GetBatteryResultsForIndividualQueryHookResult = ReturnType<typeof useGetBatteryResultsForIndividualQuery>;
export type GetBatteryResultsForIndividualLazyQueryHookResult = ReturnType<typeof useGetBatteryResultsForIndividualLazyQuery>;
export type GetBatteryResultsForIndividualQueryResult = Apollo.QueryResult<GetBatteryResultsForIndividualQuery, GetBatteryResultsForIndividualQueryVariables>;
export const GetAllBatteryResultsDocument = gql`
    query GetAllBatteryResults($orgId: ID!, $connection: ConnectionInput!, $metricItemsConnection: ConnectionInput!) {
  organization(orgId: $orgId) {
    id
    batteryResults(connection: $connection) {
      nodes {
        ...batteryResults
      }
    }
  }
}
    ${BatteryResultsFragmentDoc}`;

/**
 * __useGetAllBatteryResultsQuery__
 *
 * To run a query within a React component, call `useGetAllBatteryResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBatteryResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBatteryResultsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      connection: // value for 'connection'
 *      metricItemsConnection: // value for 'metricItemsConnection'
 *   },
 * });
 */
export function useGetAllBatteryResultsQuery(baseOptions: Apollo.QueryHookOptions<GetAllBatteryResultsQuery, GetAllBatteryResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBatteryResultsQuery, GetAllBatteryResultsQueryVariables>(GetAllBatteryResultsDocument, options);
      }
export function useGetAllBatteryResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBatteryResultsQuery, GetAllBatteryResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBatteryResultsQuery, GetAllBatteryResultsQueryVariables>(GetAllBatteryResultsDocument, options);
        }
export type GetAllBatteryResultsQueryHookResult = ReturnType<typeof useGetAllBatteryResultsQuery>;
export type GetAllBatteryResultsLazyQueryHookResult = ReturnType<typeof useGetAllBatteryResultsLazyQuery>;
export type GetAllBatteryResultsQueryResult = Apollo.QueryResult<GetAllBatteryResultsQuery, GetAllBatteryResultsQueryVariables>;
export const GetPaginatedClinicalBatteryResultsDocument = gql`
    query GetPaginatedClinicalBatteryResults($searchInput: ClinicalResultTableSearchInput!, $orgId: ID!) {
  clinicalResultsTableSearch(searchInput: $searchInput, orgId: $orgId) {
    batteryResults {
      ...clinicalBatteryResult
    }
    total
    count
    success
    errors
  }
}
    ${ClinicalBatteryResultFragmentDoc}`;

/**
 * __useGetPaginatedClinicalBatteryResultsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedClinicalBatteryResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedClinicalBatteryResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedClinicalBatteryResultsQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetPaginatedClinicalBatteryResultsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedClinicalBatteryResultsQuery, GetPaginatedClinicalBatteryResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedClinicalBatteryResultsQuery, GetPaginatedClinicalBatteryResultsQueryVariables>(GetPaginatedClinicalBatteryResultsDocument, options);
      }
export function useGetPaginatedClinicalBatteryResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedClinicalBatteryResultsQuery, GetPaginatedClinicalBatteryResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedClinicalBatteryResultsQuery, GetPaginatedClinicalBatteryResultsQueryVariables>(GetPaginatedClinicalBatteryResultsDocument, options);
        }
export type GetPaginatedClinicalBatteryResultsQueryHookResult = ReturnType<typeof useGetPaginatedClinicalBatteryResultsQuery>;
export type GetPaginatedClinicalBatteryResultsLazyQueryHookResult = ReturnType<typeof useGetPaginatedClinicalBatteryResultsLazyQuery>;
export type GetPaginatedClinicalBatteryResultsQueryResult = Apollo.QueryResult<GetPaginatedClinicalBatteryResultsQuery, GetPaginatedClinicalBatteryResultsQueryVariables>;
export const GetAllResultsCountDocument = gql`
    query GetAllResultsCount($searchInput: ClinicalResultTableSearchInput!, $orgId: ID!) {
  clinicalResultsTableSearch(searchInput: $searchInput, orgId: $orgId) {
    total
    success
    errors
  }
}
    `;

/**
 * __useGetAllResultsCountQuery__
 *
 * To run a query within a React component, call `useGetAllResultsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllResultsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllResultsCountQuery({
 *   variables: {
 *      searchInput: // value for 'searchInput'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetAllResultsCountQuery(baseOptions: Apollo.QueryHookOptions<GetAllResultsCountQuery, GetAllResultsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllResultsCountQuery, GetAllResultsCountQueryVariables>(GetAllResultsCountDocument, options);
      }
export function useGetAllResultsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllResultsCountQuery, GetAllResultsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllResultsCountQuery, GetAllResultsCountQueryVariables>(GetAllResultsCountDocument, options);
        }
export type GetAllResultsCountQueryHookResult = ReturnType<typeof useGetAllResultsCountQuery>;
export type GetAllResultsCountLazyQueryHookResult = ReturnType<typeof useGetAllResultsCountLazyQuery>;
export type GetAllResultsCountQueryResult = Apollo.QueryResult<GetAllResultsCountQuery, GetAllResultsCountQueryVariables>;
export const GetRolesForOrgDocument = gql`
    query GetRolesForOrg($orgId: ID!) {
  organization(orgId: $orgId) {
    id
    roles {
      nodes {
        id
        name
        displayKey
        descriptionKey
      }
    }
  }
}
    `;

/**
 * __useGetRolesForOrgQuery__
 *
 * To run a query within a React component, call `useGetRolesForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesForOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetRolesForOrgQuery(baseOptions: Apollo.QueryHookOptions<GetRolesForOrgQuery, GetRolesForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesForOrgQuery, GetRolesForOrgQueryVariables>(GetRolesForOrgDocument, options);
      }
export function useGetRolesForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesForOrgQuery, GetRolesForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesForOrgQuery, GetRolesForOrgQueryVariables>(GetRolesForOrgDocument, options);
        }
export type GetRolesForOrgQueryHookResult = ReturnType<typeof useGetRolesForOrgQuery>;
export type GetRolesForOrgLazyQueryHookResult = ReturnType<typeof useGetRolesForOrgLazyQuery>;
export type GetRolesForOrgQueryResult = Apollo.QueryResult<GetRolesForOrgQuery, GetRolesForOrgQueryVariables>;
export const UserIsThirdPartyDocument = gql`
    query UserIsThirdParty($userId: ID!, $orgId: ID!) {
  organization(orgId: $orgId) {
    userById(userId: $userId) {
      isThirdPartyManaged
    }
  }
}
    `;

/**
 * __useUserIsThirdPartyQuery__
 *
 * To run a query within a React component, call `useUserIsThirdPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIsThirdPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIsThirdPartyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useUserIsThirdPartyQuery(baseOptions: Apollo.QueryHookOptions<UserIsThirdPartyQuery, UserIsThirdPartyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIsThirdPartyQuery, UserIsThirdPartyQueryVariables>(UserIsThirdPartyDocument, options);
      }
export function useUserIsThirdPartyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIsThirdPartyQuery, UserIsThirdPartyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIsThirdPartyQuery, UserIsThirdPartyQueryVariables>(UserIsThirdPartyDocument, options);
        }
export type UserIsThirdPartyQueryHookResult = ReturnType<typeof useUserIsThirdPartyQuery>;
export type UserIsThirdPartyLazyQueryHookResult = ReturnType<typeof useUserIsThirdPartyLazyQuery>;
export type UserIsThirdPartyQueryResult = Apollo.QueryResult<UserIsThirdPartyQuery, UserIsThirdPartyQueryVariables>;
export const namedOperations = {
  Query: {
    OrganizationsByUser: 'OrganizationsByUser',
    CdsRun: 'CdsRun',
    currentUser: 'currentUser',
    GetReportForParticipant: 'GetReportForParticipant',
    GetBatteryResultsForIndividual: 'GetBatteryResultsForIndividual',
    GetAllBatteryResults: 'GetAllBatteryResults',
    GetPaginatedClinicalBatteryResults: 'GetPaginatedClinicalBatteryResults',
    GetAllResultsCount: 'GetAllResultsCount',
    GetRolesForOrg: 'GetRolesForOrg',
    UserIsThirdParty: 'UserIsThirdParty'
  },
  Fragment: {
    batteryHeader: 'batteryHeader',
    participantWithBatteryResults: 'participantWithBatteryResults',
    clinicalBatteryResult: 'clinicalBatteryResult',
    batteryResults: 'batteryResults'
  }
}