import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { LinusModal } from '../../shared/LinusModal';
import { SwitchOrganization } from '../SwitchOrganization/SwitchOrganization';
import { useChangeDefaultOrganization } from '../../context/useChangeDefaultOrganization';
import { Organization } from '../../../components/context/useSwitchOrganization';
interface SwitchOrganizationModalProps {
	isOpen: boolean;
	onClose: () => void;
	itemClickHandler: (orgId: string) => void;
	organizations: Organization[];
	setPrimaryOrgHandler: Dispatch<SetStateAction<string>>;
}

export const SwitchOrganizationModal = ({
	isOpen,
	onClose,
	itemClickHandler,
	organizations,
	setPrimaryOrgHandler,
}: SwitchOrganizationModalProps): JSX.Element | null => {
	const { t } = useTranslation();

	const { changeDefaultOrganization, updatingPrimaryOrganization } =
		useChangeDefaultOrganization();

	async function handleSetDefault(orgId: string) {
		if (updatingPrimaryOrganization) {
			return;
		}
		const newDefaultOrganizationId = await changeDefaultOrganization(orgId);
		if (newDefaultOrganizationId) {
			setPrimaryOrgHandler(newDefaultOrganizationId);
		}
	}

	if (!isOpen) return null;

	return (
		<AnimatePresence>
			<LinusModal onClose={onClose} title={t`web.switchOrgModal.title`}>
				<SwitchOrganization
					organizations={organizations}
					onSwitchOrgListItemClick={itemClickHandler}
					onSetDefault={handleSetDefault}
				/>
			</LinusModal>
		</AnimatePresence>
	);
};
