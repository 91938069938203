import styled from 'styled-components';

import { useSetupValueLists } from 'components/shared/hooks';
import { SideBar } from '../shared/SideBar';

import { AuthenticatedRoutes } from './AuthenticatedRoutes';

const Layout = (): JSX.Element => {
	useSetupValueLists();

	return (
		<LayoutContainer>
			<SideBar />
			<StyledContent>
				<AuthenticatedRoutes />
			</StyledContent>
		</LayoutContainer>
	);
};

export { Layout };

const LayoutContainer = styled.div`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const StyledContent = styled.div(
	({ theme: { color } }) => `
	background: ${color.body};
	padding: 0 32px 16px 32px;
	width: 100%;
	overflow: auto;
	`
);
