import { create } from 'zustand';

import { ValueListEntries } from 'components/context/UserContext';

type State = {
	valueLists: ValueListEntries | null;
	loading: boolean;
	setValueLists: (valueLists: ValueListEntries | null) => void;
	setLoading: (loadingState: boolean) => void;
	resetValueLists: () => void;
};

const useValueListsStore = create<State>()((set) => ({
	valueLists: null,
	loading: false,
	setValueLists: async (valueLists) => set(() => ({ valueLists })),
	setLoading: (loading) => set(() => ({ loading })),
	resetValueLists: () => {
		set(() => ({
			valueLists: null,
			loading: false,
		}));
	},
}));

export { useValueListsStore };
