export const URLS = {
	termsOfUse: 'https://linushealth.com/terms-of-use',
	contactUs: 'https://linushealth.com/contact/',
	privacyPolicy: 'https://linushealth.com/privacy-policy',
};

export enum RoleKeys {
	LINUS_INTEGRATIONS_ADMIN = 'web.roles.linusIntegrationsAdministrator.display',
	LINUS_ITSEC = 'web.roles.linusItsec.display',
	LINUS_READ_ONLY = 'web.roles.linusReadOnly.display',
}
