import { Patient } from '@lh/eng-platform-subject-service-rest-client';

import { t } from 'i18n';
import * as yup from 'yup';

import {
	LOCATION_BATTERY_TYPE,
	LocationBatteryType,
} from '../../../../components/patients/patient.helpers';
import { PHONE_NUMBER_REGEX } from '../Components/PhoneNumber';

export type addBatteryModel = {
	battery: string;
	remoteBattery: string;
	provider: string;
	contactMethod?: string;
	contactEmail?: string;
	contactPhone?: string;
	reasonsForTestingNote?: string;
	type?: LocationBatteryType;
};

export const getModel = (patient?: Patient): addBatteryModel => {
	return {
		battery: '',
		remoteBattery: '',
		provider: '',
		reasonsForTestingNote: '',
		contactMethod: patient?.contactPreference || '',
		contactEmail: patient?.contactEmail || '',
		contactPhone: patient?.contactPhone
			? patient?.contactPhone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
			: '',
	};
};

type AddBatterySchemaProps = {
	hasRemoteAssessmentFlag?: boolean;
	isTest?: boolean;
};

export const addBatterySchema = ({
	hasRemoteAssessmentFlag,
	isTest = false,
}: AddBatterySchemaProps) => {
	return yup.object().shape(
		{
			battery: yup.string().when('type', {
				is: (val: string) =>
					!isTest && val === LOCATION_BATTERY_TYPE.IN_CLINIC,
				then: (schema) =>
					schema.required(t('web.shared.forms.schemas.required')),
				otherwise: (schema) => schema.optional(),
			}),
			remoteBattery: yup.string().when('type', {
				is: (val: string) =>
					!isTest && val === LOCATION_BATTERY_TYPE.REMOTE,
				then: (schema) =>
					schema.required(t('web.shared.forms.schemas.required')),
				otherwise: (schema) => schema.optional(),
			}),
			provider: yup.string().required(t`web.shared.required`),
			reasonsForTestingNote: yup.string(),
			type: !hasRemoteAssessmentFlag
				? yup.string().notRequired()
				: yup
						.string()
						.required(
							t('web.patients.forms.remoteAssessment.error.type')
						),
			contactMethod: yup.string().when('type', {
				is: (val: string) =>
					!isTest && val === LOCATION_BATTERY_TYPE.REMOTE,
				then: (schema) =>
					schema.required(t('web.shared.forms.schemas.required')),
				otherwise: (schema) => schema.optional(),
			}),
			contactPhone: yup
				.string()
				.optional()
				.nullable()
				.when('contactMethod', {
					is: (val: string) =>
						['phone_only', 'email_and_phone'].includes(val),
					then: (schema) =>
						schema
							.required(t('web.shared.forms.schemas.required'))
							.test(
								'is-valid-phone',
								t('web.shared.forms.schemas.invalidPhone'),
								function (item) {
									if (item) {
										return isValidPhoneNumber(item);
									}
									return true;
								}
							)
							.test(
								'isRemoteOk',
								t('web.shared.forms.schemas.required'),
								function (item) {
									return this.parent.batteryType ===
										LOCATION_BATTERY_TYPE.REMOTE
										? this.parent.contactPhone || item
										: true;
								}
							),
					otherwise: (schema) => schema.optional(),
				}),
			contactEmail: yup
				.string()
				.optional()
				.nullable()
				.email(t('web.shared.forms.schemas.invalidEmail'))
				.when('contactMethod', {
					is: (val: string) =>
						['email_only', 'email_and_phone'].includes(val),
					then: (schema) =>
						schema
							.required(t('web.shared.forms.schemas.required'))
							.test(
								'isRemoteOk',
								t('web.shared.forms.schemas.required'),
								function (item) {
									return this.parent.batteryType ===
										LOCATION_BATTERY_TYPE.REMOTE
										? this.parent.contactEmail || item
										: true;
								}
							),
					otherwise: (schema) => schema.optional(),
				})
				.when('type', {
					is: (val: string) =>
						val === LOCATION_BATTERY_TYPE.IN_CLINIC,
					then: (schema) => schema.optional(),
				}),
		},
		[
			['contactPhone', 'contactMethod'], // this is disabling dependency ordering for these fields b/c they depend on each other - https://github.com/jquense/yup/issues/176#issuecomment-369925782
			['contactEmail', 'contactMethod'],
		]
	);
};

const isValidPhoneNumber = (phoneNumber: string) => {
	return PHONE_NUMBER_REGEX.test(phoneNumber);
};
