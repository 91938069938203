import {
	ConcernType,
	FeatureType,
	Organization as OrganizationRest,
} from '@lh/eng-platform-organization-service-rest-client';

import { vlEnumeration } from 'enums/enumeration';
import { locale } from 'enums/locale';
import { participantLanguageToPatientDisplayTransform } from 'utils/valueListUtils';

import { OrganizationSettings } from './UserContext';

export const buildOrgPreferences = (
	organization: OrganizationRest
): OrganizationSettings => {
	const orgPreferences = organization.preferences;

	const organizationParticipantLanguages = vlEnumeration(
		orgPreferences.participantLanguages,
		participantLanguageToPatientDisplayTransform
	);

	return {
		organizationFeatures: orgPreferences.features as FeatureType[],
		organizationCdsConcerns:
			orgPreferences.cdsConcernConfigurations as ConcernType[],
		organizationId: organization.id,
		organizationName: organization.name,
		organizationDateFormat: organization.dateFormat,
		organizationType: organization.type,
		organizationStatus: organization.status,
		organizationDefaultUserLocale:
			locale.fromDisplay(organization.defaultUserLocale) ?? locale.EnUs,
		organizationDefaultTimezone: organization.defaultTimezone ?? '',
		organizationParticipantLanguages,
		organizationValueLists: orgPreferences.valueLists,
	};
};
