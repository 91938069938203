import { buildList } from '../Pathway.list';
import { tMany } from '../Pathway.misc';

import { ReversibleCause, ReversibleCauseID } from './ReversibleCauses.types';

export const reversibleCausesMap: Record<ReversibleCauseID, ReversibleCause> = {
	[ReversibleCauseID.Drugs]: {
		id: ReversibleCauseID.Drugs,
		title: 'cds.reversibleCauses.drugs.title',
		nextSteps: buildList(tMany('cds.reversibleCauses.drugs.nextSteps')),
	},
	[ReversibleCauseID.Emotional]: {
		id: ReversibleCauseID.Emotional,
		title: 'cds.reversibleCauses.emotional.title',
		nextSteps: buildList(tMany('cds.reversibleCauses.emotional.nextSteps')),
	},
	[ReversibleCauseID.Metabolic]: {
		id: ReversibleCauseID.Metabolic,
		title: 'cds.reversibleCauses.metabolic.title',
		nextSteps: buildList(tMany('cds.reversibleCauses.metabolic.nextSteps')),
	},
	[ReversibleCauseID.EyesAndEarImpairment]: {
		id: ReversibleCauseID.EyesAndEarImpairment,
		title: 'cds.reversibleCauses.eyesAndEarImpairment.title',
		nextSteps: 'cds.reversibleCauses.eyesAndEarImpairment.nextSteps',
	},
	[ReversibleCauseID.NeoplasmAndSpaceOccupyingLesions]: {
		id: ReversibleCauseID.NeoplasmAndSpaceOccupyingLesions,
		title: 'cds.reversibleCauses.neoplasmAndSpaceOccupyingLesions.title',
		nextSteps:
			'cds.reversibleCauses.neoplasmAndSpaceOccupyingLesions.nextSteps',
	},
	[ReversibleCauseID.TBITestosteroneDeficiency]: {
		id: ReversibleCauseID.TBITestosteroneDeficiency,
		title: tMany('cds.reversibleCauses.tbiTestosteroneDeficiency.title'),
		nextSteps: buildList(
			tMany('cds.reversibleCauses.tbiTestosteroneDeficiency.nextSteps')
		),
	},
	[ReversibleCauseID.Infection]: {
		id: ReversibleCauseID.Infection,
		title: 'cds.reversibleCauses.infection.title',
		nextSteps: 'cds.reversibleCauses.infection.nextSteps',
	},
	[ReversibleCauseID.AtrialFibrillation]: {
		id: ReversibleCauseID.AtrialFibrillation,
		title: tMany('cds.reversibleCauses.atrialFibrillation.title'),
		nextSteps: buildList(
			tMany('cds.reversibleCauses.atrialFibrillation.nextSteps')
		),
	},
	[ReversibleCauseID.SubsyndromalDelirium]: {
		id: ReversibleCauseID.SubsyndromalDelirium,
		title: tMany('cds.reversibleCauses.subsyndromalDelirium.title'),
		nextSteps: buildList(
			tMany('cds.reversibleCauses.subsyndromalDelirium.nextSteps')
		),
	},
};

export const reversibleCausesLinks = {
	ABC: 'https://www.acbcalc.com',
	GDS: 'https://geriatrictoolkit.missouri.edu/cog/GDS_SHORT_FORM.PDF',
	PHQ_9: 'https://www.apa.org/depression-guideline/patient-health-questionnaire.pdf',
	GAD_7: 'https://adaa.org/sites/default/files/GAD-7_Anxiety-updated_0.pdf',
	pittsburgh:
		'https://www.sleep.pitt.edu/wp-content/uploads/Study_Instruments_Measures/PSQI-Instrument.pdf',
};
