import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { t } from 'i18n';
import { useMemo } from 'react';
import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';
import { TrialsResult } from './ImmediateRecall.types';

export const MAX_SCORE = '12';
export const MAX_TRIAL_SCORE = '6';

export const useImmediateRecallData = (segmentResult: DeepSegmentResult) => {
	const { trialsResult, requiresReview } = useMemo(() => {
		return parseImmediateRecallMetrics(segmentResult);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});

	audioFiles.forEach((file) => {
		if (file.type === ImmediateRecallFilenames.ImmediateRecall1) {
			trialsResult.trial1.url = file.responseUrl;
		}
		if (file.type === ImmediateRecallFilenames.ImmediateRecall2) {
			trialsResult.trial2.url = file.responseUrl;
		}
	});

	return { trialsResult, requiresReview };
};

export const parseImmediateRecallMetrics = (
	segmentResult: DeepSegmentResult
) => {
	const trialsResult: TrialsResult = {
		correctResponses: '',
		trial1: {
			score: '',
			prompt: '',
			url: '',
		},
		trial2: {
			score: '',
			prompt: '',
			url: '',
		},
	};

	let trial1NumericScore = 0;
	let trial2NumericScore = 0;

	segmentResult.metricItems.forEach((metricItem) => {
		if (metricItem.key.includes('pvlt_recall_score_trial_1')) {
			trial1NumericScore = Number(metricItem.value);
		}
		if (metricItem.key.includes('pvlt_recall_score_trial_2')) {
			trial2NumericScore = Number(metricItem.value);
		}
		if (metricItem.key.includes('pvlt_expected_words_trial_1')) {
			trialsResult.trial1.prompt = processPrompt(metricItem.value);
		}
		if (metricItem.key.includes('pvlt_expected_words_trial_2')) {
			trialsResult.trial2.prompt = processPrompt(metricItem.value);
		}
	});

	const totalScore = trial1NumericScore + trial2NumericScore;
	const requiresReview = isNaN(totalScore);
	trialsResult.correctResponses = requiresReview
		? `--/${MAX_SCORE}`
		: `${totalScore}/${MAX_SCORE}`;

	trialsResult.trial1.score = isNaN(trial1NumericScore)
		? `--`
		: `${trial1NumericScore}/${MAX_TRIAL_SCORE}`;
	trialsResult.trial2.score = isNaN(trial2NumericScore)
		? `--`
		: `${trial2NumericScore}/${MAX_TRIAL_SCORE}`;

	return { trialsResult, requiresReview };
};

export const processPrompt = (prompt: string) => {
	return prompt
		.split(',')
		.map((word) => word[0].toUpperCase() + word.substring(1))
		.join(', ');
};

export enum ImmediateRecallFilenames {
	ImmediateRecall1 = 'immediate-recall-1',
	ImmediateRecall2 = 'immediate-recall-2',
}

export const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			segmentResultId,
			type: ImmediateRecallFilenames.ImmediateRecall1,
			filename: 'immediate-recall-audio-1.mp4',
		},
		{
			segmentResultId,
			type: ImmediateRecallFilenames.ImmediateRecall2,
			filename: 'immediate-recall-audio-2.mp4',
		},
	];
};

export const createPrompt = (prompt: string) =>
	`${t('web.report.pvltImmediateReport.prompt')} ${prompt}`;
