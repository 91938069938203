import { t } from 'i18n';
import { OrganizationType } from '../../../generated/graphql';
import { CurrentUser } from '../../../types';

export const loginLandingPage = (currentUser: CurrentUser): string => {
	const administratorRoleNames = [
		t('web.roles.clinicalAdministrator.display'),
		t('web.roles.manager.display'),
		t('web.roles.linusAdministrator.display'),
		t('web.roles.linusLimitedAdministrator.display'),
	];
	switch (currentUser?.organizationType) {
		case OrganizationType.Clinical: {
			if (administratorRoleNames.includes(t(currentUser.role))) {
				return '/organization';
			} else {
				return '/patients';
			}
		}
		default: {
			return '/organization';
		}
	}
};
