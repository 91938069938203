import { usePrevious } from '@mantine/hooks';
import { useContext, useEffect } from 'react';

import { useValueListItemsQuery } from 'api/organization';
import { UserContext, ValueListEntries } from 'components/context/UserContext';
import { vlEnumeration } from 'enums/enumeration';
import { useValueListsStore } from 'store';

export function useSetupValueLists() {
	const { currentUser } = useContext(UserContext);
	const setLoading = useValueListsStore((state) => state.setLoading);
	const setValueLists = useValueListsStore((state) => state.setValueLists);

	const valueListsResults = useValueListItemsQuery(
		currentUser.organizationValueLists
	);

	const done =
		valueListsResults.length > 0
			? valueListsResults.every((result) => result.isSuccess === true)
			: false;

	useEffect(() => {
		if (done) {
			formatValueLists();
		}
	}, [done]);

	const prevOrgId = usePrevious(currentUser.organizationId);
	useEffect(() => {
		if (prevOrgId && prevOrgId !== currentUser.organizationId) {
			setLoading(false);
			setValueLists(null);
			formatValueLists();
		}
	}, [prevOrgId, currentUser.organizationId]);

	async function formatValueLists() {
		setLoading(true);

		if (valueListsResults.length && done) {
			const formattedValueLists = valueListsResults.reduce(
				(acc: ValueListEntries, v) => {
					if (v.data) {
						acc[v.data.type] = vlEnumeration(v.data.items);
					}
					return acc;
				},
				{} as ValueListEntries
			);

			setValueLists(formattedValueLists);
			setLoading(false);
		}
	}
}
