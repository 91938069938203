import { Route, Routes } from 'react-router-dom';

import { AboutAssessments, AboutPage } from 'components/about';
import { FileNotFound } from '../FileNotFound';
import { ZendeskArticle, ZendeskArticleKeys } from '../about/ZendeskArticle';
import { loginLandingPage } from '../auth/login/loginLandingPage';
import { PatientDetails } from '../patients/PatientDetails';
import { Patients } from '../patients/Patients/Patients';
import { AuthenticatedRoute } from '../providers/RouteProvider/AuthenticatedRoute';
import { RoleBasedRoute } from '../providers/RouteProvider/RoleBasedRoute';
import { RemoteAuth } from '../remoteAuth';
import { CDSArticlePage } from '../report/CDS/CDSArticlePage';
import { CognitiveEvaluation } from '../report/CognitiveEvaluation';
import { Results } from '../results/Results';
import { Team } from '../team/team/Team';
import { UserProfile } from '../userProfile/UserProfile';

export const AuthenticatedRoutes = (): JSX.Element | null => {
	return (
		<Routes>
			<Route
				path='/organization'
				element={
					<RoleBasedRoute>
						<Team />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/patients/:patientId'
				element={
					<RoleBasedRoute>
						<PatientDetails />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/patients'
				element={
					<RoleBasedRoute>
						<Patients />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/profile'
				element={
					<AuthenticatedRoute>
						<UserProfile />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path='/results/:participantId/:reportId/:contentId'
				element={
					<RoleBasedRoute>
						<CDSArticlePage />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/results/:participantId/:reportId'
				element={
					<RoleBasedRoute>
						<CognitiveEvaluation />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/results'
				element={
					<RoleBasedRoute>
						<Results />
					</RoleBasedRoute>
				}
			/>
			<Route
				path='/remoteAuth'
				element={
					<AuthenticatedRoute>
						<RemoteAuth loginLandingPage={loginLandingPage} />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path='/about/instructions-for-use'
				element={
					<RoleBasedRoute>
						<ZendeskArticle
							article={ZendeskArticleKeys.instructionsForUse}
						/>
					</RoleBasedRoute>
				}
			/>
			<Route path='/about/assessments' element={<AboutAssessments />} />
			<Route path='/about' element={<AboutPage />} />
			<Route path='*' element={<FileNotFound />} />
		</Routes>
	);
};
