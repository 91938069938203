import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalyticsAction, sendEventData } from 'analytics';
import { ViewDetailsButton } from 'components/shared/ViewDetailsButton';
import { Caption } from 'components/shared/designSystem';

import {
	Container,
	Content,
	Info,
	MedP2,
	ScoreBox,
	ScoreValue,
	Title,
} from './MemoryImpairmentProbability.style';
import { getAdditionalText, getConainerType } from './Mip.utils';
import { MipDetailsModal } from './MipDetailsModal';

type MipProps = {
	score: number;
};

export function MemoryImpairmentProbability({ score }: Readonly<MipProps>) {
	const { t } = useTranslation();

	const [showDetails, setShowDetails] = useState(false);

	const { scoreBgColor, text, textColor } = getConainerType(score);

	function handleMipDetailsShow() {
		sendEventData({
			eventType: AnalyticsAction.ViewedMipDetails,
			eventProperties: {
				source: 'web_report',
			},
		});
		setShowDetails(true);
	}

	function handleMipDetailsHide() {
		setShowDetails(false);
	}

	return (
		<Container>
			<Info>
				<Title>{t('web.report.mip.probability')}</Title>
				<ViewDetailsButton onClick={handleMipDetailsShow} />
			</Info>
			<Content>
				<ScoreBox backgroundColor={scoreBgColor}>
					<Score score={score} textColor={textColor} />
				</ScoreBox>
				<MedP2>{text}</MedP2>
			</Content>
			{showDetails && <MipDetailsModal onClose={handleMipDetailsHide} />}
		</Container>
	);
}

type ScoreProps = {
	score: number;
	textColor: string;
};

function Score({ score, textColor }: Readonly<ScoreProps>) {
	const isRcr = score < 0;

	return !isRcr ? (
		<>
			<ScoreValue scoreColor={textColor}>{score}%</ScoreValue>
			<Caption color={textColor}>{getAdditionalText(score)}</Caption>
		</>
	) : (
		<ScoreValue scoreColor={textColor}>--</ScoreValue>
	);
}
