import {
	GetUsersSortField,
	GetUsersSortOrder,
	UserOrganizationsRoleStatus,
} from '@lh/eng-platform-organization-service-rest-client';

import { useContext, useMemo } from 'react';

import { useUsers } from 'api/user';
import { UserContext } from 'components/context';
import { DropdownOption } from 'types';
import { inlineSuffix } from 'utils/valueListUtils';

export function useProvidersInterpretingResults(): DropdownOption[] {
	const { currentUser } = useContext(UserContext);

	const { data } = useUsers({
		organizationId: currentUser.organizationId,
		pageSize: 100000,
		page: 0,
		sortField: [GetUsersSortField.LastName],
		sortOrder: [GetUsersSortOrder.Asc],
	});

	const providers = useMemo(() => {
		if (!data) {
			return [];
		}

		const activeUsers: DropdownOption[] = [];
		data.results.forEach((d) => {
			const orgRole = d.roles.find((role) => {
				return role.organizationId === currentUser.organizationId;
			});
			if (orgRole?.status === UserOrganizationsRoleStatus.Active) {
				if (d.suffix) {
					const displaySuffix = inlineSuffix(d.suffix);
					activeUsers.push({
						display: `${d.firstName} ${d.lastName} ${displaySuffix}`,
						value: `${d.id}`,
					});
				}
			}
		});
		return activeUsers;
	}, [data, currentUser.organizationId]);

	return providers;
}
