import { useContext, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { setSessionStorage } from '../../utils/sessionStorage';
import { SessionStorageEnum } from '../../enums/sessionStorageKeysEnum';
import { FileNotFound } from '../FileNotFound';
import { UserContext } from '../context/UserContext';
import { CurrentUser } from '../../types';
import { useCurrentUserBuilder } from '../context/useCurrentUserBuilder';

export const RemoteAuth = ({
	loginLandingPage,
}: {
	loginLandingPage: (currentUser: CurrentUser) => string;
}): JSX.Element | null => {
	const [searchParams] = useSearchParams();
	const { currentUser, setCurrentUser } = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const currentUserBuilder = useCurrentUserBuilder();

	const orgId = searchParams.get('orgId');

	useEffect(() => {
		if (!orgId) {
			setLoading(false);
			return;
		}
		const updateCurrentUser = async () => {
			return currentUserBuilder(orgId);
		};
		updateCurrentUser().then((updatedCurrentUser) => {
			if (updatedCurrentUser) {
				const { organizationId, organizationName, organizationType } =
					updatedCurrentUser;
				setSessionStorage(
					{
						organizationId,
						organizationType,
						organizationName,
					},
					SessionStorageEnum.ImpersonatedOrg
				);

				setCurrentUser(updatedCurrentUser);
				setLoading(false);
			}
		});
	}, [currentUser.organizationId, orgId, currentUserBuilder, setCurrentUser]);

	if (loading) {
		return null;
	}
	if (!currentUser) {
		return <FileNotFound />;
	}
	return <Navigate to={loginLandingPage(currentUser)} />;
};
