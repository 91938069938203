import { ConcernType } from 'generated/graphql';
import { t } from 'i18next';

import { buildList } from '../Pathway.list';
import {
	AdditionalConcerns,
	PathwayConcern,
	PathwayConcernID,
} from '../Pathway.types';
import { tMany } from '../Pathway.misc';

export const concernsMap: Record<PathwayConcernID, PathwayConcern> = {
	[ConcernType.Mixed]: {
		id: ConcernType.Mixed,
		title: t('cds.concerns.mixed.title'),
		rationale: t('cds.concerns.mixed.rationale'),
		nextSteps: buildList([
			...tMany('cds.concerns.mixed.nextSteps.0'),
			buildList(tMany('cds.concerns.mixed.nextSteps.1'), {
				type: 'ordered',
				listStyleType: 'lower-alpha',
			}),
			...tMany('cds.concerns.mixed.nextSteps.2'),
		]),
		details: {
			highlight: 'cds.concerns.mixed.details.highlight',
			content: tMany('cds.concerns.mixed.details.content'),
		},
	},
	[ConcernType.Memory]: {
		id: ConcernType.Memory,
		title: t('cds.concerns.memory.title'),
		rationale: t('cds.concerns.memory.rationale'),
		nextSteps: buildList(tMany('cds.concerns.memory.nextSteps')),
		details: {
			highlight: 'cds.concerns.memory.details.highlight',
			content: tMany('cds.concerns.memory.details.content'),
		},
	},
	[ConcernType.Execvascular]: {
		id: ConcernType.Execvascular,
		title: t('cds.concerns.execVascular.title'),
		rationale: t('cds.concerns.execVascular.rationale'),
		nextSteps: buildList([
			...tMany('cds.concerns.execVascular.nextSteps.0'),
			buildList(tMany('cds.concerns.execVascular.nextSteps.1'), {
				type: 'ordered',
				listStyleType: 'lower-alpha',
			}),
			...tMany('cds.concerns.execVascular.nextSteps.2'),
		]),
		details: {
			highlight: 'cds.concerns.execVascular.details.highlight',
			content: tMany('cds.concerns.execVascular.details.content'),
		},
	},
	[ConcernType.Cholinergic]: {
		id: ConcernType.Cholinergic,
		title: t('cds.concerns.cholinergic.title'),
		rationale: t('cds.concerns.cholinergic.rationale'),
		nextSteps: buildList([
			...tMany('cds.concerns.cholinergic.nextSteps.0'),
			buildList(tMany('cds.concerns.cholinergic.nextSteps.1'), {
				type: 'ordered',
				listStyleType: 'lower-alpha',
			}),
			...tMany('cds.concerns.cholinergic.nextSteps.2'),
		]),
		details: {
			highlight: 'cds.concerns.cholinergic.details.highlight',
			content: tMany('cds.concerns.cholinergic.details.content'),
		},
	},
	[ConcernType.Tremor]: {
		id: ConcernType.Tremor,
		title: t('cds.concerns.tremor.title'),
		rationale: t('cds.concerns.tremor.rationale'),
		nextSteps: buildList([
			...tMany('cds.concerns.tremor.nextSteps.0'),
			buildList(tMany('cds.concerns.tremor.nextSteps.1'), {
				type: 'ordered',
				listStyleType: 'lower-alpha',
			}),
			...tMany('cds.concerns.tremor.nextSteps.2'),
		]),
		details: {
			highlight: 'cds.concerns.tremor.details.highlight',
			content: tMany('cds.concerns.tremor.details.content'),
		},
	},
	[ConcernType.Parkinsonism]: {
		id: ConcernType.Parkinsonism,
		title: t('cds.concerns.parkinsonism.title'),
		rationale: t('cds.concerns.parkinsonism.rationale'),
		nextSteps: buildList(tMany('cds.concerns.parkinsonism.nextSteps')),
		details: {
			highlight: 'cds.concerns.parkinsonism.details.highlight',
			content: tMany('cds.concerns.parkinsonism.details.content'),
		},
	},
	[ConcernType.Execmixed]: {
		id: ConcernType.Execmixed,
		title: t('cds.concerns.execMixed.title'),
		rationale: t('cds.concerns.execMixed.rationale'),
		nextSteps: buildList([
			...tMany('cds.concerns.execMixed.nextSteps.0'),
			buildList(tMany('cds.concerns.execMixed.nextSteps.1'), {
				type: 'ordered',
				listStyleType: 'lower-alpha',
			}),
			...tMany('cds.concerns.execMixed.nextSteps.2'),
		]),
		details: {
			highlight: 'cds.concerns.execMixed.details.highlight',
			content: tMany('cds.concerns.execMixed.details.content'),
		},
	},
	[ConcernType.MemorymixedCond1]: {
		id: ConcernType.MemorymixedCond1,
		title: t('cds.concerns.memoryMixedCond1.title'),
		rationale: t('cds.concerns.memoryMixedCond1.rationale'),
		nextSteps: buildList([
			...tMany('cds.concerns.memoryMixedCond1.nextSteps.0'),
			buildList(tMany('cds.concerns.memoryMixedCond1.nextSteps.1'), {
				type: 'ordered',
				listStyleType: 'lower-alpha',
			}),
			...tMany('cds.concerns.memoryMixedCond1.nextSteps.2'),
		]),
		details: {
			highlight: 'cds.concerns.memoryMixedCond1.details.highlight',
			content: tMany('cds.concerns.memoryMixedCond1.details.content'),
		},
	},
	[ConcernType.MemorymixedCond2]: {
		id: ConcernType.MemorymixedCond2,
		title: t('cds.concerns.memoryMixedCond2.title'),
		rationale: t('cds.concerns.memoryMixedCond2.rationale'),
		nextSteps: buildList([
			...tMany('cds.concerns.memoryMixedCond2.nextSteps.0'),
			buildList(tMany('cds.concerns.memoryMixedCond2.nextSteps.1'), {
				type: 'ordered',
				listStyleType: 'lower-alpha',
			}),
			...tMany('cds.concerns.memoryMixedCond2.nextSteps.2'),
		]),
		details: {
			highlight: 'cds.concerns.memoryMixedCond2.details.highlight',
			content: tMany('cds.concerns.memoryMixedCond2.details.content'),
		},
	},
	[AdditionalConcerns.Hearing]: {
		id: AdditionalConcerns.Hearing,
		title: t('cds.concerns.hearing.title'),
		rationale: t('cds.concerns.hearing.rationale'),
		nextSteps: buildList(tMany('cds.concerns.hearing.nextSteps')),
		details: {
			highlight: '',
			content: [],
		},
	},
};

export const concernsLinks = {
	ABC: `https://www.acbcalc.com`,
	FRT: `https://www.meddean.luc.edu/lumen/meded/medicine/pulmonar/pd/pstep14.htm#:~:text=With%20eyes%20closed%2C%20the%20patient,next%20to%20the%20patient's%20ear`,
	WVT: `https://geriatrics.ucsf.edu/sites/geriatrics.ucsf.edu/files/2018-06/whispertest.pdf`,
	TUG: `https://www.cdc.gov/steadi/pdf/TUG_test-print.pdf`,
};
