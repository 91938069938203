import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function getUserOrganizations(
	organizationId: string,
	userId: string,
	signal?: AbortSignal
) {
	if (!organizationId) {
		throw new Error(
			"useGetUserOrganizationsQuery: organizationId can't be null"
		);
	}

	if (!userId) {
		throw new Error("useGetUserOrganizationsQuery: userId can't be null");
	}

	const organizationService = await getOrganizationService();
	const { data } = await organizationService.getUserOrganizations(
		{ organizationId, userId },
		{ signal }
	);
	return data;
}

export function useUserOrganizationsQuery(
	organizationId: string,
	userId: string
) {
	return useQuery({
		enabled: !!organizationId && !!userId,
		meta: {
			errorMessage: 'Error fetching user organizations',
		},
		placeholderData: keepPreviousData,
		queryKey: [QueryKey.UserOrganizations, organizationId, userId],
		queryFn: ({ signal }) =>
			getUserOrganizations(organizationId, userId, signal),
		staleTime: Infinity,
	});
}
