import { InvalidCredentialsError } from '@lh/eng-shared-auth';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
	AnalyticsAction,
	sendEventData,
	updateAmplitudeWithProps,
} from 'analytics';
import { config } from '../../../config';
import { UserContext } from '../../context/UserContext';
import { messageEnum } from '../../../enums/messageEnum';
import { routeEnum as _routeEnum, RouteEnum } from '../../../enums/routeEnum';
import { Auth, updateAuthModule } from '../../../features/auth-service';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import {
	OrganizationType,
	UserStatus,
	useCurrentUserLazyQuery,
} from '../../../generated/graphql';
import { loginLandingPage } from './loginLandingPage';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { LinusPasswordInput } from '../../shared/Forms/Components/LinusPasswordInput';
import { ButtonLg } from '../../shared/designSystem/ButtonLg';
import {
	getModel,
	LoginModel,
	validationSchema,
} from '../../shared/Forms/Schemas/loginSchema';
import { H2 } from '../../shared/designSystem/TextComponents';
import { InfoMessage } from '../../shared/InfoMessage';
import { CurrentUser } from '../../../types';

const LoginForm = ({ pathname }: { pathname?: string }): JSX.Element => {
	const isAuth0Universal = useFeatureFlag(FeatureFlags.Auth0Universal);
	const navigate = useNavigate();
	const location = useLocation();
	const [error, setError] = useState(false);
	const { logout, onLogin } = useContext(UserContext);
	const { t } = useTranslation();

	const [currentUserQuery, { loading }] = useCurrentUserLazyQuery({
		fetchPolicy: 'network-only',
	});

	const redirect = (user: CurrentUser) => {
		const enumeration = _routeEnum(user);
		// Here location.state has no shape ( it's defined by the navigation ).  We are casting
		// it to what we hope to find in it.  Then trying to access that item.  If it's not there
		// then we check to see if the pathname was passed in to the form.  This is what will most often
		// happens when a user is loggedout and tries to access a page
		let path = (location.state as { from: string })?.from || pathname;
		// If path does not exist at this point, then we default to the login landing page for the specific org.

		path = path || loginLandingPage(user);
		// Check the route to see if it is available to this user or not.
		const isRouteFound = enumeration.find((route: RouteEnum) => {
			return path?.toLowerCase().includes(route.path);
		});
		if (
			user.userStatus === UserStatus.Invited &&
			config.preferredUserProvider === 'auth0'
		) {
			const path = `/auth/finish-signup`;
			navigate(path);
		} else {
			navigate(isRouteFound ? path : '/', { replace: true });
		}
	};

	const onSubmit = async (
		values: LoginModel,
		{ setSubmitting }: FormikHelpers<LoginModel>
	) => {
		// resets the error state on click of submit
		setError(false);
		try {
			updateAuthModule(isAuth0Universal);
			const isLoggedIn = await Auth.login({
				username: values.username,
				password: values.password,
			});
			const query = await currentUserQuery();
			// Here we have to cast the gql CurrentUser to our slightly different ( added fields )
			// frontend CurrentUser
			const currentUser = query.data?.currentUser
				?.currentUser as CurrentUser;

			if (!currentUser && isLoggedIn) {
				await logout();
				navigate(`/user-not-found`);
				return;
			}
			if (!currentUser) {
				setError(true);
				return;
			}

			if (
				![OrganizationType.Clinical, OrganizationType.Linus].includes(
					currentUser.organizationType
				)
			) {
				await logout();
				navigate('/access-denied');
				return;
			}

			updateAmplitudeWithProps(currentUser);
			sendEventData({
				eventType: AnalyticsAction.StartedSession,
			});
			onLogin(currentUser);
			return redirect(currentUser);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			if (err instanceof InvalidCredentialsError) setError(true);
		}

		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={getModel()}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<StyledForm>
						<StyledH2>{t`web.login.title`}</StyledH2>
						<LinusInput
							data-testid='login-email-input'
							name='username'
							type='email'
							label={t`web.shared.emailAddress`}
						/>
						<LinusPasswordInput
							data-testid='login-password-input'
							name='password'
							label={t`web.shared.password`}
						/>
						<StyledForgotPasswordLink
							data-testid='login-forgot-password-link'
							to='/auth/forgotpassword'
						>
							{t`web.login.forgotPassword`}
						</StyledForgotPasswordLink>
						<ButtonLg
							disabled={!(isValid && dirty) || isSubmitting}
							type='submit'
							loading={loading}
							width='317px'
							text={t`web.login.title`}
							primary={true}
							dataTestId='login-submit-button'
						/>

						<StyledInfoMessage>
							<InfoMessage
								data-testid='login-txt-error'
								messageEnum={messageEnum.error(
									t`web.authErrors.invalidCredentials`
								)}
								showIf={error}
							/>
						</StyledInfoMessage>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

export { LoginForm };

const StyledForm = styled(Form)`
	position: absolute;
	width: 600px;
	height: 608px;
	padding: 64px 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: 0.4s ease all;
`;
const StyledForgotPasswordLink = styled(Link)(
	({ theme: { spacing, color } }) => `
	width: 317px;
	color: ${color.link};
	display: flex;
	justify-content: flex-start;
	margin: ${spacing.sm} 0 ${spacing.lg} 0;	
	`
);
const StyledH2 = styled(H2)(
	({ theme: { spacing } }) => `
	margin: 0 0 ${spacing.md} 0;	
	`
);

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => `
	padding-botton: ${spacing.md};
	margin: ${spacing.lg} 0 0 0;
`
);
