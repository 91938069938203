import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { usePatientsCountQuery } from 'api/patient';
import { useZendeskUrlQuery } from 'api/user';
import { icons } from 'enums/icons';
import { RouteEnum, routeEnum } from 'enums/routeEnum';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import {
	OrganizationType,
	useOrganizationsByUserQuery,
} from 'generated/graphql';
import { hasOperation } from 'hasOperation';
import { AuthProvider } from 'helpers/AuthProvider';
import { UserContext } from '../../context/UserContext';
import { getBrowserLanguage } from '../getLanguage';

import { MenuNode } from './MenuNode';

type MenuProps = {
	isCollapsed?: boolean;
	onViewSwitchOrgModalClick?: () => void;
};

export const Menu = ({
	isCollapsed,
	onViewSwitchOrgModalClick,
}: MenuProps): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const isAuth0Provider = AuthProvider.isAuth0;
	const isUniversalLoginEnabled = useFeatureFlag(FeatureFlags.Auth0Universal);
	const isAuth0Universal = isAuth0Provider && isUniversalLoginEnabled;

	const { data: totalPatients, isLoading: totalPatientsLoading } =
		usePatientsCountQuery({
			organizationId: currentUser.organizationId,
		});
	const { organizationId, id: userId } = currentUser;
	const locale = getBrowserLanguage();

	const { data: redirectUrl } = useZendeskUrlQuery({
		organizationId,
		userId,
		locale,
	});

	const { data: orgsByUserData, loading: loadingOrgsByUser } =
		useOrganizationsByUserQuery({
			variables: {
				userId: currentUser.id,
			},
		});

	const menuNodes = useMemo(() => {
		const enumeration = routeEnum(currentUser, isAuth0Universal);

		return enumeration.filter((route) => {
			if (hasOperation(currentUser, route.operations)) {
				if (route.filter) {
					return route.filter(currentUser);
				}
				return true;
			}
			return false;
		});
	}, [currentUser, isAuth0Universal]);

	if (totalPatientsLoading || !totalPatients) return null;
	// This is done this way so we can structure the order
	// menu items are listed by the order of the routeEnum(s)

	const handleOnClickMenuItem = async (node: RouteEnum) => {
		sendEventData({
			eventType: AnalyticsAction.ClickedSidebarItem,
			eventProperties: {
				name: node.text,
			},
		});

		if (node.operations.includes(OperationToken.ContactSupport)) {
			if (redirectUrl) {
				sendEventData({
					eventType: AnalyticsAction.ViewedSupport,
				});
				window.open(redirectUrl);
			}
		}
	};

	const handleSwitchOrgMenuNodeClick = () => {
		onViewSwitchOrgModalClick && onViewSwitchOrgModalClick();
	};

	const renderSwitchOrgMenuNode = () => {
		// Only render if more than 1 orgs for the user.
		if (loadingOrgsByUser) return null;
		if ((orgsByUserData?.organizationsByUser.nodes || []).length <= 1)
			return null;
		// check if multiple non-research organizations are available
		const validOrgs = orgsByUserData?.organizationsByUser.nodes?.filter(
			(organization) =>
				organization.type === OrganizationType.Clinical ||
				organization.type === OrganizationType.Linus
		);

		if (validOrgs && validOrgs.length <= 1) return null;

		return (
			<MenuNode
				isCollapsed={isCollapsed}
				text={t`web.menuItems.switchOrg`}
				menuIcon={icons.Configure}
				isBordered={true}
				path='#'
				key={t`web.menuItems.switchOrg`}
				onClick={handleSwitchOrgMenuNodeClick}
				dataTestId={`menu-node-${t('web.menuItems.switchOrg')}`}
			/>
		);
	};

	const counts = {
		Participants: totalPatients.totalCount,
	} as { [key: string]: number };
	return (
		<StyledList>
			{renderSwitchOrgMenuNode()}
			{menuNodes.map((node: RouteEnum) => (
				<MenuNode
					isCollapsed={isCollapsed}
					text={node.text}
					menuIcon={node.icon}
					isBordered={node.isBordered}
					path={node.path}
					// We will need to extend this if we ever need to support nested routes
					isActive={
						pathname === node.path ||
						pathname.includes(`${node.path}/`) // this will fix showing organization and organizations 'active' simultaneously.
					}
					key={node.text}
					onClick={() => handleOnClickMenuItem(node)}
					count={
						node.getCount
							? counts[node.getCount as keyof typeof counts]
							: undefined
					}
					dataTestId={`menu-node-${node.text}`}
				/>
			))}
		</StyledList>
	);
};

const StyledList = styled.ul`
	list-style: none;
	width: 100%;
	padding: 16px 0;
`;
