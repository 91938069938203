import { AssessmentType } from '@lh/eng-platform-battery-service-rest-client';

import { BatteryType } from 'generated/graphql';

export const BATTERIES_WITH_PATIENT_REPORT: BatteryType[] = [
	BatteryType.CognitiveEvaluation,
	BatteryType.Dcr,
	BatteryType.Lhq,
	BatteryType.Default,
	BatteryType.Iadl,
	BatteryType.DcrTrails,
];

export const ASSESSMENTS_WITH_PATIENT_REPORT: AssessmentType[] = [
	AssessmentType.Dcr,
	AssessmentType.Adl,
	AssessmentType.Iadl,
];

export const ASSESSMENTS_WITH_CARE_PARTNER: AssessmentType[] = [
	AssessmentType.Adl,
	AssessmentType.Iadl,
];
