import {
	Box,
	Center,
	Flex,
	Grid,
	Group,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Trans } from 'react-i18next';

import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';
import { theme } from 'components/providers/StyleProvider/theme';
import { AdditionalDcrResults } from 'components/report/AssessmentComponents/AdditionalDcrResults';
import { LineDivider } from 'components/shared/LineDivider';
import { LinusTooltip } from 'components/shared/LinusTooltip';
import { Caption, H2, Icon, P1, P2 } from 'components/shared/designSystem';
import { ClipboardSearch } from 'components/shared/designSystem/Icons/svgs/Svgs';
import { icons } from 'enums/icons';
import { t } from 'i18n';
import { dateFormatter } from 'stringStrategy/dateFormatStringStrategy';

import { getBackgroundColor, handleDCR } from './DCRSummaryWidget.helpers';
import { TEXT } from './DCRSummaryWidget.i18n';

type ScoreValue = number | null;

interface ScoreData {
	DCR: ScoreValue;
	clockDrawing: ScoreValue;
	delayedRecall: ScoreValue;
}

interface Props {
	canViewReport?: boolean;
	showAdditionalDcrResults?: boolean;
	data: {
		score: ScoreData;
		mmsePrediction?: number | string;
		mostRecent?: string;
		dateFormat?: string;
		defaultTimeZone?: string;
	};
	onViewReport?: () => void;
}

export function DCRSummaryWidget({
	canViewReport,
	data,
	showAdditionalDcrResults = false,
	onViewReport,
}: Readonly<Props>) {
	const isTablet = useMediaQuery(MediaQueries.maxWidth.lg);

	return (
		<Container>
			<Header
				canViewReport={canViewReport}
				mostRecent={data.mostRecent}
				score={data.score.DCR}
				dateFormat={data.dateFormat}
				defaultTimeZone={data.defaultTimeZone}
				onViewReport={onViewReport}
			/>
			<Group
				gap={16}
				align='flex-start'
				style={{
					flexDirection: isTablet ? 'column' : 'row',
				}}
			>
				<DcrScoringWidget
					score={data.score}
					additionalDcr={showAdditionalDcrResults}
				/>
				{showAdditionalDcrResults && (
					<>
						<LineDivider
							orientation={isTablet ? 'horizontal' : 'vertical'}
						/>
						<Stack
							style={{
								flex: 1,
								width: '100%',
							}}
						>
							<AdditionalDcrResults
								mmsePrediction={data.mmsePrediction}
							/>
						</Stack>
					</>
				)}
			</Group>
		</Container>
	);
}

function Container({ children }: { children: React.ReactNode }) {
	return (
		<Stack
			data-testid='dcr-summary-widget'
			style={{
				width: '100%',
				gap: 8,
				padding: 16,
				borderRadius: 10,
				backgroundColor: theme.colors.white,
				boxShadow: theme.boxShadow.standard,
			}}
		>
			{children}
		</Stack>
	);
}

function Header({
	canViewReport = false,
	mostRecent,
	score,
	dateFormat,
	defaultTimeZone,
	onViewReport,
}: {
	canViewReport?: boolean;
	mostRecent?: string;
	dateFormat?: string;
	defaultTimeZone?: string;
	score: ScoreValue;
	onViewReport?: () => void;
}) {
	const showViewReport = canViewReport && score !== null && onViewReport;

	return (
		<Stack gap={2}>
			<Group gap={2} justify='space-between' wrap='nowrap'>
				<Title />
				{showViewReport && <ViewReport onClick={onViewReport} />}
			</Group>
			{mostRecent && dateFormat && (
				<MostRecent
					value={mostRecent}
					defaultTimeZone={defaultTimeZone}
					dateFormat={dateFormat}
				/>
			)}
		</Stack>
	);
}

function Title() {
	return (
		<Group gap={2} wrap='nowrap'>
			<P1
				style={{
					color: theme.colors.gray_30,
					fontWeight: theme.weight.semi,
				}}
			>
				{t(TEXT.title)}
			</P1>
			<Tooltip>
				<Trans
					i18nKey={TEXT.tooltip}
					components={{
						b: <span style={{ fontWeight: theme.weight.semi }} />,
					}}
				/>
			</Tooltip>
		</Group>
	);
}

function Tooltip({ children }: { children: React.ReactNode }) {
	return (
		<LinusTooltip
			overlay={
				<P2
					style={{
						maxWidth: 300,
						marginLeft: 4,
						textAlign: 'start',
						whiteSpace: 'pre-wrap',
						color: theme.colors.white,
					}}
				>
					{children}
				</P2>
			}
			tooltipIcon={
				<Icon
					data-testid='empty-icon'
					icon={icons.InfoOutlined}
					iconWidth={24}
					iconHeight={24}
				/>
			}
		/>
	);
}

function ViewReport({ onClick }: { onClick(): void }) {
	return (
		<UnstyledButton ff={theme.baseFont} display='flex' onClick={onClick}>
			<Caption
				style={{
					color: theme.colors.blue,
					fontWeight: theme.weight.medium,
				}}
			>
				{t(TEXT.viewReport)}
			</Caption>
		</UnstyledButton>
	);
}

function MostRecent({
	value,
	dateFormat,
	defaultTimeZone,
}: {
	value: string;
	dateFormat: string;
	defaultTimeZone?: string;
}) {
	const formattedValue = isNaN(Date.parse(value))
		? value
		: dateFormatter(value, dateFormat, defaultTimeZone);

	return (
		<P2
			style={{
				fontStyle: 'italic',
				color: theme.colors.gray_30,
			}}
		>
			{t(TEXT.mostRecent, { value: formattedValue })}
		</P2>
	);
}

export function DcrScoringWidget({
	additionalDcr,
	score,
}: Readonly<{ additionalDcr: boolean; score: ScoreData }>) {
	return (
		<Grid
			gutter={0}
			style={{
				flexWrap: 'nowrap',
				flex: 1,
				width: '100%',
				minWidth: '445px',
				flexGrow: additionalDcr ? 0 : 1,
			}}
		>
			<Grid.Col
				span='content'
				style={{
					'.scoreTag': {
						height: '100%',
					},
				}}
			>
				<ScoreTag value={score.DCR} />
			</Grid.Col>
			<Grid.Col span='auto'>
				<Breakdown score={score} />
			</Grid.Col>
		</Grid>
	);
}

function ScoreTag({ value }: { value: ScoreValue }) {
	function parseValue(value: number) {
		let displayValue: string | number = value;

		if (value === -1) displayValue = '--';

		const maxScore = 5;

		return `${displayValue}/${maxScore}`;
	}

	if (value === null) {
		return (
			<Center h={88} mih={88} w={88} miw={88}>
				<ClipboardSearch />
			</Center>
		);
	}

	return (
		<Center
			className='score-tag'
			data-testid='score-tag'
			style={{
				minHeight: 88,
				width: 124,
				minWidth: 124,
				padding: '22px 12px',
				borderRadius: '8px 0 0 8px',
				backgroundColor: getBackgroundColor(value).solid,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<P2 style={{ fontWeight: 500, textAlign: 'center' }}>
				{t(
					'web.dcrReport.overview.scoringDescription.total'
				).toUpperCase()}
			</P2>
			<H2
				style={{
					color: theme.colors.gray_10,
					fontWeight: theme.weight.medium,
				}}
			>
				{parseValue(value)}
			</H2>
		</Center>
	);
}

function Breakdown({ score }: { score: ScoreData }) {
	if (score.DCR === null) {
		return (
			<Box
				style={{
					width: '100%',
					height: '100%',
					padding: '8px 16px',
				}}
			>
				{t(TEXT.breakdown.empty)}
			</Box>
		);
	}

	function getText(value: number) {
		let text = '';

		handleDCR(value, {
			unanalyzable: () => {
				text = TEXT.breakdown.unanalyzable;
			},
			_0_1: () => {
				text = TEXT.breakdown._0_1;
			},
			_2_3: () => {
				text = TEXT.breakdown._2_3;
			},
			_4_5: () => {
				text = TEXT.breakdown._4_5;
			},
		});

		return text;
	}

	const isUnanalyzable = score.DCR === -1;
	const backgroundColor = getBackgroundColor(score.DCR);
	const maxPoints = { clockDrawing: 2, delayedRecall: 3 };

	const content = {
		unanalyzable: (
			<Group gap={4} align='flex-start' wrap='nowrap'>
				<Flex
					style={{ gap: 4, width: 19, minWidth: 19, paddingTop: 7 }}
				>
					<SolidDash />
					<SolidDash />
				</Flex>
				<P2 style={{ color: theme.colors.gray_10 }}>
					<Trans
						i18nKey={TEXT.breakdown.unanalyzable}
						components={{
							a: (
								<span
									style={{ fontWeight: theme.weight.medium }}
								/>
							),
						}}
					/>
				</P2>
			</Group>
		),
		analyzable: (
			<>
				<Group gap={4} align='center' wrap='nowrap'>
					<Box mt={2.5}>
						<SolidDot color={backgroundColor.solid} />
					</Box>
					<P2
						style={{
							color: theme.colors.gray_10,
							fontWeight: theme.weight.medium,
						}}
					>
						<Trans
							i18nKey={getText(score.DCR)}
							components={{ b: <b /> }}
						/>
					</P2>
				</Group>
				<Stack gap={4} ml={16}>
					<P2>
						{t(TEXT.breakdown.pointsFrom.clockDrawing, {
							correct: score.clockDrawing,
							expected: maxPoints.clockDrawing,
						})}
					</P2>
					<P2>
						{t(TEXT.breakdown.pointsFrom.delayedRecall, {
							correct: score.delayedRecall,
							expected: maxPoints.delayedRecall,
						})}
					</P2>
				</Stack>
			</>
		),
	};

	return (
		<Stack
			style={{
				position: 'relative',
				width: '100%',
				height: '100%',
				gap: 8,
				padding: 8,
				borderRadius: '0 8px 8px 0',
			}}
		>
			<Background color={backgroundColor.light} />

			{isUnanalyzable ? content.unanalyzable : content.analyzable}
		</Stack>
	);
}

function Background({ color }: { color: string }) {
	return (
		<Box
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '0 8px 8px 0',
				backgroundColor: color,
				opacity: 0.2,
				pointerEvents: 'none',
			}}
		/>
	);
}

function SolidDot({ color }: { color: string }) {
	const size = 12;

	return (
		<Box
			style={{
				width: size,
				height: size,
				minWidth: size,
				minHeight: size,
				borderRadius: size,
				backgroundColor: color,
			}}
		/>
	);
}

function SolidDash() {
	return (
		<Flex
			style={{
				width: '100%',
				height: 2.8,
				minHeight: 2.8,
				backgroundColor: theme.colors.gray_30,
			}}
		/>
	);
}
