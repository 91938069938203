import { Auth } from '@lh/eng-shared-auth';

import { getConfig } from 'config';
import { ReportType } from 'generated/graphql';
import { koaPost } from 'utils/koaRestUtils';

const getBaseEndpoint = () => {
	const { apiProtocol, apiHost, apiPort } = getConfig();
	return `${apiProtocol}://${apiHost}:${apiPort}`;
};

const GET_REPORT_ENDPOINT = '/report';
const GENERATE_REPORT_ENDPOINT = '/generateReport';

export type PdfGenerateInput = {
	batteryResultId: string;
	reportType: ReportType;
	language: string;
};

export type PdfDownloadInput = PdfGenerateInput & {
	revision?: number;
};

export type PDFDownloadLinkResponse = {
	status: number;
	downloadLink?: string;
};

type KoaPDFResponse = {
	status: number;
	data: {
		reportUrl: string | undefined;
	};
};

export const getPDFDownloadLink = async (
	auth: Auth,
	input: PdfDownloadInput
): Promise<PDFDownloadLinkResponse> => {
	try {
		const response: KoaPDFResponse = await koaPost(auth, {
			url: `${getBaseEndpoint()}${GET_REPORT_ENDPOINT}`,
			body: input,
		});

		if (response.status === 200) {
			return {
				status: response.status,
				downloadLink: response.data.reportUrl,
			};
		}

		return { status: response.status };
	} catch (e: any) {
		if (e?.response?.status && e?.response?.status === 404) {
			return { status: 404 };
		}
		throw e;
	}
};

export const generatePDF = async (
	auth: Auth,
	input: PdfGenerateInput
): Promise<PDFDownloadLinkResponse> => {
	try {
		const response: KoaPDFResponse = await koaPost(auth, {
			url: `${getBaseEndpoint()}${GENERATE_REPORT_ENDPOINT}`,
			body: input,
		});

		// Should return 201
		return { status: response.status };
	} catch (e: any) {
		// Generation in progress
		if (e?.response?.status && e?.response?.status === 409) {
			return { status: 409 };
		}

		throw e;
	}
};

export const pollPromiseForPDFLink = async (
	auth: Auth,
	input: PdfGenerateInput
): Promise<PDFDownloadLinkResponse> => {
	return getPDFDownloadLink(auth, input).then((pdfDownloadResponse) => {
		if (pdfDownloadResponse.status === 404) {
			return generatePDF(auth, { ...input }).then(({ status }) => {
				if (status === 201 || status === 409) {
					// No-op to continue polling
					throw new Error('Continue polling');
				}
				// This should never occur, it'll short-circuit polling but isn't inherently bad
				return { status };
			});
		}
		return pdfDownloadResponse;
	});
};
