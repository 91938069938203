import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { OrganizationType, UserStatus } from '../../generated/graphql';
import { CurrentUser } from '../../types';

import { OrganizationSettings } from './UserContext';

export type gqlCurrentUser = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	avatarUrl: string;
	role: string;
	roleId: string;
	phoneNumber?: string | undefined;
	operations: Array<OperationToken>;
	organizationId: string;
	organizationType: OrganizationType;
	organizationName: string;
	suffix: Array<{
		__typename?: 'ValueListItem';
		id: string;
		value?: string | undefined;
		display?: string | undefined;
		displayKey?: string | undefined;
	}>;
	userStatus: UserStatus;
};

export const buildCurrentUser = (
	currentUser: gqlCurrentUser,
	orgPreferences: OrganizationSettings,
	impersonatedOrgId?: string
): CurrentUser => {
	let user = {
		...currentUser,
		...orgPreferences,
	} as CurrentUser;

	if (impersonatedOrgId) {
		user = {
			...user,
			organizationId: impersonatedOrgId,
			originalOrganization: {
				organizationId: user.organizationId,
			},
		};
	}
	return user;
};
