import {
	Organization,
	PaginatedOrganization,
} from '@lh/eng-platform-organization-service-rest-client';
import { UserBlockedError, auth0Login } from '@lh/eng-shared-auth';

import { useApolloClient } from '@apollo/client';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { useQueryClient } from '@tanstack/react-query';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { getOrganization, getUserOrganizations } from 'api/organization';
import { QueryKey } from 'api/query';
import { buildOrgPreferences } from 'components/context/buildOrganizationPreferences';
import { RoleKeys } from 'components/shared/constants';
import { config } from 'config';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import {
	CurrentUserQuery,
	OrganizationType,
	UserStatus,
	useCurrentUserLazyQuery,
} from 'generated/graphql';
import { Memoize } from 'helpers/Memoize';
import { ERROR } from 'logging/linusLogger';
import * as Amplitude from '../../../analytics/amplitude';
import { SessionStorageEnum } from '../../../enums/sessionStorageKeysEnum';
import { Auth } from '../../../features/auth-service/';
import { CurrentUser } from '../../../types';
import {
	clearSessionStorage,
	getSessionStorage,
	setSessionStorage,
} from '../../../utils/sessionStorage';
import { UserContext, nulloUser } from '../../context/UserContext';

const clearImpersonatedOrgSession = () =>
	clearSessionStorage(SessionStorageEnum.ImpersonatedOrg);

type Props = {
	children: ReactNode;
	currentUserOverrides?: CurrentUser;
};

export const UserContextProvider = ({
	children,
	currentUserOverrides = undefined,
}: Props) => {
	const client = useApolloClient();
	const queryClient = useQueryClient();
	const impersonatedOrg = getSessionStorage(
		SessionStorageEnum.ImpersonatedOrg
	);
	const isAuth0Universal = useFeatureFlag(FeatureFlags.Auth0Universal);
	const navigate = useNavigate();

	const [loggedIn, setLoggedIn] = useState(!isAuth0Universal);
	const [currentUser, setCurrentUser] = useState<CurrentUser>(
		currentUserOverrides ?? nulloUser
	);
	const [orgLoaded, setOrgLoaded] = useState(false);
	const [isImpersonation, setIsImpersonation] = useState(false);

	const onCurrentUserCompleted = (data: CurrentUserQuery) => {
		const user = data?.currentUser?.currentUser as CurrentUser;
		if (user) {
			setLoggedIn(true);
			setCurrentUser((state) => {
				return buildUser({ ...state, ...user });
			});
		}
	};

	const [getCurrentUser] = useCurrentUserLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: onCurrentUserCompleted,
		onError: () => setLoggedIn(false),
	});

	const buildUser = useCallback(
		(user: CurrentUser) => {
			return impersonatedOrg
				? {
						...user,
						originalOrganization: {
							organizationId: user.organizationId,
						},
						...impersonatedOrg,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  }
				: user;
		},
		[impersonatedOrg]
	);

	const onOrganizationQueryCompleted = (data?: Organization) => {
		if (!data) return;

		const preferences = buildOrgPreferences(data);

		setCurrentUser(buildUser({ ...currentUser, ...preferences }));
		setOrgLoaded(true);

		if (isImpersonation) {
			setIsImpersonation(false);
			setSessionStorage(
				{
					organizationId: currentUser.organizationId,
					operations: currentUser.operations,
				},
				SessionStorageEnum.ImpersonatedOrg
			);
		}
	};

	const onLogin = useCallback((_user: CurrentUser) => {
		setLoggedIn(true);
		setCurrentUser((state) => ({ ...state, ..._user }));
	}, []);

	const logoutWithoutSSO = useCallback(async () => {
		try {
			await Auth.logout();
			setLoggedIn(false);
			setCurrentUser(nulloUser);
			await client.clearStore();
			Amplitude.logout();
			clearImpersonatedOrgSession();
		} catch (error) {
			console.error('error signing out: ', error);
		}
	}, [client]);

	const logoutWithSSO = useCallback(
		async (url = config.clinicalDomainUrl) => {
			try {
				setLoggedIn(false);
				setOrgLoaded(false);
				setCurrentUser(nulloUser);
				await client.clearStore();
				clearImpersonatedOrgSession();
				Amplitude.logout();
				await Auth.logout(url);
			} catch (error) {
				console.error('error signing out: ', error);
			}
		},
		[client]
	);

	const updateUser = useCallback(
		(user: CurrentUser, isImpersonation?: boolean) => {
			unstable_batchedUpdates(() => {
				if (isImpersonation) {
					setSessionStorage(
						{
							organizationId: user.organizationId,
							operations: user.operations,
						},
						SessionStorageEnum.ImpersonatedOrg
					);
					setIsImpersonation(true);
				}
				setCurrentUser(user);
			});
		},
		[setCurrentUser, setIsImpersonation]
	);

	const clearImpersonation = useCallback(() => {
		if (currentUser.originalOrganization) {
			const currentUserWithOriginalOrg = {
				...currentUser,
				organizationId:
					currentUser?.originalOrganization.organizationId,
				originalOrganization: undefined,
			};
			setCurrentUser(currentUserWithOriginalOrg);
			clearImpersonatedOrgSession();
		}
	}, [currentUser, setCurrentUser]);

	const refetchCurrentUser = useCallback(() => {
		getCurrentUser({
			variables: {
				orgId: currentUser.organizationId,
			},
		});
	}, [currentUser.organizationId, getCurrentUser]);

	useEffect(() => {
		if (!loggedIn) {
			queryClient.clear();
		}
	}, [loggedIn, queryClient]);

	useEffect(() => {
		async function _getOrganization(orgId: string) {
			const response = await queryClient.fetchQuery({
				queryKey: [QueryKey.Organization, orgId],
				queryFn: ({ signal }) => getOrganization(orgId, signal),
				staleTime: Infinity,
			});
			onOrganizationQueryCompleted(response);
		}

		if (currentUser.organizationId) {
			_getOrganization(currentUser.organizationId);
		}
	}, [currentUser.organizationId]);

	useEffect(() => {
		if (isAuth0Universal) {
			loginWithAuth0Provider();
		}
	}, [isAuth0Universal]);

	const loginWithAuth0Provider = useCallback(() => {
		if (window.location.search.includes('error=unauthorized')) {
			logoutWithSSO(`${config.clinicalDomainUrl}/access-denied`);
		} else {
			auth0Login(
				Auth,
				config.clinicalDomainUrl,
				`${window.location.pathname}${window.location.search}`
			)
				.then(
					(response: void | RedirectLoginResult<any> | undefined) => {
						setLoggedIn(true);
						if (response?.appState?.target) {
							navigate(response?.appState?.target, {
								replace: true,
							});
						}
					}
				)
				.catch((err) => {
					if (
						err instanceof UserBlockedError ||
						err?.message?.includes('user is blocked')
					) {
						logoutWithSSO(
							`${config.clinicalDomainUrl}/access-denied`
						);
					} else {
						logoutWithSSO(
							`${config.clinicalDomainUrl}/user-not-found`
						);
					}
				});
		}
	}, [logoutWithSSO]);

	useEffect(() => {
		if (isAuth0Universal && (loggedIn || currentUserOverrides)) {
			getCurrentUser().then(async (response) => {
				const currentUser = response.data?.currentUser?.currentUser;
				let newDefaultOrg: PaginatedOrganization;
				if (currentUser) {
					const nonLoginLinusRoles = [
						RoleKeys.LINUS_INTEGRATIONS_ADMIN.toString(),
						RoleKeys.LINUS_ITSEC.toString(),
						RoleKeys.LINUS_READ_ONLY.toString(),
					];
					if (
						nonLoginLinusRoles.includes(currentUser.role) &&
						!window.location.pathname.includes('remoteAuth')
					) {
						logoutWithSSO(
							`${config.clinicalDomainUrl}/access-denied?ot=m`
						);
						return;
					}
					if (
						currentUser.organizationType !==
							OrganizationType.Linus &&
						currentUser.organizationType !==
							OrganizationType.Clinical
					) {
						try {
							// search if other non-research orgs are assigned to user
							const allAvailableOrganization =
								await queryClient.fetchQuery({
									queryKey: [
										QueryKey.UserOrganizations,
										currentUser.organizationId,
										currentUser.id,
									],
									queryFn: ({ signal }) =>
										getUserOrganizations(
											currentUser.id,
											currentUser.organizationId,
											signal
										),
									staleTime: Infinity,
								});
							const validOrgs = allAvailableOrganization.filter(
								(organization) =>
									organization.type ===
										OrganizationType.Clinical ||
									organization.type === OrganizationType.Linus
							);

							if (validOrgs.length === 0) {
								// Redirect to Clinical Org Type Access Denied Page
								logoutWithSSO(
									`${config.clinicalDomainUrl}/access-denied?ot=r`
								);
								return;
							}

							newDefaultOrg = validOrgs[0];
						} catch (e) {
							ERROR('Error at getUserOrganization: ', e);
						}
					}

					setCurrentUser((state) => {
						if (newDefaultOrg)
							return buildUser({
								...state,
								...currentUser,
								organizationId: newDefaultOrg.id,
								organizationName: newDefaultOrg.name,
							});
						return buildUser({
							...state,
							...currentUser,
						});
					});

					if (currentUser.userStatus === UserStatus.Invited) {
						navigate('/auth/finish-signup', { replace: true });
						return;
					} else if (
						currentUser.userStatus === UserStatus.Deactivated
					) {
						logoutWithSSO(
							`${config.clinicalDomainUrl}/access-denied`
						);
						return;
					}
				} else if (
					!response.error ||
					response.error.message.includes('404')
				) {
					logoutWithSSO(`${config.clinicalDomainUrl}/user-not-found`);
					return;
				}
			});
		}
		// Having 'navigate' as a part of deps here causes getUser API to be called
		// every time we navigate to a new page. That is not what we want.
		// Causes issues like HCD-1161
	}, [isAuth0Universal, loggedIn, logoutWithSSO, getCurrentUser]);

	if (isAuth0Universal && !loggedIn && !orgLoaded && !currentUser) {
		return null;
	}

	if (!isAuth0Universal && loggedIn && (!orgLoaded || !currentUser)) {
		return null;
	}

	return (
		<UserContext.Provider
			value={{
				currentUser,
				setCurrentUser: updateUser,
				updateCurrentUser: getCurrentUser,
				onLogin,
				logout: isAuth0Universal ? logoutWithSSO : logoutWithoutSSO,
				clearImpersonation,
				refetchCurrentUser,
			}}
		>
			<Memoize>{children}</Memoize>
		</UserContext.Provider>
	);
};
