import { ClockConcernsPropType } from 'components/report/SegmentComponents/ClockConcerns/ClockConcerns';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { t } from 'i18n';
import { SegmentType } from '../../../../../generated/graphql';
import { H4 } from '../../../../shared/designSystem/TextComponents';
import { FormattedSegmentResults } from '../../../cognitiveEvalParseData/cognitiveEvalParseData';
import { DctClockRecording } from '../../../SegmentComponents/DctClockRecording/DctClockRecording';
import { RecallRecording } from '../../../SegmentComponents/RecallRecording/RecallRecording';
import { PrintPageHeader } from '../PrintPageHeader';

type MiniModalRecordingsProps = {
	miniModalRecordingSegments: FormattedSegmentResults[];
	copyClockLoaded?: () => void;
	commandClockLoaded?: () => void;
	clockConcernsEnabled?: boolean;
};

type ProcessedShortPhraseKeys = {
	copyShortPhraseData: string[];
	commandShortPhraseData: string[];
};

const processShortPhraseMetricItems = (
	data: FormattedSegmentResults
): ProcessedShortPhraseKeys => {
	const copyShortPhraseData = [];
	const commandShortPhraseData = [];
	if (data?.metricItems[`expected_short_phrases`]?.value) {
		const expectedPhraseCount = Number(
			data.metricItems[`expected_short_phrases`].value
		);
		for (let i = 1; i < expectedPhraseCount + 1; i++) {
			const clockType = data?.metricItems[`short_phrase_${i}_clock`]
				?.value as string;
			const shortPhraseKey = data?.metricItems[`short_phrase_${i}_key`]
				?.value as string;
			if (clockType && shortPhraseKey) {
				if (clockType === 'COP') {
					copyShortPhraseData.push(
						t(
							`web.dcrReport.recordings.clock.shortPhrases.${shortPhraseKey}`
						)
					);
				} else if (clockType === 'COM') {
					commandShortPhraseData.push(
						t(
							`web.dcrReport.recordings.clock.shortPhrases.${shortPhraseKey}`
						)
					);
				}
			}
		}
	}
	return { copyShortPhraseData, commandShortPhraseData };
};

export function parsePhrases(data: FormattedSegmentResults) {
	const defaultClock = {
		concerns: [],
		disclaimer: t`web.dcrReport.recordings.clock.shortPhrases.disclaimer`,
		title: t`web.dcrReport.recordings.clock.shortPhrases.mainConcerns`,
		betaTitle: t``,
	};
	const { copyShortPhraseData, commandShortPhraseData } =
		processShortPhraseMetricItems(data);

	const copyClockShortPhrases: ClockConcernsPropType | undefined = {
		...defaultClock,
		concerns: copyShortPhraseData,
		sectionName: 'copy',
	};
	const commandClockShortPhrases: ClockConcernsPropType | undefined = {
		...defaultClock,
		concerns: commandShortPhraseData,
		sectionName: 'command',
	};

	return {
		copyClockShortPhrases,
		commandClockShortPhrases,
	};
}

export const MiniModalRecordings = ({
	miniModalRecordingSegments,
	copyClockLoaded,
	commandClockLoaded,
	clockConcernsEnabled,
}: MiniModalRecordingsProps): JSX.Element | null => {
	const { t } = useTranslation();

	const recallDelayedSegment = miniModalRecordingSegments.find(
		(segment) => segment.segmentType === SegmentType.RecallDelayed
	);
	const recallImmediateSegment = miniModalRecordingSegments.find(
		(segment) => segment.segmentType === SegmentType.RecallImmediate
	);

	const dctClockSegment = miniModalRecordingSegments.find(
		(segment) => segment.segmentType === SegmentType.Clock
	);

	if (!dctClockSegment || !recallImmediateSegment || !recallDelayedSegment)
		return null;

	let copyClockShortPhrases: ClockConcernsPropType | undefined = undefined;
	let commandClockShortPhrases: ClockConcernsPropType | undefined = undefined;

	if (clockConcernsEnabled) {
		const result = parsePhrases(dctClockSegment);
		copyClockShortPhrases = result.copyClockShortPhrases;
		commandClockShortPhrases = result.commandClockShortPhrases;
	}

	return (
		<StyledContainer>
			<StyledH4>
				<Trans i18nKey='web.dcrReport.recordings.title' />
			</StyledH4>
			<StyledPrintPageHeader title={t`web.dcrReport.recordings.title`} />
			<StyledWrapper>
				<StyledColumn>
					<StyledRecall>
						{/* TODO: Find a better solution for this  */}
						{/* hidden audio player temp solution for WEB-2559 */}
						<div
							style={{
								visibility: 'hidden',
								height: 0,
							}}
						>
							<audio controls></audio>
						</div>
						<RecallRecording
							subTitle={t`web.dcrReport.recordings.recall.immediate.subtitle`}
							title={t`web.dcrReport.recordings.recall.immediate.title`}
							segment={recallImmediateSegment}
							tooltipText={t`web.dcrReport.recordings.recall.immediate.tooltipText`}
						/>
					</StyledRecall>
					<StyledClock>
						<DctClockRecording
							title={t`web.dcrReport.recordings.clock.copy.title`}
							clockType={'COPY'}
							tooltipText={t`web.dcrReport.recordings.clock.copy.tooltipText`}
							segmentId={dctClockSegment.id}
							contentLoaded={copyClockLoaded}
							concern={copyClockShortPhrases}
						/>
					</StyledClock>
				</StyledColumn>
				<StyledColumn>
					<StyledClock>
						<DctClockRecording
							title={t`web.dcrReport.recordings.clock.command.title`}
							clockType={'COMMAND'}
							tooltipText={t`web.dcrReport.recordings.clock.command.tooltipText`}
							segmentId={dctClockSegment.id}
							contentLoaded={commandClockLoaded}
							concern={commandClockShortPhrases}
						/>
					</StyledClock>
					<StyledRecall>
						<RecallRecording
							subTitle={t`web.dcrReport.recordings.recall.delayed.subtitle`}
							title={t`web.dcrReport.recordings.recall.delayed.title`}
							segment={recallDelayedSegment}
							tooltipText={t`web.dcrReport.recordings.recall.delayed.tooltipText`}
						/>
					</StyledRecall>
				</StyledColumn>
			</StyledWrapper>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { spacing } }) => css`
		width: 100%;
		margin-bottom: ${spacing.xl};

		@media print {
			margin-top: ${spacing.xl};
			break-before: page;
		}
	`
);

const StyledWrapper = styled.div(
	({ theme: { spacing } }) => css`
		height: auto;
		display: flex;
		gap: ${spacing.xl};
	`
);

const StyledColumn = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		min-height: auto;
		gap: ${spacing.xl};
		width: 100%;

		@media print {
			min-height: 0;
		}
	`
);

const StyledClock = styled.div`
	height: auto;
`;

const StyledRecall = styled.div`
	@media print {
		display: none;
	}
`;

const StyledPrintPageHeader = styled(PrintPageHeader)`
	display: none;

	@media print {
		display: flex;
	}
`;

const StyledH4 = styled(H4)(
	({ theme: { spacing, weight, color } }) => css`
		font-weight: ${weight.medium};
		color: ${color.textSubtitle};
		margin-left: ${spacing.lg};
		margin-bottom: ${spacing.lg};

		@media print {
			display: none;
		}
	`
);
