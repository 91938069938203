import { AnimatePresence } from 'framer-motion';
import get from 'lodash/get';
import { AnalyticsAction, sendEventData } from 'analytics';
import { ERROR } from 'logging/linusLogger';
import { createRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useGetReport } from 'components/shared/hooks/useGetReport';
import { Auth } from 'features/auth-service';
import { getReportLabelTitle } from 'utils/reportText';
import { IssueType, Locale, ReportType } from '../../generated/graphql';
import i18n from '../../i18n';
import { getLanguageResourceKey } from '../../utils/i18nextUtils';
import { FileNotFound } from '../FileNotFound';
import { CDSContext } from '../context/CDSContext';
import { UserContext } from '../context/UserContext';
import { Header } from '../shared/Header';
import { LinusModal } from '../shared/LinusModal';
import { LoadingDots } from '../shared/LoadingDots';
import { Breadcrumbs } from '../shared/breadcrumbs/Breadcrumbs';
import { ButtonSm } from '../shared/designSystem/ButtonSm';
import { useCDS, usePolling } from '../shared/hooks';

import { BatteryHeader } from './BatteryComponents/BatteryHeader/BatteryHeader';
import { BatteryTestingIssueDetails } from './BatteryComponents/BatteryTestingIssueDetails';
import {
	PDFDownloadLinkResponse,
	pollPromiseForPDFLink,
} from './BatteryComponents/PdfDownloadService';
import { getHeaderMarginBottom } from './CognitiveEvaluation.helpers';
import { Report } from './CognitiveEvaluation.types';
import { CognitiveEvaluationFooter } from './CognitiveEvaluationFooter';
import { CognitiveEvaluationBody } from './CognitiveEvaluationBody';

const patientReportI18N = i18n.cloneInstance();

const MAX_ATTEMPTS = 13; // Retry attempts tick after the first POLLING_INTERVAL, so 13 attempts should give us an even 2 min of waiting
const POLLING_INTERVAL = 10000; // 10 seconds

export type SessionIssue = {
	options: Array<IssueType>;
	note?: string;
	reporter: { id: string; firstName: string; lastName: string };
};

type Props = {
	mockedReport?: Report;
};

export const CognitiveEvaluation = ({
	mockedReport,
}: Props): JSX.Element | null => {
	const cdsEnabled = useCDS();
	const { currentUser } = useContext(UserContext);
	const params = useParams() as { participantId: string; reportId: string };
	const { t } = useTranslation();

	const infoContainerRef = createRef<HTMLDivElement>();

	const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);
	const [isContentLoading, setIsContentLoading] = useState(false);

	const { participantId, reportId: batteryResultId } = params;
	const {
		data: {
			assignment: batteryAssignment,
			battery: batteryResult,
			issue: batteryIssue,
			participant: batteryParticipant,
		},
		loading: { loadingBattery: loadingBatteryResult },
	} = useGetReport({
		batteryResultId,
		orgId: currentUser.organizationId,
		participantId,
	});

	const report = batteryResult ?? mockedReport;
	const batteryName = batteryResult?.batteryResultById?.battery?.name;
	const batteryType = batteryResult?.batteryResultById?.battery?.batteryType;

	useEffect(() => {
		const languageResourceKey = getLanguageResourceKey(
			batteryParticipant?.language || ''
		);
		patientReportI18N.changeLanguage(languageResourceKey);
	}, [batteryParticipant]);

	useEffect(() => {
		if (batteryName && batteryResultId) {
			sendEventData({
				eventType: AnalyticsAction.ViewedReport,
				eventProperties: {
					reportName: batteryName,
					batteryResultId,
				},
			});
		}
	}, [batteryName, batteryResultId]);

	function handleGetData() {
		const reportType = ReportType.PatientReportActionPlan;

		return pollPromiseForPDFLink(Auth, {
			batteryResultId,
			reportType,
			language: currentUser?.organizationDefaultUserLocale
				.display as Locale,
		});
	}

	async function handlePollingSuccess(response: PDFDownloadLinkResponse) {
		if (!response || !response.downloadLink) return;
		if (
			response.downloadLink.match('response-content-disposition=inline')
		) {
			//If the response content disposition is inline (meant to be previewed before downloading)
			window.open(response.downloadLink, '_blank');
		} else {
			//If the file is meant to be downloaded directly.
			window.location.assign(response.downloadLink);
		}
	}

	const [, startPolling] = usePolling({
		interval: POLLING_INTERVAL,
		retryCount: MAX_ATTEMPTS,
		onGetData: handleGetData,
		onSuccess: (response) => {
			handlePollingSuccess && handlePollingSuccess(response);
		},
		onFailure: (error) => {
			ERROR('Some error occurred while polling', error);
		},
	});

	if (loadingBatteryResult) {
		return <LoadingDots />;
	}

	if (!report || !batteryType) {
		return <FileNotFound />;
	}

	const batteryTitle = getReportLabelTitle({
		batteryType: batteryType || 'COGNITIVE_EVALUATION',
		batteryName: batteryName,
	});

	const issueReporterFirstName = batteryIssue?.reporter?.firstName;
	const issueReporterLastName = batteryIssue?.reporter?.lastName;
	const reporterName = `${issueReporterFirstName || ''} ${
		issueReporterLastName || ''
	}`;

	return (
		<StyledContainer>
			<CDSContext.Provider
				value={{
					cdsEnabled: !!cdsEnabled,
					contentVersion: get(
						batteryParticipant,
						'participant.batteryResultById.cdsRecommendations.0.contentVersion',
						'1.0.0'
					),
					cdsRecommendations: get(
						batteryParticipant,
						'participant.batteryResultById.cdsRecommendations',
						[]
					),
				}}
			>
				<StyledHeader>
					<Header />
					<Breadcrumbs
						patient={{
							firstName: batteryParticipant?.firstName,
							lastName: batteryParticipant?.lastName,
						}}
						batteryType={batteryType}
						batteryName={batteryName}
					/>
				</StyledHeader>
				<StyledReportInfoContainer
					ref={infoContainerRef}
					marginBottom={getHeaderMarginBottom(batteryType)}
				>
					<BatteryHeader
						batteryAssignment={batteryAssignment}
						batteryIssue={batteryIssue}
						batteryType={batteryType}
						batteryTitle={batteryTitle}
						batteryResultId={batteryResultId}
						batteryParticipant={batteryParticipant}
						batteryResult={batteryResult}
					/>
				</StyledReportInfoContainer>
				<CognitiveEvaluationBody
					batteryParticipant={batteryParticipant}
					batteryResult={batteryResult}
					batteryType={batteryType}
					startPolling={startPolling}
					infoContainerRef={infoContainerRef}
					isContentLoading={isContentLoading}
					setIsContentLoading={setIsContentLoading}
				/>
				{!isContentLoading && (
					<CognitiveEvaluationFooter
						batteryResultsId={batteryResultId}
						patientId={participantId}
					/>
				)}
				<AnimatePresence>
					{isIssueModalOpen && (
						<LinusModal
							onClose={() => setIsIssueModalOpen(false)}
							title={t`web.shared.sessionIssues.title`}
						>
							<StyledIssuesModalWrapper>
								<BatteryTestingIssueDetails
									options={
										(batteryIssue?.options as IssueType[]) ||
										[]
									}
									note={batteryIssue?.note}
									reporterName={reporterName}
								/>
								<StyledButtonWrapper>
									<ButtonSm
										text={t`web.shared.sessionIssues.closeButton`}
										width='158px'
										onClick={() =>
											setIsIssueModalOpen(false)
										}
									/>
								</StyledButtonWrapper>
							</StyledIssuesModalWrapper>
						</LinusModal>
					)}
				</AnimatePresence>
			</CDSContext.Provider>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	() => `
	display: flex;
	align-content: center;
	flex-direction: column;
`
);

const StyledHeader = styled.div(
	({ theme: { color } }) => `
	background: ${color.body};
	position: sticky;
	top: 0;
	z-index: 3;
`
);

const StyledReportInfoContainer = styled.div<{ marginBottom: string }>(
	({ marginBottom }) => css`
		display: flex;
		margin-bottom: ${marginBottom};
	`
);

export const StyledButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
    display: flex;
    justify-content: right;
    margin-top: ${spacing.lg};
`
);

export const StyledIssuesModalWrapper = styled.div`
	min-width: 786px;
`;
