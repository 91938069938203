import { SegmentType } from '@lh/eng-platform-battery-service-rest-client';

import { FormattedSegmentResults } from 'components/report/cognitiveEvalParseData/cognitiveEvalParseData';
import { BatteryType } from 'generated/graphql';

export const BATTERIES_WITH_ADDITIONAL_INFO: BatteryType[] = [
	BatteryType.DcrPlus,
	BatteryType.CcePlus,
];

export function shouldShowAdditionalDcr(
	hasMmseFlag: boolean,
	hasMipFlag: boolean,
	batteryType?: BatteryType | null
) {
	if (!hasMmseFlag && !hasMipFlag) {
		return false;
	}

	if (!batteryType) {
		return false;
	}

	return BATTERIES_WITH_ADDITIONAL_INFO.includes(batteryType);
}

export function getDcrScores(
	miniModalSubscoreSegments: FormattedSegmentResults[]
) {
	let immediateRecallData: FormattedSegmentResults | undefined,
		dctClockData: FormattedSegmentResults | undefined,
		delayedRecallData: FormattedSegmentResults | undefined;

	miniModalSubscoreSegments.forEach((subscoreSegment) => {
		if (subscoreSegment?.segmentType === SegmentType.RecallImmediate) {
			immediateRecallData = subscoreSegment;
		}

		if (subscoreSegment?.segmentType === SegmentType.Clock) {
			dctClockData = subscoreSegment;
		}

		if (subscoreSegment?.segmentType === SegmentType.RecallDelayed) {
			delayedRecallData = subscoreSegment;
		}
	});

	const correctImmediateWordCount = Number(
		immediateRecallData?.metricItems?.correct_words_count?.value || 0
	);

	const metricData = dctClockData?.metricItems || null;
	const dctScore = metricData?.DCTScore
		? Math.floor(Number(metricData.DCTScore.value))
		: 0;

	const correctDelayedWordCount = Number(
		delayedRecallData?.metricItems?.correct_words_count?.value || 0
	);

	return {
		immediateScore: correctImmediateWordCount,
		dctScore,
		delayedScore: correctDelayedWordCount,
	};
}
