import {
	ValueList,
	ValueListItem,
} from '@lh/eng-platform-organization-service-rest-client';

import { useQueries } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';
import { orderValueLists } from 'utils/valueListUtils';

export async function getValueListItems(
	valueListId: string
): Promise<ValueListItem[]> {
	if (!valueListId) {
		throw new Error(
			"useGetValueListItemsQueries: valueListId can't be null"
		);
	}

	const service = await getOrganizationService();
	const { data } = await service.getValueListItemsByValueListId({
		valueListId,
	});
	return data;
}

export function useValueListItemsQuery(valueLists: ValueList[] | null) {
	const lists = valueLists ?? [];

	return useQueries({
		queries: lists.map((list) => ({
			queryKey: [QueryKey.ValueList, list.id],
			queryFn: () => getValueListItems(list.id),
			select: (items: ValueListItem[]) => ({
				items: orderValueLists(items),
				type: list.type,
			}),
			staleTime: Infinity,
		})),
	});
}
