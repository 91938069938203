import { t } from 'i18n';

import { tMany } from '../../Pathway.misc';

import { Reference, ReferenceID } from './ReferencesModal.types';

export const referencesMap: Record<ReferenceID, Reference> = {
	[ReferenceID.RedDCRScore]: {
		id: ReferenceID.RedDCRScore,
		title: t('cds.referencesModal.data.red-dcr-score.title'),
		articles: [
			{
				title: t('cds.referencesModal.data.red-dcr-score.articleTitle'),
				url: 'article_attachments/14638679440411/Red_DCR_Score___Indicative_of_Cognitive_Impairment.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.red-dcr-score.content'),
	},
	[ReferenceID.YellowDCRScore]: {
		id: ReferenceID.YellowDCRScore,
		title: t('cds.referencesModal.data.yellow-dcr-score.title'),
		articles: [
			{
				title: t(
					'cds.referencesModal.data.yellow-dcr-score.articleTitle'
				),
				url: 'article_attachments/14662239046939/Yellow_DCR_Score___Borderline_for_Cognitive_Impairment.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.yellow-dcr-score.content'),
	},
	[ReferenceID.GreenDCRScore]: {
		id: ReferenceID.GreenDCRScore,
		title: t('cds.referencesModal.data.green-dcr-score.title'),
		articles: [
			{
				title: t(
					'cds.referencesModal.data.green-dcr-score.articleTitle'
				),
				url: 'article_attachments/14662359461915/Green_DCR_Score___Not_Indicative_of_Cognitive_Impairment.pdf',

				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.green-dcr-score.content'),
	},
	[ReferenceID.HighRiskDementia]: {
		id: ReferenceID.HighRiskDementia,
		title: t('cds.referencesModal.data.high-risk-dementia.title'),
		articles: [
			{
				title: t(
					'cds.referencesModal.data.high-risk-dementia.articleTitle_1'
				),
				url: 'article_attachments/14662617082011/Elevated_Risk_of_Developing_Dementia.pdf',
				isZendeskUrl: true,
			},
			{
				title: t(
					'cds.referencesModal.data.high-risk-dementia.articleTitle_2'
				),
				url: 'https://linushealth.com/dementia-risk-estimation-in-the-linus-health-core-cognitive-evaluation',
			},
		],
		content: tMany('cds.referencesModal.data.high-risk-dementia.content'),
	},
	[ReferenceID.NoRiskDementia]: {
		id: ReferenceID.NoRiskDementia,
		title: t('cds.referencesModal.data.no-risk-dementia.title'),
		articles: [
			{
				title: t(
					'cds.referencesModal.data.no-risk-dementia.articleTitle'
				),
				url: 'article_attachments/14662639693339/No_Risk_Concern_for_Developing_Dementia.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.no-risk-dementia.content'),
	},
	[ReferenceID.Memory]: {
		id: ReferenceID.Memory,
		title: t('cds.referencesModal.data.memory.title'),
		articles: [
			{
				title: t('cds.referencesModal.data.memory.articleTitle'),
				url: 'article_attachments/14662734322843/Concern_for_Verbal_Memory_Impairment.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.memory.content'),
	},
	[ReferenceID.ExecVascular]: {
		id: ReferenceID.ExecVascular,
		title: t('cds.referencesModal.data.exec-vascular.title'),
		articles: [
			{
				title: t(
					'cds.referencesModal.data.exec-vascular.articleTitle_1'
				),
				url: 'article_attachments/14662840370587/Concern_for_Executive_Cognitive_Impairment.pdf',
				isZendeskUrl: true,
			},
			{
				title: t(
					'cds.referencesModal.data.exec-vascular.articleTitle_2'
				),
				url: 'article_attachments/14662845611035/Concern_for_Vascular_Cognitive_Impairment.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.exec-vascular.content'),
	},
	[ReferenceID.Mixed]: {
		id: ReferenceID.Mixed,
		title: t('cds.referencesModal.data.mixed.title'),
		articles: [
			{
				title: t('cds.referencesModal.data.mixed.articleTitle'),
				url: 'article_attachments/14663011656347/Concern_for_Mixed-Domain_Cognitive_Impairment.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.mixed.content'),
	},
	[ReferenceID.Cholinergic]: {
		id: ReferenceID.Cholinergic,
		title: t('cds.referencesModal.data.cholinergic.title'),
		articles: [
			{
				title: t('cds.referencesModal.data.cholinergic.articleTitle'),
				url: 'article_attachments/14667898007963/Value_and_Clinical_Implications_Anticholinergic_Burden.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.cholinergic.content'),
	},
	[ReferenceID.Parkinsonism]: {
		id: ReferenceID.Parkinsonism,
		title: t('cds.referencesModal.data.parkinsonism.title'),
		articles: [
			{
				title: t('cds.referencesModal.data.parkinsonism.articleTitle'),
				url: 'article_attachments/14663137950747/Concern_for_Parkinsonism.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.parkinsonism.content'),
	},
	[ReferenceID.Tremor]: {
		id: ReferenceID.Tremor,
		title: t('cds.referencesModal.data.tremor.title'),
		articles: [
			{
				title: t('cds.referencesModal.data.tremor.articleTitle'),
				url: 'article_attachments/14665815994651/Concern_for_Tremor.pdf',
				isZendeskUrl: true,
			},
		],
		content: tMany('cds.referencesModal.data.tremor.content'),
	},
};
