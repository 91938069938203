import { Center, Group, Stack } from '@mantine/core';
import { t } from 'i18n';
import { useMemo } from 'react';

import { theme } from 'components/providers/StyleProvider/theme';
import { LinusModal } from 'components/shared/LinusModal';
import { ButtonSm } from 'components/shared/designSystem';
import { Icon } from 'components/shared/designSystem/Icon';
import { H3, P1 } from 'components/shared/designSystem/TextComponents';
import { icons } from 'enums/icons';
import { ConcernType } from 'generated/graphql';

import { PathwayConcernID } from '../../Pathway.types';
import { ReferenceItem } from './ReferenceItem';
import { referencesMap } from './ReferencesModal.data';
import { ReferenceID } from './ReferencesModal.types';

interface Props {
	/**
	 * Used to have a specific reference item opened
	 */
	concernId?: PathwayConcernID;
	onBack?(): void;
	onClose(): void;
}

export function ReferencesModal({ concernId, onBack, onClose }: Props) {
	const references = useMemo(() => Object.values(referencesMap), []);

	function getOpened(id: ReferenceID) {
		/**
		 * Pipe `ReferenceID` to `ConcernType`
		 */
		const proxyIdMap = {
			[ReferenceID.RedDCRScore]: null,
			[ReferenceID.YellowDCRScore]: null,
			[ReferenceID.GreenDCRScore]: null,
			[ReferenceID.HighRiskDementia]: null,
			[ReferenceID.NoRiskDementia]: null,
			[ReferenceID.Mixed]: [
				ConcernType.Mixed,
				ConcernType.MemorymixedCond1,
				ConcernType.MemorymixedCond2,
				ConcernType.Execmixed,
			],
			[ReferenceID.Memory]: [ConcernType.Memory],
			[ReferenceID.ExecVascular]: [ConcernType.Execvascular],
			[ReferenceID.Cholinergic]: [ConcernType.Cholinergic],
			[ReferenceID.Tremor]: [ConcernType.Tremor],
			[ReferenceID.Parkinsonism]: [ConcernType.Parkinsonism],
		};

		const pathwayConcernID: PathwayConcernID[] | null = proxyIdMap[id];

		if (pathwayConcernID && concernId) {
			const result = pathwayConcernID.includes(concernId);
			return result;
		}

		return false;
	}

	return (
		<LinusModal width='760px' onClose={onClose}>
			<Stack gap={16} w={760} data-testid='references-modal'>
				{onBack && <Back onClick={onBack} />}
				<Title>{t('cds.referencesModal.references')}</Title>
				<Content>
					{references.map((reference) => (
						<ReferenceItem
							key={reference.id}
							opened={getOpened(reference.id)}
							reference={reference}
						/>
					))}
				</Content>
				<Center>
					<ButtonSm
						text={t('cds.referencesModal.close')}
						width='158px'
						onClick={onClose}
					/>
				</Center>
			</Stack>
		</LinusModal>
	);
}

function Title({ children }: { children: React.ReactNode }) {
	return (
		<H3
			style={{
				color: theme.colors.gray_10,
				fontWeight: theme.weight.medium,
			}}
		>
			{children}
		</H3>
	);
}

function Back({ onClick }: { onClick(): void }) {
	return (
		<Group
			style={{
				gap: 16,
				cursor: 'pointer',
				width: 'fit-content',
			}}
			onClick={onClick}
			wrap='nowrap'
		>
			<Icon
				icon={icons.TraditionalArrowLeft}
				iconWidth={24}
				iconHeight={24}
				color={theme.colors.blue}
			/>
			<P1 style={{ color: theme.colors.blue }}>
				{t('cds.referencesModal.back')}
			</P1>
		</Group>
	);
}

function Content({ children }: { children: React.ReactNode }) {
	return (
		<Stack
			style={{
				gap: 8,
				flex: 1,
				maxHeight: 432,
				overflowY: 'auto',
			}}
		>
			{children}
		</Stack>
	);
}
