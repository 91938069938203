import { ConcernType, PathwayType } from 'generated/graphql';

import { ListNode } from './Pathway.list';

export interface Pathway {
	pathwayInstanceId: PathwayType | PathwayID.Empty;
	concernsList: PathwayConcernID[];
}

export enum PathwayID {
	/**
	 * - DCR Score = null
	 */
	Empty = 'Empty',
}

export enum AdditionalConcerns {
	/**
	 * Separate, not coming from the engine
	 */
	Hearing = 'HEARING',
}

export type PathwayConcernID = ConcernType | AdditionalConcerns;

export interface PathwayConcern {
	id: PathwayConcernID;
	title: string;
	rationale: string;
	nextSteps: ListNode;
	details: {
		highlight: string;
		content: string[];
	};
}

export interface BhapFunction {
	BHAP: () => Promise<void>;
}
