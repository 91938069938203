import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getOrganization } from 'api/organization';
import { QueryKey } from 'api/query';
import { CurrentUserDocument } from '../../generated/graphql';
import { CurrentUser } from '../../types';
import { useCustomLazyQuery } from '../../utils/customLazyQuery';
import { buildOrgPreferences } from './buildOrganizationPreferences';

import { buildCurrentUser } from './buildCurrentUser';

export const useCurrentUserBuilder = (): ((
	organizationId?: string
) => Promise<CurrentUser | undefined>) => {
	const client = useQueryClient();
	const currentUserQuery = useCustomLazyQuery(CurrentUserDocument);

	const getCurrentUser = useCallback(
		async (organizationId?: string) => {
			const userQuery = await currentUserQuery({
				orgId: organizationId,
			});

			const user = userQuery?.data?.currentUser?.currentUser || undefined;

			if (!user) {
				return;
			}

			const _organizationId = organizationId ?? user.organizationId;
			const organization = await client.fetchQuery({
				queryKey: [QueryKey.Organization, _organizationId],
				queryFn: ({ signal }) =>
					getOrganization(_organizationId, signal),
				staleTime: Infinity,
			});

			if (!organization) {
				return;
			}

			const preferences = buildOrgPreferences(organization);
			return buildCurrentUser(user, preferences, organizationId);
		},
		[currentUserQuery, getOrganization]
	);

	return getCurrentUser;
};
