import { Route, Routes } from 'react-router-dom';

import { AccessDenied } from '../../accessDenied/AccessDenied';
import { RegulatoryPage } from '../../regulatory/RegulatoryPage';
import { SessionTimeout } from '../../SessionTimeout/SessionTimeout';
import { UserNotFound } from '../../userNotFound/UserNotFound';
import { UserProvider } from '../UserContextProvider';

import { LoggedInCheck } from './LoggedInCheck';
import { RootRoute } from './RootRoute';

export const RouteProvider = (): JSX.Element | null => {
	return (
		<Routes>
			<Route path='/regulatory-label' element={<RegulatoryPage />} />
			<Route path={'/access-denied'} element={<AccessDenied />} />
			<Route path={'/user-not-found'} element={<UserNotFound />} />
			<Route path={'/session-timeout'} element={<SessionTimeout />} />
			{/* If route is '/' redirect to the org specific landing page 
				which will then land here and hit the '/*' route */}
			<Route
				path='/'
				element={
					<UserProvider>
						<RootRoute />
					</UserProvider>
				}
			/>
			{/* Otherwise proceed to loginCheck */}
			<Route
				path='/*'
				element={
					<UserProvider>
						<LoggedInCheck />
					</UserProvider>
				}
			/>
		</Routes>
	);
};
