import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { MMSEAccordion } from 'components/report/BatteryComponents/MMSE';
import { MemoryImpairmentProbability } from 'components/report/BatteryComponents/MemoryImpairmentProbability';
import { LineDivider } from 'components/shared/LineDivider';
import { P2 } from 'components/shared/designSystem';
import { useOrganizationFeatures } from 'components/shared/hooks/useOrganizationFeatures';
import { FeatureType } from 'generated/graphql';

interface AdditionalDcrResultsProps {
	mmsePrediction?: number | string;
}

export function AdditionalDcrResults({
	mmsePrediction,
}: Readonly<AdditionalDcrResultsProps>) {
	const features = useOrganizationFeatures();
	const { t } = useTranslation();

	const showMipSection = features.includes(
		FeatureType.MemoryImpairmentProbability
	);
	const showMmmseSection = features.includes(FeatureType.MmseEstimation);

	return (
		<Container>
			<DCRResulsTitle>
				{t('web.report.mip.additionalDcrResults').toUpperCase()}
			</DCRResulsTitle>
			{showMipSection && (
				<>
					<Spacer>
						{/* TODO: Change hardcoded value after AN-1607 is done */}
						<MemoryImpairmentProbability score={81} />
					</Spacer>
					<Spacer>
						<LineDivider />
					</Spacer>
				</>
			)}
			{showMmmseSection && !isNaN(Number(mmsePrediction)) && (
				<MMSEAccordion
					marginOfError={3}
					score={Number(mmsePrediction)}
				/>
			)}
		</Container>
	);
}

const Spacer = styled.div(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.sm};
	`
);

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const DCRResulsTitle = styled(P2)(
	({ theme: { color, weight } }) => css`
		font-weight: ${weight.medium};
		color: ${color.bodyTextSecondary};
	`
);
