import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { OrganizationServiceErrors, useRolesQuery } from 'api/organization';
import { QueryKey } from 'api/query';
import { ErrorData } from 'api/types';
import { useInviteNewUser } from 'api/user';
import { useValueListsStore } from 'store';
import { MessageEnumItem, messageEnum } from '../../enums/messageEnum';
import { ValueListType } from '../../generated/graphql';
import { AuthProvider } from '../../helpers/';
import { ERROR } from '../../logging/linusLogger';
import { UserContext } from '../context/UserContext';
import { LinusInput } from '../shared/Forms/Components/LinusInput';
import {
	addNewUserModel,
	addNewUserSchema,
	getModel,
} from '../shared/Forms/Schemas/addNewUserSchema';
import { InfoMessage } from '../shared/InfoMessage';
import { LoadingDots } from '../shared/LoadingDots';
import { ButtonLg } from '../shared/designSystem/ButtonLg';

import { UserRoleDefinitions } from './UserRoleDefinitions';

export type AddNewUserFormProps = {
	onCancel?: (event: React.MouseEvent) => void;
	onFinish?: () => void;
};

export const AddNewUserForm = ({
	onCancel,
	onFinish,
}: AddNewUserFormProps): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	const theme = useTheme();
	const { t } = useTranslation();
	const valueLists = useValueListsStore((state) => state.valueLists);

	const client = useQueryClient();
	const { data: organizationRoles } = useRolesQuery(
		currentUser.organizationId
	);

	const {
		mutateAsync: inviteUser,
		error,
		isSuccess,
		isPending,
	} = useInviteNewUser();

	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	useEffect(() => {
		if (isSuccess) {
			onFinish?.();
		}
	}, [isSuccess, onFinish]);

	useEffect(() => {
		if (error) {
			const message = getErrorMessage(error as AxiosError<ErrorData>, t);
			ERROR(error.message, error);
			setServerSideMessage(messageEnum.error(message));
		}
	}, [error, t]);

	if (isPending) {
		return <LoadingDots />;
	}

	const onSubmit = async (
		values: addNewUserModel,
		{ setSubmitting }: FormikHelpers<addNewUserModel>
	) => {
		delete values.avatarUrl;
		try {
			await inviteUser({
				userInvite: {
					...values,
					organizationId: currentUser.organizationId,
					phoneNumber: values.phoneNumber,
					suffixIds: values?.suffixIds?.split(', ').filter(Boolean),
					clientId: AuthProvider.getClientId(),
				},
				organizationId: currentUser.organizationId,
			});

			await client.invalidateQueries({
				queryKey: [QueryKey.Users, currentUser.organizationId],
			});

			sendEventData({ eventType: AnalyticsAction.InvitedNewUser });
			setSubmitting(false);
		} catch (err) {
			ERROR(
				`Error trying to invite a user from AddNewUserForm, organizationId: ${currentUser.organizationId}: `,
				err
			);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={getModel()}
			validationSchema={addNewUserSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<StyledForm>
						<StyledRow>
							<LinusInput
								width='257px'
								name='firstName'
								label={t`web.team.sharedModal.firstNameLabel`}
								data-testid='first-name'
							/>
							<LinusInput
								width='257px'
								name='lastName'
								label={t`web.team.sharedModal.lastNameLabel`}
								data-testid='last-name'
							/>
							<LinusInput
								width='257px'
								name='suffixIds'
								label={t`web.team.sharedModal.suffixLabel`}
								type='multi'
								preferDisplayLength='long'
								disabled={!valueLists}
								dropdownOptions={valueLists?.[
									ValueListType.Suffix
								]?.toOptions()}
								data-testid='suffix-ids'
							/>
						</StyledRow>
						<StyledRow>
							<LinusInput
								name='email'
								label={t`web.team.sharedModal.emailAddressLabel`}
								type='email'
								width='402px'
								data-testid='email'
							/>
							<LinusInput
								name='phoneNumber'
								label={t`web.team.sharedModal.phoneNumberLabel`}
								width='402px'
								data-testid='phone'
							/>
						</StyledRow>
						<StyledRow>
							<LinusInput
								name='roleId'
								type='select'
								label={t`web.team.sharedModal.userRoleLabel`}
								width='100%'
								dropdownOptions={(organizationRoles || []).map(
									(r) => ({
										display: t(r.displayKey),
										value: r.id,
									})
								)}
								dropUpSpace={theme.spacing.sm}
								data-testid='role-id'
							/>
						</StyledRow>
						<UserRoleDefinitions roles={organizationRoles} />
						<InfoMessage
							messageEnum={serverSideMessage}
							showIf={!!serverSideMessage}
						/>
						<StyledButtonRow>
							{isValid && dirty && (
								<StyledButtonWrapper>
									<ButtonLg
										onClick={onCancel}
										text={t`web.team.sharedModal.cancelButton`}
										primary={false}
										width='200px'
									/>
								</StyledButtonWrapper>
							)}
							<ButtonLg
								primary={true}
								disabled={!(isValid && dirty) || isSubmitting}
								text={t`web.team.addNewUserModal.inviteButton`}
								type='submit'
								width='200px'
							/>
						</StyledButtonRow>
					</StyledForm>
				);
			}}
		</Formik>
	);
};

const StyledForm = styled(Form)`
	width: min-content;
	margin: 0;
	padding: 0;
`;

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 836px;
`;
const StyledButtonRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: center;
	padding: ${spacing.md};
	`
);
const StyledButtonWrapper = styled.div`
	margin: 0 32px 0 0;
`;

const getErrorMessage = (error: AxiosError<ErrorData>, t: TFunction) => {
	if (
		error?.response?.data?.message.includes('UsernameExistsException') ||
		error?.response?.data?.message.includes('Unique constraint')
	) {
		return t`web.team.addNewUserModal.error.accountExists`;
	} else if (
		error?.response?.data.errorCode ===
		OrganizationServiceErrors.CrossProductUserNotAllowed
	) {
		return t`web.team.addNewUserModal.error.crossProduct`;
	} else {
		return t`web.team.addNewUserModal.error.default`;
	}
};
