import { OrganizationType } from '@lh/eng-platform-organization-service-rest-client';

import {
	OrganizationStatus,
	useOrganizationsByUserQuery,
} from 'generated/graphql';
import { useContext, useMemo, useState } from 'react';
import { useCurrentUserBuilder } from './useCurrentUserBuilder';
import { UserContext } from './UserContext';

export type Organization = {
	id: string;
	name: string;
	isDefault?: boolean;
	status: OrganizationStatus;
};

export function sortOrganizationsByName(a: Organization, b: Organization) {
	if (a.name < b.name) {
		return 1;
	}
	if (a.name > b.name) {
		return -1;
	}

	return 0;
}

export function compareOrganizations(a: Organization, b: Organization) {
	if (a.isDefault) {
		return -1;
	}
	if (b.isDefault) {
		return 1;
	}
	if (a.status === OrganizationStatus.Live) {
		return -1;
	}
	if (b.status === OrganizationStatus.Live) {
		return 1;
	}
	return a.name.localeCompare(b.name);
}

export function sortOrganizations(orgs: Organization[]) {
	return orgs.sort(sortOrganizationsByName).sort(compareOrganizations);
}

export function useSwitchOrganization() {
	const { currentUser, setCurrentUser } = useContext(UserContext);

	const { data, loading: loadingQuery } = useOrganizationsByUserQuery({
		variables: {
			userId: currentUser.id,
		},
	});

	const [primaryOrganizationId, setPrimaryOrganizationId] = useState(
		currentUser.originalOrganization?.organizationId ||
			currentUser.organizationId
	);

	const currentUserBuilder = useCurrentUserBuilder();
	const updateCurrentUser = async (orgId: string) => {
		return currentUserBuilder(orgId);
	};

	const switchOrganization = async (organizationId: string) => {
		return updateCurrentUser(organizationId).then((updatedCurrentUser) => {
			if (updatedCurrentUser) {
				setCurrentUser(updatedCurrentUser, true);
				return updatedCurrentUser;
			}
		});
	};

	const organizations = useMemo(() => {
		if (!data?.organizationsByUser?.nodes?.length) {
			return [];
		}
		// we should't show research organizations
		const organizations = data?.organizationsByUser.nodes.filter(
			(organization) =>
				organization.type === OrganizationType.Clinical ||
				organization.type === OrganizationType.Linus
		);

		return sortOrganizations(
			organizations.map((organization) => {
				const isDefaultOrganization =
					organization.id === primaryOrganizationId;

				return {
					id: organization.id,
					name: organization.name,
					isDefault: isDefaultOrganization,
					status: organization.status,
				} as Organization;
			})
		);
	}, [
		data?.organizationsByUser,
		currentUser.organizationId,
		primaryOrganizationId,
	]);

	return {
		switchOrganization,
		setPrimaryOrganizationId,
		organizations,
		loadingQuery,
	};
}
